import Http from '@/libs/http';

class PanelLocationUserProfileApi extends Http {
   index(params) {
      return super.get(`panel/location/user-profile`, { params });
   }

   store(payload, params) {
      return super.post(`panel/location/user-profile`, payload, { params });
   }

   show(id, params) {
      return super.get(`panel/location/user-profile/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`panel/location/user-profile/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`panel/location/user-profile/${id}`, { params });
   }
}

export default new PanelLocationUserProfileApi();
