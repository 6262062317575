import BaseModel from './BaseModel';

export default class Location extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getCountry() {
      return this.get('country');
   }

   getCity() {
      return this.get('city');
   }

   getDistrict() {
      return this.get('district');
   }

   getLocationFormatted() {
      const city = this.getCity();
      const country = this.getCountry();
      const district = this.getDistrict();

      return `${district} / ${city},${country}`;
   }

   getIsTrial() {
      return this.get('is_trial');
   }

   getLicenseEndAt() {
      return this.get('license_end_at');
   }
}
