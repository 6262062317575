<template>
   <ObiText class="obi-eg">
      <ObiText class="obi-eg-nav">
         <ObiNav class="--for-obi-eg" vertical pills :items="navItems" v-model="selectedItem" />
      </ObiText>
      <ObiText class="obi-eg-body">
         <ObiText v-if="selectedItem === 'theme-select'">
            <ObiTitle class="mb-2" :title="$t('labels.color_scheme')" />
            <ObiButtonSwitch :items="dataColorSchemes" v-model="formDataGeneral.color_scheme" />
         </ObiText>
         <ObiText v-if="selectedItem === 'logo'">
            <ObiTitle class="mb-1" :title="$t('labels.logo')" />
            <ObiText class="mb-4" v-html="$t('labels.logo_description')" />
            <ObiText class="mb-5">
               <ObiFileUpload url="/panel/portal/image" v-model="formDataGeneral.logo" />
            </ObiText>
            <ObiTitle class="mb-2" :title="$t('labels.logo_resize')" />
            <ObiText>Boyutlandırma componenti</ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'background'">
            <ObiTitle class="mb-2" :title="$t('labels.background_appearance')" />
            <ObiText class="mb-5" v-html="$t('labels.background_appearance_description')" />
            <ObiTitle class="mb-2" :title="$t('labels.background_type')" />
            <ObiText class="mb-5">
               <ObiButtonSwitch :items="dataBackgroundTypes" v-model="formDataGeneral.background_type" />
            </ObiText>
            <ObiText v-if="['image', 'color_image'].includes(formDataGeneral.background_type)">
               <ObiTitle class="mb-2" :title="$t('labels.background_image')" />
               <ObiText class="mb-5">
                  <ObiFileUpload url="/panel/portal/image" v-model="formDataGeneral.background_image" />
               </ObiText>
            </ObiText>
            <ObiText v-if="['color', 'color_image'].includes(formDataGeneral.background_type)">
               <ObiTitle class="mb-2" :title="$t('labels.background_color')" />
               <ObiText class="mb-5">
                  <ObiButtonSwitch :items="dataColorTypes" v-model="formDataGeneral.color_type" />
               </ObiText>
               <ObiText v-if="formDataGeneral.color_type === 'solid'">
                  <ObiTitle class="mb-2" :title="$t('labels.color_select_solid')" color="black-50" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.background_color" />
                  </ObiText>
               </ObiText>
               <ObiText v-if="formDataGeneral.color_type === 'gradient'">
                  <ObiTitle class="mb-2" :title="$t('labels.color_select_gradient')" color="black-50" />
                  <ObiText class="mb-5">
                     <ObiRow>
                        <ObiCol sm="3">
                           <ObiColorPicker v-model="formDataGeneral.background_color" />
                        </ObiCol>

                        <ObiCol sm="3">
                           <ObiColorPicker v-model="formDataGeneral.background_color" />
                        </ObiCol>
                     </ObiRow>
                  </ObiText>
               </ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'header'">Header options</ObiText>
         <ObiText v-if="selectedItem === 'footer'">Footer options</ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import Form from '@/libs/form';

export default {
   name: 'PortalEditGeneral',

   i18n: {
      messages: {
         tr: {
            labels: {
               logo: 'Logo',
               logo_description: 'Portal için logonuzu özelleştirin. Tavsiye edilen boyut 600x150 pikseldir.',
               logo_resize: 'Boyutlandırın',
               color_scheme: 'Renk Şeması',
               background_appearance: 'Arkaplan Özellikleri',
               background_appearance_description: 'Portalınızın arkaplan rengini, resmini ayarlayın',
               background_type: 'Arkaplan Türü',
               background_image: 'Resim Seçiniz',
               background_color: 'Renk Seçiniz',
               color_select_solid: 'Renk Seçiniz',
               color_select_gradient: 'Gradient Oluşturun',
            },
         },
         en: {
            labels: {
               logo: 'Logo',
               logo_description: "Customize your portal's logo. Recommended size is 600x150 pixels.",
               logo_resize: 'Resize',
               color_scheme: 'Color Scheme',
               background_appearance: 'Background Appearance',
               background_appearance_description: 'Set the background color or image for your portal',
               background_type: 'Background Type',
               background_image: 'Choose Image',
               background_color: 'Choose Color',
               color_select_solid: 'Choose Color',
               color_select_gradient: 'Create Gradient',
            },
         },
      },
   },

   props: {},

   data() {
      return {
         selectedItem: 'theme-select',
         navItems: [
            {
               id: 'theme-select',
               label: 'Tema Seçimi',
            },
            {
               id: 'logo',
               label: 'Logo',
            },
            {
               id: 'background',
               label: 'Arkaplan Özellikleri',
            },
            {
               id: 'header',
               label: 'Üst Kısım',
            },
            {
               id: 'footer',
               label: 'Alt Kısım',
            },
         ],
         dataColorSchemes: [
            { id: 'light', label: 'Aydınlık Tema' },
            { id: 'dark', label: 'Koyu Tema' },
         ],
         dataBackgroundTypes: [
            { id: 'color', label: 'Renk' },
            { id: 'image', label: 'Resim' },
            { id: 'color_image', label: 'Renk + Resim' },
         ],
         dataColorTypes: [
            { id: 'solid', label: 'Tek Renk' },
            { id: 'gradient', label: 'Gradinet' },
         ],
         formDataGeneral: Form.create([
            'color_scheme',
            'background_type',
            'color_type',
            'background_color',
            'background_image',
            'logo',
         ]).defaults({
            color_scheme: 'light',
            background_type: 'image',
            color_type: 'solid',
            background_color: '#242424',
            background_image: '',
            logo: '',
         }),
      };
   },

   async mounted() {},

   methods: {},
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: grid;
   margin-left: -1.5rem;
   grid-template-columns: 12rem 1fr;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }
}
</style>
