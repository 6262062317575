<template>
   <ObiPage class="page-wrapper" v-if="dataFirewallProfile">
      <ObiRow class="filter-list--item">
         <ObiCol class="filter-list--item" col="6">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Trackers_Blocking_Title')" :subtitle="$t('Trackers_Blocking_Subtitle')">
                     <template #actions>
                        <ObiButtonSwitch
                           :items="enabledOptions"
                           v-model="dataFirewallProfile.is_trackers_blocked"
                           @click="changeFilterStatus('d8rz0m', dataFirewallProfile.is_trackers_blocked)"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiText
                        ><b>{{ $t('Enabled') }} :</b>
                        {{ $t('Trackers_Enabled_Info') }}
                     </ObiText>
                     <ObiText
                        ><b>{{ $t('Disabled') }} :</b>
                        {{ $t('Trackers_Disabled_Info') }}
                     </ObiText>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
         <ObiCol class="filter-list--item" col="6">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Ads_Blocking_Title')" :subtitle="$t('Ads_Blocking_Subtitle')">
                     <template #actions>
                        <ObiButtonSwitch
                           :items="enabledOptions"
                           v-model="dataFirewallProfile.is_ads_blocked"
                           @click="changeFilterStatus('782r1l', dataFirewallProfile.is_ads_blocked)"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiText
                        ><b>{{ $t('Enabled') }} :</b>
                        {{ $t('Ads_Blocking_Enabled_Info') }}
                     </ObiText>
                     <ObiText
                        ><b>{{ $t('Disabled') }} :</b>
                        {{ $t('Ads_Blocking_Disabled_Info') }}
                     </ObiText>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow class="filter-list--item">
         <ObiCol col="12">
            <ObiCard :loading="isNetworksLoading">
               <template #header>
                  <ObiTitle :title="$t('Networks_Title')" :subtitle="$t('Networks_Subtitle')">
                     <template #actions>
                        <ObiButton
                           compact
                           size="md"
                           color="primary"
                           :text="$t('Add_New_Network')"
                           :loading="isNetworksLoading"
                           @click="showAddNetworkModal"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiNotFound
                        v-if="networkList && networkList.length === 0"
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('NOT_FOUND_ANY_NETWORK')"
                     />
                     <div v-else>
                        <ObiDeleteButton
                           v-for="network in networkList"
                           edit
                           class="me-2 bg-white text-primary"
                           :key="network.hid"
                           :text="`${network.name}-${network.starting_ip}/${network.end_ip}`"
                           @remove="onRemoveNetworkClicked(network)"
                           @edit="onEditNetworkClicked(network)"
                        ></ObiDeleteButton>
                     </div>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow class="filter-list--item">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Websites_Apps_Games_Title')" :subtitle="$t('Websites_Apps_Games_Subtitle')">
                     <template #actions>
                        <ObiButton
                           compact
                           size="md"
                           color="primary"
                           :text="$t('Add_New_Title')"
                           :loading="isAppsFilterListLoading"
                           @click="showAppsFilterList"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiNotFound
                        v-if="dataAppFilterList.items && dataAppFilterList.items.length === 0"
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                     />
                     <div v-else>
                        <ObiDeleteButton
                           v-for="filterList in dataAppFilterList.items"
                           class="me-2 bg-white text-primary"
                           :key="filterList.hid"
                           :text="filterList.name"
                           :icon="filterList.thumbnail"
                           @remove="onRemoveClicked(filterList)"
                        ></ObiDeleteButton>
                     </div>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow class="filter-list--item">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Categories_Title')" :subtitle="$t('Categories_Subtitle')">
                     <template #actions>
                        <ObiButton
                           compact
                           size="md"
                           color="primary"
                           :text="$t('Add_New_Title')"
                           :loading="isAppsFilterListLoading"
                           @click="showCategoriesFilterList"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiNotFound
                        v-if="dataCategoriesFilterList.items && dataCategoriesFilterList.items.length === 0"
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                     />
                     <div v-else>
                        <ObiDeleteButton
                           v-for="filterList in dataCategoriesFilterList.items"
                           class="me-2 bg-white text-primary"
                           :key="filterList.hid"
                           :text="filterList.name"
                           @remove="onRemoveCategoryClicked(filterList)"
                        ></ObiDeleteButton>
                     </div>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow>
         <ObiCol col="12" class="filter-list--item">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Blocked_Domains_Title')" :subtitle="$t('Blocked_Domains_Subtitle')">
                     <template #actions>
                        <ObiButton
                           compact
                           size="md"
                           color="primary"
                           :text="$t('Add_New_Title')"
                           :loading="isBlockedDomainLoading"
                           @click="$refs.addBlockedDomainFilterModal.show()"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2 mb-4">
                     <ObiNotFound
                        v-if="dataDomains.blocked && dataDomains.blocked.length === 0"
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                     />
                     <div v-else>
                        <ObiDeleteButton
                           v-for="blockedDomain in dataDomains.blocked"
                           class="me-2 bg-white text-primary"
                           :key="blockedDomain.name"
                           :text="blockedDomain.name"
                           @remove="deleteDomainList(blockedDomain.name, 'blocked')"
                        ></ObiDeleteButton>
                     </div>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow>
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Allowed_Domains_Title')" :subtitle="$t('Allowed_Domains_Subtitle')">
                     <template #actions>
                        <ObiButton
                           compact
                           size="md"
                           color="primary"
                           :text="$t('Add_New_Title')"
                           :loading="isBlockedDomainLoading"
                           @click="$refs.addAllowedDomainFilterModal.show()"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2 mb-4">
                     <ObiNotFound
                        v-if="dataDomains.allowed && dataDomains.allowed.length === 0"
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                     />
                     <div v-else>
                        <ObiDeleteButton
                           v-for="allowedDomain in dataDomains.allowed"
                           class="me-2 bg-white text-primary"
                           :key="allowedDomain.name"
                           :text="allowedDomain.name"
                           @remove="deleteDomainList(allowedDomain.name, 'allowed')"
                        ></ObiDeleteButton>
                     </div>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <AddFilterListModal
         ref="modalCategoriesFilterList"
         @addList="onAddCategoryList"
         @removeList="onRemoveCategoryList"
         :currentList="dataCategoriesFilterList.items"
      />
      <AddFilterListModal
         ref="modalWebsites_Apps_GamesFilterList"
         @addList="onAddAppList"
         @removeList="onRemoveAppList"
         :currentList="dataAppFilterList.items"
      />
      <AddDomainFilterModal
         ref="addBlockedDomainFilterModal"
         type="blocked"
         @saveDomain="addDomain"
         :currentList="dataAppFilterList.items"
      />
      <AddDomainFilterModal
         ref="addAllowedDomainFilterModal"
         type="allowed"
         @saveDomain="addDomain"
         :currentList="dataAppFilterList.items"
      />
      <AddNetworkModal ref="addNetworkModal" @saveNetwork="addNetwork" />
   </ObiPage>
</template>

<script>
import set from 'lodash/set';

import merge from 'lodash/merge';
import ErrorHandler from '@/libs/error-handler';
import FirewallFilterList from '../../../api/FirewallFilterList';
import AddFilterListModal from '@/views/pages/Firewall/_Modals/AddFilterListModal';
import AddDomainFilterModal from '@/views/pages/Firewall/_Modals/AddDomainFilterModal';
import AddNetworkModal from '@/views/pages/Firewall/_Modals/AddNetworkModal';

import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import MyModel from '@/api/Models/MyModel';
import FirewallProfileApi from '@/api/FirewallProfileApi';
import FirewallNetworkApi from '../../../api/FirewallNetworkApi';

export default {
   components: {
      AddFilterListModal,
      AddDomainFilterModal,
      AddNetworkModal,
   },
   i18n: {
      messages: {
         tr: {
            Websites_Apps_Games_Title: 'Engellenen Hizmetler',
            Websites_Apps_Games_Subtitle:
               'Bu özellik, belirli web sitelerine, uygulamalara ve oyunlara erişimi kısıtlamanıza olanak tanır.',
            Enabled: 'Etkin',
            Disabled: 'Devre dışı',
            no_data_to_see: 'Henüz kısıtlanmış kayıt yok',
            Trackers_Blocking_Title: 'İzleyicileri Engelleme',
            Trackers_Blocking_Subtitle: 'Bilinen izleme alanlarına karşı koruma seviyenizi seçin',
            Trackers_Disabled_Info:
               'İzleme koruması etkin değil. Gezintiniz bilinen izleme alanları tarafından izlenebilir',
            Trackers_Enabled_Info: 'Bilinen izleme alanlarına karşı koruma etkin. Ortak izleme alanlarını engeller',
            Ads_Blocking_Title: 'Reklam Engelleme',
            Ads_Blocking_Subtitle: 'Gezinti deneyiminiz sırasında reklamların görüntülenmesini kontrol edin',
            Ads_Blocking_Disabled_Info: 'Reklam engelleme etkin değil. Reklamlar normal şekilde görüntülenecektir',
            Ads_Blocking_Enabled_Info:
               'Reklam engelleme etkin. Rahatsız edici reklamları engellerken, rahatsız edici olmayan reklamların görüntülenmesine izin verir',
            Add_New_Title: 'Yeni Ekle',
            Blocked_Domains_Title: 'Engellenen Alan Adları',
            Blocked_Domains_Subtitle: 'Daha önce eklediğiniz engellenmiş alan adlarının listesi',
            Allowed_Domains_Title: 'İzin Verilen Alan Adları',
            Allowed_Domains_Subtitle: 'Daha önce eklediğiniz izin verilen alan adlarının listesi',
            NOT_FOUND_ANY_NETWORK: 'Eklenmiş bir network bulunumadı',
            Networks_Title: 'Networkler',
            Networks_Subtitle: 'Ağınızdaki farklı IP aralıklarını tanımlayarak özelleştirilmiş kurallar oluşturun',
            Add_New_Network: 'Yeni Ekle',
            Categories_Title: 'Kategoriler',
            Categories_Subtitle: 'Belirli websitesi kategorilerini engelleyin',
         },
         en: {
            Websites_Apps_Games_Title: 'Blocked Services',
            Websites_Apps_Games_Subtitle:
               'This feature allows you to restrict access to specific websites, apps, and games.',
            Enabled: 'Enabled',
            Disabled: 'Disabled',
            no_data_to_see: 'No restricted records yet',
            Trackers_Blocking_Title: 'Trackers Blocking',
            Trackers_Blocking_Subtitle: 'Choose your level of protection against known tracking domains',
            Trackers_Disabled_Info:
               'No tracking protection enabled. Your browsing may be tracked by known tracking domains',
            Trackers_Enabled_Info: 'Protection enabled against known tracking domains. Blocks common tracking domains',
            Ads_Blocking_Title: 'Ads Blocking',
            Ads_Blocking_Subtitle: 'Control the display of ads during your browsing experience',
            Ads_Blocking_Disabled_Info: 'No ad-blocking enabled. Ads will be displayed as usual',
            Ads_Blocking_Enabled_Info:
               'Ad-blocking enabled. Blocks intrusive ads while allowing non-intrusive ads to be displayed',
            Add_New_Title: 'Add New',
            Blocked_Domains_Title: 'Blocked Domains',
            Blocked_Domains_Subtitle: 'List of blocked domains of you added before',
            Allowed_Domains_Title: 'Allowed Domains',
            Allowed_Domains_Subtitle: 'List of allowed domains of you added before',
            NOT_FOUND_ANY_NETWORK: 'Eklenmiş bir network bulunumadı',
            Networks_Title: 'Networks',
            Networks_Subtitle: 'Create customized rules by defining different IP ranges in your network',
            Add_New_Network: 'Add New',
            Categories_Title: 'Categories',
            Categories_Subtitle: 'Block specific website Categories',
         },
      },
   },
   computed: {
      showHeaderSaveButton() {
         return this.$route.name === 'qrmenu-categories';
      },
   },
   data() {
      return {
         dataFirewallProfile: null,
         dataAppFilterList: { data: [], items: [] },
         dataCategoriesFilterList: { data: [], items: [] },
         appFilterList: SingleResourceResponse.create().map(MyModel),
         networkList: [],
         dataDomains: {
            allowed: [],
            blocked: [],
         },
         enabledOptions: [
            { id: true, label: this.$t('Enabled') },
            { id: false, label: this.$t('Disabled') },
         ],
         trackersProtectionEnabled: false,
         adsProtectionEnabled: false,
         showFilterListModal: false,
         isAppsFilterListLoading: false,
         isBlockedDomainLoading: false,
         isNetworksLoading: false,
      };
   },
   beforeMount() {
      this.getProfileData();
      this.getNetworks();
      this.getFilterList();
      this.getCategoriesFilterList();
      this.getDomainList('allowed');
      this.getDomainList('blocked');
   },
   methods: {
      merge,
      set,
      async getProfileData() {
         try {
            const data = await FirewallProfileApi.show();
            this.dataFirewallProfile = data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async showCategoriesFilterList() {
         this.isAppsFilterListLoading = true;

         try {
            const response = await FirewallFilterList.categories();
            this.$refs.modalCategoriesFilterList.show(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
         }
      },
      async showAppsFilterList() {
         this.isAppsFilterListLoading = true;

         try {
            const response = await FirewallFilterList.apps();
            this.$refs.modalWebsites_Apps_GamesFilterList.show(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
         }
      },
      async getFilterList() {
         this.isAppsFilterListLoading = true;

         try {
            const response = await FirewallFilterList.filterList({ filter_type: 2 });
            this.dataAppFilterList.items = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
         }
      },
      async getCategoriesFilterList() {
         this.isAppsFilterListLoading = true;

         try {
            const response = await FirewallFilterList.filterList({ filter_type: 3 });
            this.dataCategoriesFilterList.items = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
         }
      },
      async getDomainList(type) {
         this.isAppsFilterListLoading = true;

         try {
            const { data } = await FirewallProfileApi.domains({ type: type });
            this.dataDomains[type] = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
         }
      },
      async addToFilterList(hid) {
         this.isAppsFilterListLoading = true;

         try {
            await FirewallFilterList.add({ id: hid });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
         }
      },
      async removeFromFilterList(hid) {
         this.isAppsFilterListLoading = true;

         try {
            await FirewallFilterList.remove(hid);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isAppsFilterListLoading = false;
            this.getFilterList();
            this.getCategoriesFilterList();
         }
      },
      async deleteDomainList(domain, type) {
         this.isBlockedDomainLoading = true;

         try {
            await FirewallProfileApi.removeDomain(domain, type);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isBlockedDomainLoading = false;
            this.getDomainList(type);
         }
      },
      onAddCategoryList(item) {
         this.dataCategoriesFilterList.data.push(item);
         this.dataCategoriesFilterList.items.push(item.toObject());
         this.addToFilterList(item.get('hid'));
      },
      onRemoveCategoryList(item) {
         const index = this.dataCategoriesFilterList.data.findIndex(
            (element) => element.get('code') === item.get('code')
         );
         if (index !== -1) {
            this.dataCategoriesFilterList.data.splice(index, 1);
            this.dataCategoriesFilterList.items.splice(index, 1);
         }
         this.onRemoveClicked(item.toObject());
      },
      onRemoveItemCategoryList(item) {
         const index = this.dataCategoriesFilterList.data.findIndex((element) => element.get('code') === item.code);
         if (index !== -1) {
            this.dataCategoriesFilterList.data.splice(index, 1);
            this.dataCategoriesFilterList.items.splice(index, 1);
         }
      },
      onAddAppList(item) {
         this.dataAppFilterList.data.push(item);
         this.dataAppFilterList.items.push(item.toObject());
         this.addToFilterList(item.get('hid'));
      },
      onRemoveAppList(item) {
         const index = this.dataAppFilterList.data.findIndex((element) => element.get('code') === item.get('code'));
         if (index !== -1) {
            this.dataAppFilterList.data.splice(index, 1);
            this.dataAppFilterList.items.splice(index, 1);
         }
         this.onRemoveClicked(item.toObject());
      },
      onRemoveItemAppList(item) {
         const index = this.dataAppFilterList.data.findIndex((element) => element.get('code') === item.code);
         if (index !== -1) {
            this.dataAppFilterList.data.splice(index, 1);
            this.dataAppFilterList.items.splice(index, 1);
         }
      },
      onRemoveClicked(item) {
         this.onRemoveItemAppList(item);
         this.removeFromFilterList(item.hid);
      },
      onRemoveCategoryClicked(item) {
         this.onRemoveItemCategoryList(item);
         this.removeFromFilterList(item.hid);
      },
      async changeFilterStatus(hid, state) {
         if (state) {
            try {
               await FirewallFilterList.add({ id: hid });
            } catch (err) {
               ErrorHandler.handle(err);
            }
         } else {
            try {
               await FirewallFilterList.remove(hid);
            } catch (err) {
               ErrorHandler.handle(err);
            }
         }
      },
      async addDomain(name, type) {
         this.isBlockedDomainLoading = true;

         try {
            await FirewallProfileApi.addDomain({ name, type });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isBlockedDomainLoading = false;
            this.getDomainList(type);
         }
      },
      async getNetworks() {
         this.isNetworksLoading = true;

         try {
            const data = await FirewallNetworkApi.index();
            this.networkList = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isNetworksLoading = false;
         }
      },
      async addNetwork(formData) {
         this.isNetworksLoading = true;

         try {
            if (formData.hid != null) {
               await FirewallNetworkApi.update(formData.hid, formData);
            } else {
               await FirewallNetworkApi.store(formData.hid, formData);
            }
            this.getNetworks();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isNetworksLoading = false;
         }
      },
      showAddNetworkModal() {
         this.$refs.addNetworkModal.show();
      },
      onEditNetworkClicked(item) {
         this.$refs.addNetworkModal.show(item);
      },
      async onRemoveNetworkClicked(network) {
         this.isNetworksLoading = true;

         try {
            await FirewallNetworkApi.delete(network.hid);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isNetworksLoading = false;
            this.getNetworks();
         }
      },
   },
};
</script>

<style lang="scss">
.page-wrapper {
   padding: 10px;
   margin-bottom: 20px;
}
.filter-list--item {
   margin-bottom: 10px;
}
@media (max-width: 768px) {
   .filter-list--item {
      flex: 0 0 100% !important;
   }
}
</style>
