<template>
   <fade-transition origin="center" mode="out-in" :duration="250">
      <div class="layout-child-page">
         <router-view />
      </div>
   </fade-transition>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';

export default {
   name: 'LayoutChildPage',
   components: {
      FadeTransition,
   },
};
</script>

<style lang="scss" scoped>
.layout-child-page {
   height: 100%;

   > .card:first-child {
      height: 100%;
   }
}
</style>
