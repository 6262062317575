<template>
   <div class="obi-tab-menu">
      <div class="menu-item-wrapper">
         <div class="menu-item-items">
            <ObiSidebar class="sideber-wrapper" />
         </div>
      </div>
   </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import ErrorHandler from '@/libs/error-handler';

import AuthApi from '@/api/AuthApi';
import IconNotification from '@/components/Icons/general/IconNotification';
import ObiSidebar from '@/components/Common/ObiSidebar';

export default {
   name: 'ObiHeader',
   components: { ObiSidebar },
   props: {
      isAdmin: {
         type: [Boolean],
         default: false,
      },
   },
   data() {
      return {
         IconNotification,
         isTeamChanging: false,
         tabItems: [
            {
               id: 'portals-edit',
               label: this.$t('Genel'),
            },
            {
               id: 'portals-edit-sign-in-methods',
               label: this.$t('Giriş Metodları'),
            },
            {
               id: 'portals-edit-surveys',
               label: this.$t('Anketler'),
            },
            {
               id: 'portals-edit-advertisements',
               label: this.$t('Reklamlar'),
            },
            {
               id: 'portals-edit-rules',
               label: this.$t('Kurallar'),
            },
            {
               id: 'portals-edit-advanced',
               label: this.$t('Gelişmiş'),
            },
         ],
         languages: [
            {
               name: 'Türkçe',
               key: 'tr',
            },
            {
               name: 'English',
               key: 'en',
            },
         ],
      };
   },
   computed: {
      ...mapGetters('auth', ['authUser', '']),
      teams() {
         return get(this, 'authUser.teams', {});
      },
      profiles() {
         return get(this, 'authUser.profiles', {});
      },
      currentProfile() {
         return get(this, 'authUser.current_profile', {});
      },
      currentTeam() {
         return get(this, 'authUser.current_team', {});
      },
      notifications() {
         const notifications = get(this, 'authUser.notifications', []);
         const readNotifications = get(this, 'authUser.readNotifications', []);
         return notifications.concat(readNotifications);
      },
      unread() {
         return get(this, 'authUser.notifications.length');
      },
      currentLanguage() {
         const locales = this.languages.filter((x) => x.key == this.$i18n.locale);

         return locales && locales.length > 0 ? locales[0] : this.locales[0];
      },
   },
   methods: {
      async changeTeam(team) {
         this.isTeamChanging = true;
         try {
            await AuthApi.changeProfile(team.hid);
            await this.$router.push({ name: 'dashboard-index' }).catch(() => {});
            window.location.reload();
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.isTeamChanging = false;
         }
      },
      async changeTeamToNew(response) {
         this.changeTeam(response.data);
      },
      changeLocale(lang) {
         localStorage.setItem('last-locale', lang.key);

         this.$i18n.locale = lang.key;
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-tab-menu {
   color: #ffffff !important;
   height: 60px;
   margin-bottom: -14px;
}

@media (min-width: 768px) {
   .obi-tab-menu--pane {
      display: flex;
      flex-flow: row;
   }

   .obi-tab-menu {
      display: flex;
      flex-flow: row;
   }
   .menu-item-wrapper {
      position: relative;
      flex: 1 1 0%;
      z-index: 1;
      margin-left: -1px;
   }
   .menu-item-items {
      overflow: hidden;
      display: flex;
      flex-flow: row;
      flex-shrink: 1;
      color: rgb(126, 128, 128);
   }
   .menu-item-team-list {
      height: 46px;
      position: relative;
      margin-left: 18px;
      padding-right: 18px;
      display: inline-block;
      width: auto;
      max-width: 100%;
   }
   .menu-item-team-list::after {
      border-right: 1px solid #2f71f2;
      content: '';
      position: absolute;
      right: 0px;
      height: 36px;
      top: 6px;
      bottom: 6px;
   }
}
@media (max-width: 768px) {
   .obi-tab-menu {
      position: relative;
      z-index: 1;
   }
   .menu-item-team-list {
      display: none;
   }
   .menu-item-wrapper {
      display: flex;
      flex-flow: row;
      flex-shrink: 1;
      overflow-x: scroll;
      background-color: #ffffff;
   }
}
</style>
