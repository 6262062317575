import Form from '@/libs/form';

const fields = ['name', 'type', 'images', 'embed_code'];

const rules = {
   name: 'required',
   type: 'required',
   images: 'required_if:type,image',
   embed_code: 'required_if:type,embed',
};

export default Form.create(fields).validate(rules);
