import Http from '@/libs/http';

import PanelPortalAdApi from '@/api/PanelPortalAdApi';
import PanelPortalRuleApi from '@/api/PanelPortalRuleApi';
import PanelPortalSurveyApi from '@/api/PanelPortalSurveyApi';

class PanelPortalApi extends Http {
   index(params) {
      return super.get('panel/portal', { params });
   }

   store(payload, params) {
      return super.post('panel/portal', payload, { params });
   }

   show(id, params) {
      return super.get(`panel/portal/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`panel/portal/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`panel/portal/${id}`, { params });
   }

   get ads() {
      return PanelPortalAdApi;
   }

   get rules() {
      return PanelPortalRuleApi;
   }

   get surveys() {
      return PanelPortalSurveyApi;
   }
}

export default new PanelPortalApi();
