import BaseModel from './BaseModel';

export default class Brand extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getSlug() {
      return this.get('slug');
   }

   getLogo() {
      return this.get('logo');
   }

   getAuthType() {
      return this.get('auth_type');
   }
}
