import Http from '@/libs/http';

class PanelPortalAdApi extends Http {
   index(portalId, params) {
      return super.get(`panel/portal/${portalId}/ads`, { params });
   }

   store(portalId, payload, params) {
      return super.post(`panel/portal/${portalId}/ads`, payload, { params });
   }

   show(portalId, params) {
      return super.get(`panel/portal/${portalId}/ads`, { params });
   }

   update(portalId, adId, payload, params) {
      return super.put(`panel/portal/${portalId}/ads/${adId}`, payload, { params });
   }

   delete(portalId, adId, params) {
      return super.delete(`panel/portal/${portalId}/ads/${adId}`, { params });
   }
}

export default new PanelPortalAdApi();
