<template>
   <ObiModal lg as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLocation" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiTitle class="mb-3" color="primary" :title="$t('form_title')" />

      <ObiFloatingInput
         :label="$t('fields.name')"
         :disabled="isLoading"
         :placeholder="$t('fields.name')"
         v-model="formDataLocation.name"
         :errors="formDataLocation.errors.get('name')"
      />

      <div class="row">
         <ObiFloatingInput
            :label="$t('fields.country')"
            :disabled="isLoading"
            class="col-md-4"
            :placeholder="$t('fields.country')"
            v-model="formDataLocation.country"
            :errors="formDataLocation.errors.get('country')"
         />

         <ObiFloatingInput
            :label="$t('fields.city')"
            :disabled="isLoading"
            class="col-md-4"
            :placeholder="$t('fields.city')"
            v-model="formDataLocation.city"
            :errors="formDataLocation.errors.get('city')"
         />

         <ObiFloatingInput
            :label="$t('fields.district')"
            :disabled="isLoading"
            class="col-md-4"
            :placeholder="$t('fields.district')"
            v-model="formDataLocation.district"
            :errors="formDataLocation.errors.get('district')"
         />
      </div>

      <ObiTitle title="Kullanılabilir Giriş Yöntemleri" class="mt-2 mb-2" />
      <ObiButtonSwitch v-model="formDataLocation.available_providers" multi :items="providers" />

      <ObiTitle v-if="formDataLocation.available_providers.indexOf('sms') > -1" title="SMS Limiti" class="mt-2 mb-2" />
      <ObiButtonSwitch
         v-if="formDataLocation.available_providers.indexOf('sms') > -1"
         v-model="formDataLocation.sms_limit"
         :items="sms_packages"
      />

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelLocationApi from '@/api/PanelLocationApi';

import IconLocation from '@/components/Icons/general/IconLocation';

import formDataLocation from '@/views/pages/Location/FormData/form-data-location';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import Portal from '@/api/Models/Portal';
import PanelPortalApi from '@/api/PanelPortalApi';

export default {
   name: 'AdminLocationCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Müşteri Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni müşteri ekleyin',
            form_title: 'Müşteri Bilgileri',
            fields: {
               name: 'Müşteri Adı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               portal: 'Portal',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Customer',
            subtitle: 'Add a new customer by entering the information below',
            form_title: 'Customer Information',
            fields: {
               name: 'Customer Name',
               country: 'Country',
               city: 'City',
               district: 'District',
               portal: 'Portal',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },

   data() {
      return {
         IconLocation,
         isLoading: false,
         formDataLocation,
         dataPortals: PaginationResponse.create().map(Portal),
         providers: [
            {
               id: 'citizen-id',
               label: 'TC Kimlik',
            },
            {
               id: 'room-number',
               label: 'Oda Numarası',
            },
            {
               id: 'sms',
               label: 'SMS Doğrulama',
            },
            {
               id: 'username',
               label: 'Kullanıcı Adı',
            },
            {
               id: 'temp',
               label: 'Geçici Bağlantı',
            },
         ],
         sms_packages: [
            {
               id: 100,
               label: '100 Adet',
            },
            {
               id: 500,
               label: '500 Adet',
            },
            {
               id: 1000,
               label: '1000 Adet',
            },
            {
               id: 2000,
               label: '2000 Adet',
            },
            {
               id: 5000,
               label: '5000 Adet',
            },
            {
               id: 10000,
               label: '10000 Adet',
            },
         ],
      };
   },

   computed: {
      portalSelectOptions() {
         return this.dataPortals
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
   },
   async mounted() {
      await this.loadPortals();
   },

   methods: {
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formDataLocation.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelLocationApi.store(this.formDataLocation.toObject());

            this.formDataLocation.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async loadPortals() {
         this.isLoading = true;

         try {
            this.dataPortals.merge(await PanelPortalApi.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>
