<template>
   <ObiPage class="page-wrapper">
      <ObiRow class="settings-list-wrapper">
         <ObiCol col="12" class="mb-2" v-if="!isSetupCompleted">
            <ObiCard class="p-2">
               <div class="d-flex align-items-center">
                  <IconWarning />
                  <ObiText class="ms-2">
                     <h4 class="text-primary mb-0">{{ $t('Setup_Is_Not_Completed_Title') }}</h4>
                     <h6 class="text-secondary mt-0">{{ $t('Setup_Is_Not_Completed_Subtitle') }}</h6>
                  </ObiText>
               </div>
            </ObiCard>
         </ObiCol>
         <ObiCol col="6" class="settings-list--item">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Linked_IP_Address_Title')" :subtitle="$t('Linked_IP_Address_Subtitle')">
                     <template #actions>
                        <ObiButton
                           size="md"
                           color="primary"
                           :loading="isIpLoading"
                           :text="$t('Add_New_IP')"
                           @click="$refs.addLinkedIpModal.show()"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2 mb-4">
                     <ObiNotFound
                        v-if="profile && (!profile.linked_ip_addresses || profile.linked_ip_addresses.length === 0)"
                        compact
                        image-height="100"
                        type="user"
                        :title="$t('no_data_to_see')"
                     />
                     <div v-else-if="profile" class="d-flex">
                        <v-popover v-for="ipAddress in profile.linked_ip_addresses" :key="ipAddress">
                           <ObiDeleteButton class="me-2 bg-white text-primary" :text="ipAddress"> </ObiDeleteButton>
                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('ARE_YOU_SURE_TO_UNLIK')" />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="danger"
                                 :text="$t('YES')"
                                 v-close-popover
                                 @click="unlinkIp(ipAddress)"
                              />
                              <ObiButton class="ms-2" size="sm" color="warning" :text="$t('NO')" v-close-popover />
                           </template>
                        </v-popover>
                     </div>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
         <ObiCol col="6" class="settings-list--item">
            <ObiCard>
               <template #header>
                  <ObiTitle
                     :title="$t('Configure_Your_Hardware_Title')"
                     :subtitle="$t('Configure_Your_Hardware_Subtitle')"
                  >
                  </ObiTitle>
               </template>
               <div class="d-flex flex-row justify-content-between p-2">
                  <h5 class="text-secondary mb-0">{{ $t('DNS_Servers') }}</h5>
                  <div>
                     <h6 class="text-secondary mt-0">{{ primaryDnsServer }}</h6>
                     <h6 class="text-secondary mt-0">{{ secondaryDnsServer }}</h6>
                  </div>
               </div>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow v-if="profile" class="settings-list--item">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Logging_Title')">
                     <template #actions>
                        <ObiButtonSwitch
                           :items="enabledOptions"
                           v-model="profile.logging_enabled"
                           @click="updateProfile()"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiText>
                        {{ $t('Logging_Subtitle') }}
                     </ObiText>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow v-if="profile" class="settings-list--item">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Blocking_Page_Title')">
                     <template #actions>
                        <ObiButtonSwitch
                           :items="enabledOptions"
                           v-model="profile.display_block_page"
                           @click="updateProfile()"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiRow class="p-2">
                     <ObiText>
                        {{ $t('Blocking_Page_Subtitle') }}
                     </ObiText>
                  </ObiRow>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ObiRow v-if="profile && isAdmin" class="settings-list--item">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('Captive_Enabled_Title')" :subtitle="$t('Captive_Enabled_Subtitle')">
                     <template #actions>
                        <ObiButtonSwitch
                           :items="enabledOptions"
                           v-model="profile.is_captive_enabled"
                           @click="changeCaptiveStatus()"
                        />
                     </template>
                  </ObiTitle>
               </template>
               <template #body>
                  <ObiTable
                     isDataArray
                     :headers="networkHeaders"
                     hideHeader
                     :isLoading="isNetworksLoading"
                     :data="dataNetworks"
                  >
                     <template #item="{ item: network }">
                        <div class="d-flex align-items-center flex-row">
                           <ObiText class="me-2">{{
                              `${network.name}-${network.starting_ip}/${network.end_ip}`
                           }}</ObiText>
                           <ObiButtonSwitch
                              :items="enabledOptions"
                              v-model="network.is_captive_enabled"
                              @click="changeNetworkCaptiveStatus(network)"
                           />
                        </div>
                     </template>
                  </ObiTable>
                  <ObiNotFound
                     compact
                     image-height="150"
                     type="user"
                     :title="$t('NO_NETWORK_DATA')"
                     v-if="!isNetworksLoading && dataNetworks.length === 0"
                  />
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <AddLinkedIpModal ref="addLinkedIpModal" @save="addLinkedIp" />
   </ObiPage>
</template>

<script>
import set from 'lodash/set';
import { mapGetters } from 'vuex';
import merge from 'lodash/merge';
import IconWarning from '@/components/Icons/general/IconWarning.vue';
import AddLinkedIpModal from './_Modals/AddLinkedIpModal.vue';
import FirewallProfileApi from '@/api/FirewallProfileApi';
import ErrorHandler from '@/libs/error-handler';
import FirewallNetworkApi from '../../../api/FirewallNetworkApi';

export default {
   i18n: {
      messages: {
         tr: {
            Logging_Title: 'Kayıt Tutma',
            Logging_Subtitle:
               'Günlüğü depolamayı aç veya kapat. Etkinleştirildiğinde, günlükler analiz için saklanacaktır',
            Blocking_Page_Title: 'Engelleme Sayfası',
            Blocking_Page_Subtitle:
               'Bu seçenek etkinleştirildiğinde, bir alan adına erişim engellendiğinde bir engelleme sayfası görüntülenir. \r\nLütfen bunun sayfa yükleme süresini hafifçe artırabileceğini ve bazı durumlarda bir HTTPS uyarısı görünebileceğini unutmayın. Devre dışı bırakıldığında, engellenen sorgular bir engelleme sayfası görüntülemeden reddedilecektir. Engellenen alanlarla ilgili tercihlerinize en uygun seçeneği seçin.',
            Enabled: 'Etkin',
            Disabled: 'Devre Dışı',
            Setup_Is_Not_Completed_Title: 'Bu profil için kurulum tamamlanmamış',
            Setup_Is_Not_Completed_Subtitle:
               'Kuruluma devam edebilmek için öncelikle sabit IP adreslerinizi eklemelisiniz',
            Configure_Your_Hardware_Title: 'Donanımınızı Yapılandırın',
            Configure_Your_Hardware_Subtitle:
               'Aşağıdaki bilgileri kullanarak donanımınız üzerinden DNS yönlendirmesini tanımlayınız',
            DNS_Servers: 'DNS Sunucuları',
            Linked_IP_Address_Title: 'Atanmış Sabit IPler',
            Linked_IP_Address_Subtitle: 'Profili kullanacak sabit iplerinizi bu listeye ekleyebilirsiniz',
            Add_New_IP: 'Yeni IP Ekle',
            no_data_to_see: 'Atanmış IP yok',
            ARE_YOU_SURE_TO_UNLIK: 'IP Adresini kaldırmak istediğinize emin misiniz?',
            YES: 'Evet',
            NO: 'Hayır',
            NO_NETWORK_DATA: 'Tanımlı network kaydı bulunamadı',
            Captive_Enabled_Title: 'Ağ üzerinde oturum açma',
            Captive_Enabled_Subtitle:
               'Ağınız üzerinden kullanıcıların oturum açmasını aktif etmek için bu seçeneği aktifleştirin. Firewall->Network ayarlarında network tanımlayarak doğrulamayı sadece istediğiniz IP bloğu için geçerli yapabilirsiniz. Bir network girilmemişse tüm istekler için captive portal aktif edilecektir.',
         },
         en: {
            Logging_Title: 'Logging',
            Logging_Subtitle: 'Toggle log storage on or off. When enabled, logs will be stored for analysis',
            Blocking_Page_Title: 'Blocking Page',
            Blocking_Page_Subtitle:
               'Enabling this option will display a block page when access to a domain is denied. \r\nPlease note that this may slightly increase page load time, and in some cases, an HTTPS warning may appear. When disabled, blocked queries will be denied without displaying a block page. Choose the option that best fits your preferences for handling blocked domains.',
            Enabled: 'Enabled',
            Disabled: 'Disabled',
            DNS_Servers: 'DNS Servers',
            Configure_Your_Hardware_Title: 'Configure Your Hardware',
            Configure_Your_Hardware_Subtitle:
               'Using the following information, define DNS routing through your hardware',
            Linked_IP_Address_Title: 'Linked IP Addresses',
            Linked_IP_Address_Subtitle: 'You can add your fixed IP addresses that will use the profile to this list',
            Add_New_IP: 'Add New IP',
            no_data_to_see: 'No Linked IP yet',
            ARE_YOU_SURE_TO_UNLIK: 'Are you sure to delete Linked IP?',
            YES: 'Yes',
            NO: 'No',
            NO_NETWORK_DATA: 'Tanım network kaydı bulunamadı',
            Setup_Is_Not_Completed_Title: 'Setup Not Completed for This Profile',
            Setup_Is_Not_Completed_Subtitle: 'To continue, you must first add your static IP addresses.',
            Captive_Enabled_Title: 'Login on Network',
            Captive_Enabled_Subtitle:
               'Enable this option to allow users to log in over your network. You can make authentication valid only for the IP blocks you want by defining networks in Firewall->Network settings. If no network is entered, captive portal will be activated for all requests.',
         },
      },
   },
   components: { IconWarning, AddLinkedIpModal },
   computed: {
      ...mapGetters({ isSetupCompleted: 'auth/isSetupCompleted' }),
      ...mapGetters({ currentProfile: 'auth/currentProfile' }),
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      primaryDnsServer() {
         return this.currentProfile.primary_dns_server;
      },
      secondaryDnsServer() {
         return this.currentProfile.secondary_dns_server;
      },
   },
   data() {
      return {
         enabledOptions: [
            { id: true, label: this.$t('Enabled') },
            { id: false, label: this.$t('Disabled') },
         ],
         isIpLoading: false,
         trackersProtectionEnabled: false,
         adsProtectionEnabled: false,
         captivePortalEnabled: false,
         profile: null,
         dataNetworks: [],
         isNetworksLoading: false,
         networkHeaders: [
            {
               text: '',
               value: 'item',
               sortable: false,
            },
         ],
      };
   },
   async beforeMount() {
      await this.getCurrentProfile();
      await this.getNetworks();
   },
   methods: {
      merge,
      set,
      async getCurrentProfile() {
         try {
            const data = await FirewallProfileApi.show();
            this.profile = data;
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async addLinkedIp(ipAddress) {
         this.isIpLoading = true;

         try {
            await FirewallProfileApi.linkIp(ipAddress);
            await this.$store.dispatch('auth/me');
            await this.getCurrentProfile();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isIpLoading = false;
         }
      },
      async unlinkIp(ipAddress) {
         this.isIpLoading = true;

         try {
            await FirewallProfileApi.unlinkIp(ipAddress);
            await this.$store.dispatch('auth/me');
            await this.getCurrentProfile();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isIpLoading = false;
         }
      },
      async updateProfile() {
         try {
            await FirewallProfileApi.update(this.profile);
            await this.$store.dispatch('auth/me');
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async changeCaptiveStatus() {
         try {
            await FirewallProfileApi.changeCaptiveStatus();
            await this.$store.dispatch('auth/me');
            await this.getCurrentProfile();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async changeNetworkCaptiveStatus(network) {
         try {
            await FirewallNetworkApi.changeCaptiveStatus(network.hid, {
               is_captive_enabled: network.is_captive_enabled,
            });
            await this.getNetworks();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
      async getNetworks() {
         this.isNetworksLoading = true;

         try {
            const data = await FirewallNetworkApi.index();
            this.dataNetworks = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isNetworksLoading = false;
         }
      },
   },
};
</script>

<style lang="scss">
.warning-pane {
   background-color: #ff8e8e !important;
}
.page-wrapper {
   padding: 10px;
   margin-bottom: 20px;
}

.settings-list--item {
   margin-bottom: 10px;
}

@media (max-width: 768px) {
   .settings-list--item {
      flex: 0 0 100% !important;
   }
}
</style>
