<template>
   <ObiPage>
      <ObiCard class="my-4">
         <template #header>
            <ObiTitle :icon="IconPortal" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalPortalCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>

         <template #body>
            <ObiTable
               :isLoading="isLoading"
               :headers="tableHeaders"
               :data="dataPortals.get('data')"
               v-if="!isLoading && dataPortals.get('meta.total')"
            >
               <template #created_at="{ item: portal }">{{ trFormatDate(portal.getCreatedAt()) }}</template>
               <template #actions="{ item: portal }">
                  <ObiButton
                     size="sm"
                     color="primary"
                     outline
                     as="router-link"
                     :text="$t('actions.edit')"
                     :to="{ name: 'portals-edit', params: { portalId: portal.getId() } }"
                  />
                  <ObiButton
                     size="sm"
                     color="danger"
                     outline
                     class="ms-2"
                     :text="$t('actions.delete')"
                     v-confirm="{
                        loader: true,
                        okText: 'Evet',
                        cancelText: 'Hayır',
                        ok: (dialog) => deletePortal(dialog, portal),
                        message: 'Portalı silmek istediğinize emin misiniz?',
                     }"
                  />
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoading && !dataPortals.get('meta.total')" icon="locations">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalPortalCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoading" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataPortals.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalPortalCreate ref="modalPortalCreate" @success="loadPortals" />
   </ObiPage>
</template>

<script>
import merge from 'lodash/merge';

import IconPortal from '@/components/Icons/general/IconPortal';
import ModalPortalCreate from '@/views/pages/Portal/_Modals/Create';

import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';

import PanelPortalApi from '@/api/PanelPortalApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';
import Portal from '@/api/Models/Portal';

export default {
   name: 'PortalIndex',

   i18n: {
      messages: {
         tr: {
            page_title: 'Portallarım',
            actions: {
               create: 'Portal Ekle',
               edit: 'Düzenle',
               delete: 'Sil',
            },
            fields: {
               name: 'Portal Adı',
               created_at: 'Oluşturma Tarihi',
               actions: 'İşlemler',
            },
         },
         en: {
            page_title: 'My Portals',
            actions: {
               create: 'Add Portal',
               edit: 'Edit',
               delete: 'Delete',
            },
            fields: {
               name: 'Portal Name',
               created_at: 'Creation Date',
               actions: 'Actions',
            },
         },
      },
   },

   components: {
      ModalPortalCreate,
   },

   data() {
      return {
         IconPortal,
         isLoading: false,
         dataPortals: PaginationResponse.create().map(Portal),
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
               width: 200,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               width: 200,
               class: 'text-end',
            },
         ],
      };
   },

   async mounted() {
      await this.loadPortals();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.$router.push({ query: { ...this.$route.query, page } });
      },

      async loadPortals() {
         try {
            let params = {};
            this.isLoading = true;

            params = merge(this.$route.query, params);

            this.dataPortals.merge(await PanelPortalApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async deletePortal(dialog, portal) {
         try {
            this.isLoading = true;
            await PanelPortalApi.delete(portal.getId(), null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            dialog.close();
            this.loadPortals();
         }
      },
   },

   watch: {
      async $route(to) {
         await this.loadPortals({ page: to.query.page });
      },
   },
};
</script>

<style lang="scss" scoped></style>
