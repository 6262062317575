<template>
   <ObiText class="obi-sidebar">
      <ObiText as="ul" class="nav flex-column nav-main">
         <ObiText as="li" class="nav-item" v-for="routeItem in routeItems" :key="routeItem.id">
            <RouterLink
               class="nav-link"
               :class="{ active: routeIsActive(routeItem) }"
               :to="routeItem.to"
               :style="{ opacity: routeItem.disableIfSetupNotCompleted && !isSetupCompleted ? 0.5 : 1 }"
               v-if="!routeItem.only_admin"
            >
               <component :is="routeItem.icon" />
               <span v-html="$t(routeItem.text)" />
            </RouterLink>
            <RouterLink
               class="nav-link"
               :class="{ active: routeIsActive(routeItem) }"
               :to="routeItem.to"
               :style="{ opacity: routeItem.disableIfSetupNotCompleted && !isSetupCompleted ? 0.5 : 1 }"
               v-if="routeItem.only_admin && isAdmin"
            >
               <component :is="routeItem.icon" />
               <span v-html="$t(routeItem.text)" />
            </RouterLink>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { mapGetters } from 'vuex';
import IconPrivacy from '@/components/Icons/general/IconPrivacy';
import IconGear from '@/components/Icons/general/IconGear';
import IconDashboard from '@/components/Icons/general/IconDashboard';
import IconVisitor from '@/components/Icons/general/IconVisitor';
//import IconCaptive from '@/components/Icons/general/IconCaptive';
import IconLogs from '../Icons/general/IconLogs.vue';
import IconCampaign from '../Icons/general/IconCampaign.vue';

export default {
   name: 'ObiSidebar',
   props: {},
   i18n: {
      messages: {
         tr: {
            sidebar: {
               privacy: 'Privacy',
               Firewall: 'Firewall',
               Members: 'Üyeler',
               Captive: 'Captive',
               Logs: 'Loglar',
               Campaigns: 'Kampanyalar',
               Settings: 'Ayarlar',
            },
         },
         en: {
            sidebar: {
               privacy: 'Privacy',
               Firewall: 'Firewall',
               Members: 'Members',
               Captive: 'Captive',
               Logs: 'Logs',
               Campaigns: 'Campaigns',
               Settings: 'Settings',
            },
         },
      },
   },

   data() {
      return {
         routeItems: [
            {
               id: 0,
               text: 'sidebar.dashboard',
               icon: IconDashboard,
               disableIfSetupNotCompleted: true,
               to: {
                  name: 'dashboard-index',
               },
            },
            {
               id: 5,
               text: 'sidebar.Firewall',
               icon: IconPrivacy,
               disableIfSetupNotCompleted: true,
               to: {
                  name: 'firewall-index',
               },
            },
            {
               id: 6,
               text: 'sidebar.Members',
               icon: IconVisitor,
               disableIfSetupNotCompleted: true,
               only_admin: true,
               to: {
                  name: 'members-index',
               },
            },
            /*{
               id: 7,
               text: 'sidebar.Captive',
               icon: IconCaptive,
               disableIfSetupNotCompleted: true,
               to: {
                  name: 'captive-index',
               },
            },*/
            {
               id: 8,
               text: 'sidebar.Logs',
               icon: IconLogs,
               disableIfSetupNotCompleted: true,
               to: {
                  name: 'logs-index',
               },
            },
            {
               id: 9,
               text: 'sidebar.Campaigns',
               icon: IconCampaign,
               disableIfSetupNotCompleted: true,
               to: {
                  name: 'campaigns-index',
               },
            },
            {
               id: 10,
               text: 'sidebar.settings',
               icon: IconGear,
               disableIfSetupNotCompleted: false,
               to: {
                  name: 'settings-index',
               },
            },
         ],
      };
   },
   methods: {
      routeIsActive(routeItem) {
         return routeItem.to && this.$route.name === routeItem.to.name;
      },
      logout() {
         this.$store.dispatch('auth/logout', { redirectRoute: 'auth-login' });
      },
   },
   computed: {
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      ...mapGetters({ isSetupCompleted: 'auth/isSetupCompleted' }),
      ...mapGetters('auth', ['authUser', '']),
   },
};
</script>

<style lang="scss" scoped>
.obi-sidebar {
   display: flex;
   flex-flow: row;
   flex-shrink: 1;
   flex-grow: 1;
   height: 100%;
   background-color: #2f71f2;

   .nav {
      display: contents;
      &.nav-main {
         gap: 15px;
      }
      &.nav-footer {
         justify-content: flex-start;
         position: sticky;
         bottom: 20px;
      }

      .nav-item {
         .nav-link {
            gap: 10px;
            display: flex;
            color: #ffffff;
            border-radius: 0px;
            align-items: center;
            justify-content: flex-start;
            height: 46px;

            svg {
               width: 22px;
               height: 22px;
               color: #ffffff;
               stroke: #ffffff;
            }

            &.active {
               background-color: #ffffff;
               color: #2f71f2;
               fsont-weight: 700 !important;
               svg {
                  stroke: #2f71f2;
               }
            }
         }
      }
   }
}
</style>
