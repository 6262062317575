import Http from '@/libs/http';

class PanelDeviceMacApi extends Http {
   index(deviceId, params) {
      return super.get(`panel/device/${deviceId}/macs`, { params });
   }

   store(deviceId, payload, params) {
      return super.post(`panel/device/${deviceId}/macs`, payload, { params });
   }

   show(deviceId, id, params) {
      return super.get(`panel/device/${deviceId}/macs/${id}`, { params });
   }

   update(deviceId, id, payload, params) {
      return super.put(`panel/device/${deviceId}/macs/${id}`, payload, { params });
   }

   delete(deviceId, id, params) {
      return super.delete(`panel/device/${deviceId}/macs/${id}`, { params });
   }
}

export default new PanelDeviceMacApi();
