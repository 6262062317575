<template>
   <div class="obi-dashboard">
      <ObiHeader />
      <ObiTabMenu />
      <router-view />
   </div>
</template>
<script>
import ObiHeader from '@/components/Common/ObiHeader';
import ObiTabMenu from '@/components/Common/ObiTabMenu';

export default {
   components: {
      ObiHeader,
      ObiTabMenu,
   },
   data() {
      return {
         routeIsDashboard: this.$router.currentRoute.name === 'dashboard-admin',
      };
   },
   watch: {
      $route(newVal) {
         this.routeIsDashboard = newVal.name === 'dashboard-admin';
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-dashboard {
   height: 100%;
   display: grid;
   grid-template-rows: auto 1fr;
   overflow: hidden;
}
.obi-content {
   margin-top: 0px;
   .obi-content--content {
      margin-top: 0px !important;
   }
}
</style>
