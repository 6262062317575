import Vue from 'vue';
import { clickOutside } from '@/components/directives';

Vue.directive('click-outside', clickOutside);

// UI Components
Vue.component('ObiCol', () => import('@/components/UI/ObiCol'));
Vue.component('ObiRow', () => import('@/components/UI/ObiRow'));
Vue.component('Obi404', () => import('@/components/UI/Obi404'));
Vue.component('ObiAlert', () => import('@/components/UI/ObiAlert'));
Vue.component('ObiNav', () => import('@/components/UI/ObiNav'));
Vue.component('ObiText', () => import('@/components/UI/ObiText'));
Vue.component('ObiIcon', () => import('@/components/UI/ObiIcon'));
Vue.component('ObiForm', () => import('@/components/UI/ObiForm'));
Vue.component('ObiCard', () => import('@/components/UI/ObiCard'));
Vue.component('ObiTabs', () => import('@/components/UI/ObiTabs'));
Vue.component('ObiInput', () => import('@/components/UI/ObiInput'));
Vue.component('ObiTitle', () => import('@/components/UI/ObiTitle'));
Vue.component('ObiTable', () => import('@/components/UI/ObiTable'));
Vue.component('ObiModal', () => import('@/components/UI/ObiModal'));
Vue.component('ObiImage', () => import('@/components/UI/ObiImage'));
Vue.component('ObiSelect', () => import('@/components/UI/ObiSelect'));
Vue.component('ObiButton', () => import('@/components/UI/ObiButton'));
Vue.component('ObiTabBar', () => import('@/components/UI/ObiTabBar'));
Vue.component('ObiTagsInput', () => import('@/components/UI/ObiTagsInput'));
Vue.component('ObiContainer', () => import('@/components/UI/ObiContainer'));
Vue.component('ObiFileUpload', () => import('@/components/UI/ObiFileUpload'));
Vue.component('ObiPagination', () => import('@/components/UI/ObiPagination'));
Vue.component('ObiCardMetric', () => import('@/components/UI/ObiCardMetric'));
Vue.component('ObiColorPicker', () => import('@/components/UI/ObiColorPicker'));
Vue.component('ObiButtonGroup', () => import('@/components/UI/ObiButtonGroup'));
Vue.component('ObiButtonSwitch', () => import('@/components/UI/ObiButtonSwitch'));
Vue.component('ObiFloatingInput', () => import('@/components/UI/ObiFloatingInput'));
Vue.component('ObiDropdown', () => import('@/components/UI/ObiDropdown/ObiDropdown'));
Vue.component('ObiDropdownItem', () => import('@/components/UI/ObiDropdown/ObiDropdownItem'));
Vue.component('ObiDropdownToggle', () => import('@/components/UI/ObiDropdown/ObiDropdownToggle'));
Vue.component('ObiDropdownDivider', () => import('@/components/UI/ObiDropdown/ObiDropdownDivider'));
Vue.component('ObiCheckbox', () => import('@/components/UI/ObiCheckbox'));
Vue.component('ObiNotification', () => import('@/components/UI/ObiNotification'));
Vue.component('ObiSwitch', () => import('@/components/UI/ObiSwitch'));
Vue.component('ObiRuleCard', () => import('@/components/UI/ObiRuleCard'));
Vue.component('ObiPaidSale', () => import('@/components/UI/ObiPaidSale'));
Vue.component('ObiLocationCard', () => import('@/components/UI/ObiLocationCard'));
Vue.component('ObiDeleteButton', () => import('@/components/UI/ObiDeleteButton'));
Vue.component('ObiDomainItem', () => import('@/components/UI/ObiDomainItem'));

// UI Chart Components
Vue.component('ObiBarChart', () => import('@/components/UI/ObiCharts/ObiBarChart'));
Vue.component('ObiLineChart', () => import('@/components/UI/ObiCharts/ObiLineChart'));
Vue.component('ObiPieChart', () => import('@/components/UI/ObiCharts/ObiPieChart'));

// Common Components
Vue.component('ObiApp', () => import('@/components/Common/ObiApp'));
Vue.component('ObiPage', () => import('@/components/Common/ObiPage'));
Vue.component('ObiLogo', () => import('@/components/Common/ObiLogo'));
Vue.component('ObiHeader', () => import('@/components/Common/ObiHeader'));
Vue.component('AdminHeader', () => import('@/components/Common/AdminHeader'));
Vue.component('ObiToolbar', () => import('@/components/Common/ObiToolbar'));
Vue.component('ObiContent', () => import('@/components/Common/ObiContent'));
Vue.component('ObiSidebar', () => import('@/components/Common/ObiSidebar'));
Vue.component('ObiNotFound', () => import('@/components/Common/ObiNotFound'));
Vue.component('ObiPortalEditor', () => import('@/components/Common/ObiPortalEditor'));
Vue.component('ObiThemePreview', () => import('@/components/Common/ObiThemePreview'));
Vue.component('ObiLoading', () => import('@/components/Common/ObiLoading'));

// Device Components
Vue.component('ObiDeviceCardMini', () => import('@/components/UI/Devices/ObiDeviceCardMini'));

// Portal Layouts Components
Vue.component('SplittedLayout', () => import('@/components/PortalLayouts/SplittedLayout'));

Vue.component('MultiLanguageInput', () => import('@/components/MultiLanguageInput/MultiLanguageInput'));
Vue.component('ObiOnBoardingHeader', () => import('@/components/Common/ObiOnBoardingHeader'));
Vue.component('ObiUserProfile', () => import('@/components/UI/ObiUserProfile'));

Vue.component('IconFlagTr', () => import('@/components/Icons/flags/IconFlagTr'));
Vue.component('IconFlagEn', () => import('@/components/Icons/flags/IconFlagEn'));
Vue.component('IconLanguage', () => import('@/components/Icons/IconLanguage'));
