<template>
   <ObiRow justify-center>
      <ObiCol col="12" sm="10">
         <!-- <ObiRow justify-between align-start class="mb-3">
            <ObiTitle class="my-2" color="primary" :title="$t('fields.old_password')" />

            <ObiCol col="12" lg="6">
               <ObiFloatingInput
                  class="m-2 password"
                  :label="$t('fields.old_password')"
                  type="password"
                  v-model="formDataAccount.old_password"
                  :errors="formDataAccount.errors.get('old_password')"
               />
            </ObiCol>
         </ObiRow> -->

         <ObiRow justify-between align-end class="mb-3">
            <ObiTitle class="my-2" color="primary" :title="$t('fields.new_password')" />
            <ObiCol col="12" lg="6">
               <ObiFloatingInput
                  class="m-2 password"
                  :label="$t('fields.new_password')"
                  type="password"
                  v-model="formDataAccount.password"
                  :errors="formDataAccount.errors.get('password')"
               />
               <ObiFloatingInput
                  class="m-2 password"
                  :label="$t('fields.new_password_confirmation')"
                  type="password"
                  v-model="formDataAccount.password_confirmation"
                  :errors="formDataAccount.errors.get('password_confirmation')"
               />
            </ObiCol>
            <!-- <ObiCol col="12">
               <ObiText as="small">
                  Parolanızı Değiştirdiğinizde Mail Adresinize Bir Doğrulama Maili Gönderilecektir
               </ObiText>
            </ObiCol> -->
         </ObiRow>
      </ObiCol>
   </ObiRow>
</template>

<script>
import Form from '@/libs/form';
export default {
   i18n: {
      messages: {
         tr: {
            fields: {
               new_password: 'Yeni Parola',
               new_password_confirmation: 'Yeni Parola Onay',
            },
         },
         en: {
            fields: {
               new_password: 'New Password',
               new_password_confirmation: 'Confirm New Password',
            },
         },
      },
   },
   data() {
      return {};
   },
   props: {
      formDataAccount: {
         type: Form,
      },
   },
   methods: {},
};
</script>

<style lang="scss">
.password {
   input[type='password'] {
      font-family: Verdana;
      letter-spacing: 0.125em;
   }
}
</style>
