import Vue from 'vue';
import Vuex from 'vuex';
import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import createLogger from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
   modules: {
      auth,
      app,
   },
   strict: debug,
   plugins: debug ? [createLogger(), createPersistedState()] : [createPersistedState()],
});

const setBrowserLanguage = () => {
   const browserLanguage = navigator.language || navigator.languages[0];

   if (store.state.app.availableLanguages.includes(browserLanguage)) {
      store.commit('app/setCurrentLanguage', browserLanguage);
   } else {
      const defaultLanguage = 'en';
      store.commit('app/setCurrentLanguage', defaultLanguage);
   }
};

store.subscribe((mutation, state) => {
   if (mutation.type === 'app/setCurrentLanguage' && !state.app.currentLanguage) {
      setBrowserLanguage();
   }
});
export default store;
