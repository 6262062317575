import Http from '@/libs/http';

class PanelPortalRuleApi extends Http {
   index(portalId, params) {
      return super.get(`panel/portal/${portalId}/rules`, { params });
   }

   store(portalId, payload, params) {
      return super.post(`panel/portal/${portalId}/rules`, payload, { params });
   }

   show(portalId, params) {
      return super.get(`panel/portal/${portalId}/rules`, { params });
   }

   update(portalId, adId, payload, params) {
      return super.put(`panel/portal/${portalId}/rules/${adId}`, payload, { params });
   }

   delete(portalId, adId, params) {
      return super.delete(`panel/portal/${portalId}/rules/${adId}`, { params });
   }
}

export default new PanelPortalRuleApi();
