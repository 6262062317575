import Form from '@/libs/form';

const fields = ['hid', 'name', 'starting_ip', 'end_ip'];

const rules = {
   name: 'required',
   starting_ip: 'required',
   end_ip: 'required',
};

export default Form.create(fields)
   .defaults({
      hid: null,
      name: null,
      starting_ip: null,
      end_ip: null,
   })
   .validate(rules);
