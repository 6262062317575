<template>
   <ObiCard :hideBody="!isEditing">
      <template #header>
         <ObiTitle :icon="RuleCardIcon">
            <ObiText v-if="type === 'emoji'" class="d-flex">
               <ObiText v-html="title" />
               <emoji :emoji="{ id: formDataAnswer.emoji, skin: 3 }" :size="16" />
            </ObiText>
            <ObiText v-else>
               <ObiText v-html="title" />
            </ObiText>

            <template #actions>
               <ObiCol class="d-flex">
                  <ObiButton compact size="lg" color="lighter" :icon="EditIcon" @click="$emit('edit', index)" />
                  <v-popover>
                     <ObiButton class="ms-2" size="lg" compact color="lighter" :icon="DeleteIcon" />

                     <template slot="popover">
                        <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                        <ObiButton
                           class="ms-2"
                           size="sm"
                           color="danger"
                           :text="$t('actions.yes')"
                           v-close-popover
                           @click="deleteItem"
                        />
                        <ObiButton class="ms-2" size="sm" color="warning" :text="$t('actions.no')" v-close-popover />
                     </template>
                  </v-popover>
               </ObiCol>
            </template>
         </ObiTitle>
      </template>
      <template #body class="p-4">
         <ObiRow class="p-4">
            <ObiCol col="12">
               <MultiLanguageInput reverse v-model="formDataAnswer" name="label" @input="onChanged" />
            </ObiCol>
            <ObiCol col="12" v-if="type === 'emoji'">
               <picker set="emojione" class="w-100" @select="emojiSelected" />
            </ObiCol>
         </ObiRow>
      </template>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';
import { Picker, Emoji } from 'emoji-mart-vue';

import RuleCardIcon from '@/components/Icons/portals/RuleCardIcon';
import EditIcon from '@/components/Icons/portals/EditIcon.svg';
import DeleteIcon from '@/components/Icons/portals/DeleteIcon.svg';
import IconExternalLink from '@/components/Icons/general/IconExternalLink.svg';

export default {
   name: 'ObiSurveyQuestionAnswerCard',
   components: {
      Picker,
      Emoji,
   },
   i18n: {
      messages: {
         tr: {
            last_seen: 'Son Görülme',
            are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
            labels: {
               answer_type: 'Cevap Türü',
               answers: 'Cevaplar',
            },
            fields: {
               name: 'Soru',
               state: 'Durum',
               action: 'Aksiyon',
               survey: 'Anket',
               target_url: 'Hedef Adres',
            },
            actions: {
               yes: 'Evet',
               no: 'Hayır',
               add_answer: 'Cevap Ekle',
            },
         },
         en: {
            last_seen: 'Last Seen',
            are_you_sure_delete: 'Are you sure you want to delete?',
            labels: {
               answer_type: 'Answer Type',
               answers: 'Answers',
            },
            fields: {
               name: 'Question',
               state: 'Status',
               action: 'Action',
               survey: 'Survey',
               target_url: 'Target URL',
            },
            actions: {
               yes: 'Yes',
               no: 'No',
               add_answer: 'Add Answer',
            },
         },
      },
   },
   props: {
      answer: {
         type: Object,
      },
      isEditing: {
         type: Boolean,
         default: false,
      },
      type: {
         type: String,
         default: 'text',
      },
      index: {
         type: Number,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      title() {
         return this.formDataAnswer && this.formDataAnswer.label && this.formDataAnswer.label.tr
            ? this.formDataAnswer.label.tr
            : 'Yeni Cevap';
      },
   },
   mounted() {
      if (this.$props.answer !== null) {
         this.formDataAnswer = this.$props.answer;
      }
   },
   data() {
      return {
         IconExternalLink,
         RuleCardIcon,
         EditIcon,
         DeleteIcon,
         formDataAnswer: { label: { tr: null, en: null, de: null, ru: null, ar: null }, emoji: null },
      };
   },
   methods: {
      async onChanged() {
         this.$emit('change', this.formDataAnswer);
      },
      async deleteItem() {
         this.$emit('delete', this.formDataAnswer);
      },
      async addNewAnswer() {
         this.$emit('added', this.formDataAnswer);
      },
      async cancelAddingAnswer() {
         this.$emit('cancel');
      },
      async emojiSelected(emoji) {
         this.formDataAnswer.emoji = emoji.id;
         this.onChanged();
      },
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-dcm {
   padding: 20px;
   display: grid;
   grid-gap: 20px;
   border-radius: 8px;
   align-items: center;
   background-color: #f3f8fe;
   justify-content: space-between;
   grid-template-columns: minmax(120px, max-content) 1fr auto;

   .obi-dcm-image {
      text-align: center;

      .obi-image {
         width: unset;
         max-width: 90px;
         max-height: 45px;
         object-fit: cover;
         object-position: center;
      }
   }

   .obi-dcm-content {
      display: grid;
   }

   .obi-dcm-name {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-dcm-location {
      font-size: 0.8rem;
   }

   .obi-dcm-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 12px;

      &.online {
         background: #31ba3f;
      }

      &.offline {
         background: #ff8e8e;
      }
   }

   .obi-dcm-action {
   }
}
</style>
