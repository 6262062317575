import Http from '@/libs/http';

class InvitationApi extends Http {
   accept(token, params) {
      return super.post(`team/accept/${token}`, params);
   }

   deny(token, params) {
      return super.post(`team/deny/${token}`, params);
   }

   confirmEmail(params) {
      return super.post(`visitor/confirm-email`, params);
   }
}

export default new InvitationApi();
