import Form from '@/libs/form';

const fields = [
   'time_limit',
   'time_limit_unit',
   'time_limit_enabled',
   'daily_bandwidth',
   'daily_bandwidth_unit',
   'daily_bandwidth_enabled',
   'download_limit',
   'download_limit_enabled',
   'upload_limit',
   'upload_limit_enabled',
   'mac_whitelist',
   'mac_whitelist_enabled',
   'mac_blacklist',
   'mac_blacklist_enabled',
];

const rules = {
   time_limit: 'min:1|max:30',
   time_limit_unit: 'string',
   time_limit_enabled: 'boolean',

   daily_bandwidth: 'min:1|max:1024',
   daily_bandwidth_unit: 'string',
   daily_bandwidth_enabled: 'boolean',

   download_limit: 'numeric',
   download_limit_enabled: 'boolean',

   upload_limit: 'numeric',
   upload_limit_enabled: 'boolean',

   mac_whitelist: 'array',
   mac_whitelist_enabled: 'boolean',

   mac_blacklist: 'array',
   mac_blacklist_enabled: 'boolean',
};

const defaults = {
   time_limit: 10,
   time_limit_unit: 'hour',
   time_limit_enabled: false,

   daily_bandwidth: 1,
   daily_bandwidth_unit: 'GB',
   daily_bandwidth_enabled: false,

   download_limit: 10,
   download_limit_enabled: false,

   upload_limit: 10,
   upload_limit_enabled: false,

   mac_whitelist: [],
   mac_whitelist_enabled: false,

   mac_blacklist: [],
   mac_blacklist_enabled: false,
};

export default Form.create(fields)
   .validate(rules)
   .defaults(defaults);
