<template>
   <ObiPage>
      <ObiToolbar :title="$t('page_title.account')" hideSearch hideDatePicker>
         <template slot="actions">
            <ObiText as="h6" class="text-primary m-0">
               {{ user.full_name }}
            </ObiText>
         </template>
      </ObiToolbar>

      <ObiCard class="my-4">
         <template #body>
            <ObiRow class="pt-5 ps-5">
               <ObiCol col="12" md="4" lg="3">
                  <ul class="account-nav nav">
                     <router-link
                        v-for="route in routeItems"
                        :key="route.to.name"
                        class="nav-item"
                        active-class="active"
                        tag="li"
                        :to="{ name: route.to.name }"
                     >
                        {{ $t(`${route.text}`) }}
                        <i class="mdi mdi-chevron-right ms-5"></i>
                     </router-link>
                  </ul>
               </ObiCol>

               <ObiCol col="12" md="8" lg="9">
                  <router-view :formDataAccount="formDataAccount" />
                  <ObiRow justify-end align-end>
                     <ObiCol col="12" lg="6">
                        <ObiRow justify-end align-end class="mb-3">
                           <ObiCol col="auto">
                              <ObiButton
                                 @click="reset()"
                                 color="secondary"
                                 class="px-4 m-1"
                                 :text="$t('actions.reset')"
                              />
                           </ObiCol>
                           <ObiCol col="auto">
                              <ObiButton
                                 @click="save()"
                                 :loading="isSaving"
                                 class="px-4 m-1"
                                 :text="$t('actions.save_settings')"
                              />
                           </ObiCol>
                        </ObiRow>
                     </ObiCol>
                  </ObiRow>
               </ObiCol>
            </ObiRow>
         </template>
      </ObiCard>
   </ObiPage>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import formDataAccount from '@/views/pages/Account/FormData/form-data-account';
import AuthApi from '@/api/AuthApi';
import ErrorHandler from '@/libs/error-handler';
import Notify from '@/libs/notify';

export default {
   i18n: {
      messages: {
         tr: {
            page_title: {
               account: 'Hesabım',
            },
            nav: {
               account_settings: 'Hesap Ayarları',
               password_and_security: 'Şifre ve Güvenlik',
            },
            actions: {
               save_settings: 'Ayarları Kaydet',
               reset: 'Geri Al',
            },
         },
         en: {
            page_title: {
               account: 'My Account',
            },
            nav: {
               account_settings: 'Account Settings',
               password_and_security: 'Password and Security',
            },
            actions: {
               save_settings: 'Save Settings',
               reset: 'Reset',
            },
         },
      },
   },
   data() {
      return {
         formDataAccount,
         isSaving: false,
         routeItems: [
            {
               text: 'nav.account_settings',
               to: {
                  name: 'account-settings',
               },
            },
            {
               text: 'nav.password_and_security',
               to: {
                  name: 'account-security',
               },
            },
         ],
      };
   },
   computed: {
      ...mapGetters({ user: 'auth/authUser' }),
   },
   beforeMount() {
      this.formDataAccount.merge(this.user);
      this.formDataAccount.defaults(this.formDataAccount.getAttributes());
   },
   methods: {
      ...mapMutations({
         commitAccount: 'auth/me',
      }),
      save() {
         this.submitSettings();
      },
      async submitSettings() {
         this.isSaving = true;

         console.log('isValid: ', this.formDataAccount.isValid());
         console.log(this.formDataAccount.toObject());
         console.log(this.user);

         try {
            if (this.formDataAccount.isValid()) {
               if (this.formDataAccount.password == null) console.log(this.formDataAccount.getAttributes());
               const res = await AuthApi.updateMe(this.formDataAccount.toObject());
               const user = res.message;
               this.commitAccount(user);

               Notify.success(this.$t('alert.success'));
            }
         } catch (err) {
            ErrorHandler.handle(err);
         }
         this.isSaving = false;
      },
      reset() {
         formDataAccount.reset();
      },
   },
};
</script>

<style lang="scss" scoped>
.account-nav {
   position: sticky;
   top: 1rem;
   display: flex;
   gap: 15px;
   margin: 0 1rem;
   margin-bottom: 3rem;
   flex-direction: column;
   list-style-type: none !important;
   color: #33508c;

   .nav-item {
      padding: 6px 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 100px;

      i {
         display: none;
      }

      &[class*='active'] {
         font-weight: 700;
         background: rgba($color: #2f71f226, $alpha: 0.15);

         i {
            display: block !important;
         }
      }
   }
}
</style>
