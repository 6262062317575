<template>
   <div>
      <ObiOnBoardingHeader />
      <router-view />
   </div>
</template>
<script>
export default {
   data() {
      return {
         routeIsDashboard: this.$router.currentRoute.name === 'dashboard-admin',
      };
   },
   watch: {
      $route(newVal) {
         this.routeIsDashboard = newVal.name === 'dashboard-admin';
      },
   },
};
</script>
