import Form from '@/libs/form';

const fields = [
   'name',
   'mac_address',
   'brand_id',
   'logging_enabled',
   'connection',
   'secret',
   'serial_number',
   'is_trial',
   'license_end_at',
   'trial_day',
   'trial_remain',
   'brand',
   'user_profile',
];

const rules = {
   name: 'required',
   mac_address: 'required',
   brand_id: 'required',
};

export default Form.create(fields)
   .validate(rules)
   .defaults({
      logging_enabled: false,
      connection: { host: null, port: null, username: null, password: null },
      secret: 'YeT2dXVs6D',
      serial_number: null,
      brand: {},
      user_profile: null,
   });
