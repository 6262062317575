<template>
   <div>PortalEditSignInMethods</div>
</template>

<script>
export default {
   name: 'PortalEditSignInMethods',

   i18n: {
      messages: {
         tr: {},
      },
   },

   props: {},

   data() {
      return {};
   },

   async mounted() {},

   methods: {},
};
</script>

<style lang="scss" scoped></style>
