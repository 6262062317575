<!-- eslint-disable prettier/prettier -->
<template>
   <ObiPage class="mt-4 mb-4">
      <ObiRow class="mt-4">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <div class="d-flex">
                     <input
                        class="form-control"
                        type="text"
                        :placeholder="$t('SEARCH_PLACEHOLDER_TEXT')"
                        @keyup.enter="getFirewallLogs(false)"
                        v-model="domain_filter"
                     />
                     <ObiButton
                        size="sm"
                        color="primary"
                        :icon="IconFilter"
                        class="ms-2"
                        @click="showFilters = !showFilters"
                     />
                  </div>
                  <div class="d-flex mt-4" v-if="showFilters">
                     <ObiDropdown :title="filteredIpAddress" as="filter-dropdown" outline>
                        <a
                           class="dropdown-item"
                           href="#"
                           v-for="(ipAddress, index) in ipAddressList"
                           :key="index"
                           @click="filterIpAddress(ipAddress)"
                        >
                           {{ ipAddress }}
                        </a>
                     </ObiDropdown>
                     <ObiDropdown class="ms-2" :title="filteredMacAddress" as="filter-dropdown" outline>
                        <a
                           class="dropdown-item"
                           href="#"
                           v-for="(macAddress, index) in macAddressList"
                           :key="index"
                           @click="filterMacAddress(macAddress)"
                        >
                           {{ macAddress }}
                        </a>
                     </ObiDropdown>
                     <ObiButton class="ms-2" :text="$t('CLEAR_FILTER')" @click="clearFilters()" />
                  </div>
               </template>
               <template #body>
                  <div>
                     <ObiTable
                        :headers="logHeaders"
                        hideHeader
                        lazyLoading
                        noRowPadding
                        :isLoading="isLoading"
                        :data="dataLogs.get('data')"
                        @getNextPage="getNextPage"
                     >
                        <template #domain="{ item: item }">
                           <div :class="`log-item-status-${item.get('status')} mb-1`">
                              <ObiText class="p-2 d-flex justify-content-between align-items-center">
                                 <ObiText class="ms-2">
                                    <span>{{ item.get('domain') }}</span>
                                    <p class="mb-0 text-info">
                                       <span v-if="item.get('member_ip_address') != null">{{
                                          item.get('member_ip_address')
                                       }}</span>
                                       <span v-if="item.get('member_mac_address') != null">
                                          <span v-if="item.get('member_ip_address') != null">/</span>
                                          {{ item.get('member_mac_address') }}</span
                                       >
                                    </p>
                                 </ObiText>
                                 <ObiText class="ms-2">{{
                                    timestampToDate(item.get('timestamp')).format('MMM DD YYYY HH:mm:ss')
                                 }}</ObiText>
                              </ObiText>
                           </div>
                        </template>
                     </ObiTable>
                     <ObiNotFound
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                        v-if="!isLoading && (!dataLogs.get('data') || dataLogs.get('data').length === 0)"
                     />
                  </div>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
   </ObiPage>
</template>

<script>
import set from 'lodash/set';
import merge from 'lodash/merge';

import ErrorHandler from '@/libs/error-handler';
import FirewallLogApi from '@/api/FirewallLogApi';
import FirewallProfileApi from '@/api/FirewallProfileApi';

import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import MyModel from '@/api/Models/MyModel';
import { timestampToDate } from '../../../libs/date-utils';

import IconFilter from '@/components/Icons/general/IconFilter.svg';

export default {
   i18n: {
      messages: {
         tr: {
            SEARCH_PLACEHOLDER_TEXT: 'Filtrelemek için bir alan adı arayın',
            SELECT_IP_ADDRESS_TO_FILTER: 'IP Adresini Filtrele',
            SELECT_MAC_ADDRESS_TO_FILTER: 'MAC Adresini Filtrele',
            CLEAR_FILTER: 'Sıfırla',
         },
         en: {
            SEARCH_PLACEHOLDER_TEXT: 'Search a domain to filter',
            SELECT_IP_ADDRESS_TO_FILTER: 'Filter Ip Address',
            SELECT_MAC_ADDRESS_TO_FILTER: 'Filter Mac Address',
            CLEAR_FILTER: 'Reset',
         },
      },
   },
   components: {},
   data() {
      return {
         enabledOptions: [
            { id: true, label: this.$t('Enabled') },
            { id: false, label: this.$t('Disabled') },
         ],
         IconFilter,
         trackersProtectionEnabled: false,
         adsProtectionEnabled: false,
         captivePortalEnabled: false,
         isLoading: false,
         isLazyLoading: false,
         currentPage: 1,
         perPage: 100,
         domain_filter: null,
         dataLogs: SingleResourceResponse.create().map(MyModel),
         ipAddressList: [],
         macAddressList: [],
         filtered_ip_address: null,
         filtered_mac_address: null,
         showFilters: false,
         logHeaders: [
            {
               text: '',
               value: 'domain',
               sortable: false,
            },
            {
               text: '',
               value: 'queries',
               sortable: false,
            },
         ],
      };
   },
   beforeMount() {
      this.getFirewallLogs();
      this.getIpAddressList();
      this.getMacAddressList();
   },
   computed: {
      filteredIpAddress() {
         return this.filtered_ip_address
            ? `${this.$t('SELECT_IP_ADDRESS_TO_FILTER')}: ${this.filtered_ip_address}`
            : this.$t('SELECT_IP_ADDRESS_TO_FILTER');
      },
      filteredMacAddress() {
         return this.filtered_mac_address
            ? `${this.$t('SELECT_MAC_ADDRESS_TO_FILTER')}: ${this.filtered_mac_address}`
            : this.$t('SELECT_MAC_ADDRESS_TO_FILTER');
      },
   },
   methods: {
      merge,
      set,
      timestampToDate,
      async getNextPage() {
         this.currentPage++;
         this.getFirewallLogs(true);
      },
      async getFirewallLogs(isLazyLoading = false) {
         try {
            this.isLoading = true;
            if (!isLazyLoading) this.dataLogs.merge({ data: [] });

            const params = this.$route.query;
            params.skip = 0;
            params.limit = this.perPage * this.currentPage;
            params.domain = this.domain_filter;
            if (this.filtered_ip_address) params.member_ip_address = this.filtered_ip_address;
            if (this.filtered_mac_address) params.member_mac_address = this.filtered_mac_address;

            this.dataLogs.merge(await FirewallLogApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async getIpAddressList() {
         try {
            const { data } = await FirewallProfileApi.ipAddressList();
            this.ipAddressList = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async getMacAddressList() {
         try {
            const { data } = await FirewallProfileApi.macAddressList();
            this.macAddressList = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async filterIpAddress(ipAddress) {
         this.filtered_ip_address = ipAddress;
         this.getFirewallLogs();
      },
      async filterMacAddress(macAddress) {
         this.filtered_mac_address = macAddress;
         this.getFirewallLogs();
      },
      async clearFilters() {
         this.filtered_ip_address = null;
         this.filtered_mac_address = null;
         this.getFirewallLogs();
      },
   },
};
</script>

<style lang="scss">
.filter-dropdown {
   max-height: 400px;
   width: 100% !important;
   overflow: scroll;
}

.log-item-status-allowed {
   border-left: 5px solid #198754 !important;
}

.log-item-status-blocked {
   border-left: 5px solid #dc3545 !important;
}
</style>
