import { map } from 'lodash';
import Collection from '@/libs/collection';

class BaseModel extends Collection {
   static collection(data) {
      return map(data, (item) => this.create(item));
   }

   getCreatedAt() {
      return this.get('created_at');
   }

   getUpdatedAt() {
      return this.get('updated_at');
   }

   getDeletedAt() {
      return this.get('deleted_at');
   }
}

export default BaseModel;
