<template>
   <ObiText class="page-complete">
      <vue-loading
         v-if="isLoading"
         type="bubbles"
         color="#2f71f2"
         :size="{ width: '50px', height: '50px' }"
      ></vue-loading>

      <ObiText v-else class="page-complete-content">
         <IconWelcome />
         <ObiText as="h1" class="page-title" v-html="$t('complete.page_title')" />
         <ObiText class="page-description my-5">
            Aboneliğinizi başlatmak için lütfen parolanızı tanımlayın. Daha sonra Obifi ayrıcalıklarından
            faydalanabilmek için giriş yaparak devam edebilirsiniz
         </ObiText>

         <ObiForm @submit.prevent="updatePassword">
            <ObiText class="my-4">
               <ObiFloatingInput
                  type="password"
                  name="password"
                  :disabled="isLoading"
                  v-model="formDataRegister.password"
                  :label="$t('forgot_password.fields.password')"
                  :placeholder="$t('forgot_password.fields.password')"
                  :errors="formDataRegister.errors.get('password')"
               />
            </ObiText>

            <ObiButton
               block
               icon-right
               type="submit"
               :loading="isLoading"
               icon="mdi mdi-arrow-right"
               :text="$t('forgot_password.form.reset_password')"
            />
         </ObiForm>
      </ObiText>
   </ObiText>
</template>

<script>
import IconWelcome from '@/components/Icons/form/IconWelcome';
import { VueLoading } from 'vue-loading-template';
import ErrorHandler from '@/libs/error-handler';
import AuthApi from '@/api/AuthApi';
import Form from '@/libs/form';

export default {
   name: 'CheckoutComplete',
   components: {
      IconWelcome,
      VueLoading,
   },
   data() {
      return {
         isLoading: true,
         formDataRegister: Form.create(['password']).validate({
            password: 'required|min:6',
         }),
      };
   },
   mounted() {
      const { session_id } = this.$route.query;
      if (session_id) {
         this.getSessionModel(session_id);
      }
   },
   methods: {
      async getSessionModel(session_id) {
         this.isLoading = true;

         try {
            await AuthApi.stripeSessionCheck(session_id);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async updatePassword() {
         if (!this.formDataRegister.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            const { session_id } = this.$route.query;
            await AuthApi.stripeSessionCheck(session_id, this.formDataRegister.toObject());
            await this.$router.push({ name: 'auth-login' });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.page-complete {
   text-align: center;

   .page-complete-content {
      margin: 0 auto;
      max-width: 400px;
   }

   .page-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .page-description {
      color: #777d96;
      font-size: 13px;
   }
}
</style>
