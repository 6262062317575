import LayoutDashboard from '@/views/layouts/Dashboard';
import LayoutChildPage from '@/views/layouts/ChildPage';
import DashboardIndex from '@/views/pages/Dashboard/Index';

import AccountIndex from '@/views/pages/Account/Index';
import AccountSettings from '@/views/pages/Account/Edit/Settings';
import AccountSecurity from '@/views/pages/Account/Edit/Security';

import SettingsIndex from '@/views/pages/Settings/index';

import FirewallIndex from '@/views/pages/Firewall/Index';
import MemberIndex from '@/views/pages/Member/Index';
import LogIndex from '@/views/pages/Log/Index';
import CampaignIndex from '@/views/pages/Campaign/Index';

const routesDashboard = {
   path: '/',
   name: 'dashboard-index',
   component: DashboardIndex,
};

const routesAccount = {
   path: 'account',
   component: LayoutChildPage,
   children: [
      {
         path: '/',
         name: 'account-index',
         component: AccountIndex,
         children: [
            {
               path: 'settings',
               name: 'account-settings',
               component: AccountSettings,
            },
            {
               path: 'security',
               name: 'account-security',
               component: AccountSecurity,
            },
         ],
      },
   ],
};

const routesSettings = {
   path: 'settings',
   component: LayoutChildPage,
   children: [
      {
         path: '/',
         name: 'settings-index',
         component: SettingsIndex,
      },
   ],
};

const routesFirewall = {
   path: 'firewall',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'firewall-index',
         component: FirewallIndex,
      },
   ],
};

const routesMember = {
   path: 'members',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'members-index',
         component: MemberIndex,
      },
   ],
};

const logsMember = {
   path: 'logs',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'logs-index',
         component: LogIndex,
      },
   ],
};

const routesCampaign = {
   path: 'campaigns',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'campaigns-index',
         component: CampaignIndex,
      },
   ],
};

export default {
   path: '/',
   component: LayoutDashboard,
   children: [routesDashboard, routesAccount, routesSettings, routesFirewall, routesMember, logsMember, routesCampaign],
};
