<template>
   <ObiCard class="h-100">
      <template #header>
         <ObiText>
            <ObiText
               class="d-flex pr-2"
               style="flex-direction: row; align-items: baseline; justify-content: space-between;"
            >
               <ObiText class="text-primary">{{ title }}</ObiText>
               <ObiSwitch v-model="active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ content }}</ObiText>
            </ObiText>
         </ObiText>
      </template>
      <div class="d-flex justify-content-between align-items-center p-2">
         <div class="d-flex">
            <ObiInput clean compact type="time" v-model="start_value" :disabled="!active" />
            <ObiInput class="ms-2" clean compact type="time" v-model="end_value" :disabled="!active" />
         </div>
      </div>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
   name: 'ObiTimeRange',
   components: {},
   props: {
      value: {},
      title: {
         type: String,
         required: true,
      },
      content: {
         type: String,
         default: '',
      },
   },
   i18n: {
      messages: {
         tr: {
            unlimited: 'Sınırsız',
            PLEASE_SELECT: 'Lütfen Seçin',
         },
         en: {
            unlimited: 'Unlimited',
            PLEASE_SELECT: 'Please Select',
         },
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      optionTitle() {
         return this.time_option;
      },
      inputValue: {
         get() {
            return this.active ? [this.start_value, this.end_value] : null;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
      getFormattedValue() {
         return `${this.start_value}-${this.end_value}`;
      },
   },
   data() {
      return {
         start_value: '00:00',
         end_value: '23:59',
         active: true,
         selected: {
            option: {},
         },
      };
   },
   methods: {},
};
</script>

<style lang="scss" scoped>
.obi-slider {
   width: 100%;
}
.obi-option-value {
   padding: 10px;
   background: #ffffff;
   border: 1px solid #2f71f2;
   box-sizing: border-box;
   border-radius: 3px;
   font-style: normal;
   font-weight: bold;
   font-size: 15px;
   line-height: 18px;
   color: #2f71f2;
}
</style>
