<template>
   <ObiCard class="h-100">
      <template #header>
         <ObiTitle :title="title"> </ObiTitle>
         <ObiText> {{ content }} </ObiText>
      </template>
      <RangeSlider
         v-model="inputValue.value"
         class="obi-slider"
         :min="getSelectedOptionVal('min')"
         :max="getSelectedOptionVal('max')"
         :step="getSelectedOptionVal('step')"
      />

      <div class="d-flex justify-content-between align-items-center p-2">
         <ObiText class="obi-option-value">{{ getFormattedValue }}</ObiText>
         <ObiButtonSwitch :items="options" v-model="inputValue.unit" />
      </div>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
   name: 'ObiDailyTimeRange',
   components: { RangeSlider },
   props: {
      value: {},
      title: {
         type: String,
         required: true,
      },
      content: {
         type: String,
         default: '',
      },
      options: {
         type: Array,
         required: true,
      },
   },
   i18n: {
      messages: {
         tr: {
            unlimited: 'Sınırsız',
            PLEASE_SELECT: 'Lütfen Seçin',
         },
         en: {
            unlimited: 'Unlimited',
            PLEASE_SELECT: 'Please Select',
         },
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      optionTitle() {
         return this.time_option;
      },
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
      getFormattedValue() {
         if (this.inputValue && this.inputValue.value) {
            return `${this.inputValue.value} ${this.getSelectedOptionLabel()}`;
         }

         return this.$t('PLEASE_SELECT');
      },
   },
   data() {
      return {
         selected: {
            value: 0,
            option: {},
         },
      };
   },
   methods: {
      getSelectedOptionVal(key) {
         return this.$props.options.find((option) => option.id === this.inputValue.unit)[key];
      },
      getSelectedOptionLabel() {
         return this.$props.options.find((option) => option.id === this.inputValue.unit).label;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-slider {
   width: 100%;
}
.obi-option-value {
   padding: 10px;
   background: #ffffff;
   border: 1px solid #2f71f2;
   box-sizing: border-box;
   border-radius: 3px;
   font-style: normal;
   font-weight: bold;
   font-size: 15px;
   line-height: 18px;
   color: #2f71f2;
}
</style>
