<template>
   <ObiText
      class="obi-cm card"
      v-bind="attrs"
      v-on="$listeners"
      @mouseenter="iconColor = 'white'"
      @mouseleave="iconColor = 'secondary'"
   >
      <ObiIcon :icon="icon" :color="iconColor" width="30" height="30" />
      <vue-loading
         v-if="loading"
         type="bubbles"
         color="#2f71f2"
         :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
      <ObiText v-else class="obi-cm-metric" v-html="metric" />
      <ObiText class="obi-cm-title" v-html="title" />
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';
import { VueLoading } from 'vue-loading-template';

export default {
   name: 'ObiCardMetric',
   components: {
      VueLoading,
   },
   props: {
      icon: {
         type: [String, Object],
         default: null,
      },
      metric: {
         type: [String, Number],
         default: null,
      },
      title: {
         type: [String, Object],
         default: null,
      },
      loading: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   data() {
      return {
         iconColor: 'secondary',
      };
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-cm {
   gap: 0.75rem;
   align-items: center;
   padding: 2rem 1.5rem;
   border-color: transparent;
   justify-content: space-between;
   cursor: pointer;
   box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.03);
   border: 1px solid rgba(0, 0, 0, 0.125);

   &:hover {
      color: #fff !important;
      background-color: $primary;
   }

   .obi-cm-metric {
      line-height: 1;
      font-size: 2rem;
      font-weight: 300;
   }

   .obi-cm-title {
      line-height: 1;
   }
}
</style>
