import Http from '@/libs/http';
import PanelDeviceNetworkApi from '@/api/PanelDeviceNetworkApi';
import PanelDeviceLogApi from '@/api/PanelDeviceLogApi';
import PanelDeviceMacApi from '@/api/PanelDeviceMacApi';

class PanelDeviceApi extends Http {
   index(params) {
      return super.get('panel/device', { params });
   }

   store(payload, params) {
      return super.post('panel/device', payload, { params });
   }

   brands(params) {
      return super.get('panel/device/brands', params);
   }

   show(id, params) {
      return super.get(`panel/device/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`panel/device/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`panel/device/${id}`, { params });
   }

   mikrotikScriptGenerate(deviceId, payload, params) {
      return super.post(`panel/device/mikrotik/script/${deviceId}`, payload, params);
   }

   downloadLoginHtml(deviceId, params) {
      return super.post(
         `panel/device/mikrotik/login/${deviceId}`,
         {
            responseType: 'blob',
         },
         { params }
      );
   }

   sophosDownloadLoginHtml(deviceId, params) {
      return super.post(
         `panel/device/sophos/template/${deviceId}`,
         {
            responseType: 'blob',
         },
         { params }
      );
   }

   applyLicense(deviceId, params) {
      return super.post(`panel/device/${deviceId}/license`, params);
   }

   get networks() {
      return PanelDeviceNetworkApi;
   }

   get logs() {
      return PanelDeviceLogApi;
   }
   get macs() {
      return PanelDeviceMacApi;
   }
}

export default new PanelDeviceApi();
