<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle icon-stacked :title="$t('title')" />
      </template>
      <ObiRow>
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('Name_Title')"
               :disabled="isLoading"
               :placeholder="$t('Name_Title')"
               v-model="formData.name"
               :errors="formData.errors.get('name')"
            />
         </ObiCol>
         <ObiCol sm="12">
            <ObiFloatingInput
               class="mt-2"
               compact
               :label="$t('StartingIp_Title')"
               :disabled="isLoading"
               :placeholder="$t('StartingIp_Title')"
               v-model="formData.starting_ip"
               :errors="formData.errors.get('starting_ip')"
            />
         </ObiCol>
         <ObiCol sm="12">
            <ObiFloatingInput
               class="mt-2"
               compact
               :label="$t('EndIp_Title')"
               :disabled="isLoading"
               :placeholder="$t('EndIp_Title')"
               v-model="formData.end_ip"
               :errors="formData.errors.get('end_ip')"
            />
         </ObiCol>
      </ObiRow>
      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('ACTION_SAVE')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import { trFormatDateLong } from '@/libs/date-utils';
import formData from '@/views/pages/Firewall/FormData/form-data-network';

export default {
   name: 'AddNetworkModal',
   i18n: {
      messages: {
         tr: {
            title: 'Yeni bir network tanımlayın',
            Name_Title: 'Bir isim verin',
            StartingIp_Title: 'Başlangıç IP Adresi',
            EndIp_Title: 'Bitiş IP Adresi',
            ACTION_SAVE: 'Ekle',
         },
         en: {
            title: 'Create a new network',
            Name_Title: 'Bir isim verin',
            StartingIp_Title: 'Başlangıç IP Adresi',
            EndIp_Title: 'Bitiş IP Adresi',
            ACTION_SAVE: 'Save',
         },
      },
   },
   props: {},

   components: {},

   data() {
      return {
         isLoading: false,
         formData,
      };
   },
   computed: {
      currentLanguage() {
         return this.$i18n.locale;
      },
   },

   methods: {
      trFormatDateLong,
      show(data) {
         this.formData.reset();
         if (data) this.formData.merge(data);
         this.$refs.modal.show();
      },
      hide() {
         this.formData.reset();
         this.$refs.modal.hide();
      },
      submitForm() {
         if (!this.formData.isValid({ disableDirtyCheck: true })) return;

         this.$emit('saveNetwork', this.formData.toObject());
         this.$refs.modal.hide();
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';

.obi-online-user {
   padding: 10px;
   border-radius: 8px;
   background-color: #f3f8fe;

   .bottom-white {
      border-bottom: 1px solid #fff;
   }

   .obi-online-user-content-wrapper {
      display: flex;
      gap: 20px;
   }

   .obi-online-user-content {
      display: grid;
   }

   .obi-online-user-value {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-online-user-field {
      font-size: 0.8rem;
   }
}
</style>
