<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle
            :icon="IconVisitor"
            icon-stacked
            :title="$t('MEMBER_CREATE_MODAL_TITLE')"
            :subtitle="$t('MEMBER_CREATE_MODAL_SUBTITLE')"
         />
      </template>

      <div class="container">
         <ObiText class="mb-2">
            <ObiText class="text-primary">{{ $t('FORM_MEMBER_NAME_TITLE') }}</ObiText>
            <ObiFloatingInput
               :label="$t('FORM_MEMBER_NAME_TITLE')"
               :disabled="isLoading"
               :placeholder="$t('FORM_MEMBER_NAME_TITLE')"
               v-model="formDataMember.name"
               :errors="formDataMember.errors.get('name')"
            />
         </ObiText>
         <ObiText class="mb-2">
            <ObiRow>
               <ObiCol col="4">
                  <ObiText class="text-primary">{{ $t('FORM_MEMBER_PRIVATE_KEY_OPTION_TITLE') }}</ObiText>
                  <ObiButtonSwitch
                     :items="privateKeyOptions"
                     v-model="formDataMember.private_key_option"
                     :errors="formDataMember.errors.get('private_key_option')"
                  />
               </ObiCol>
               <ObiCol v-if="formDataMember.private_key_option === 'custom'" col="4">
                  <ObiText>
                     <ObiText class="text-primary">{{ $t('FORM_MEMBER_PRIVATE_KEY_TITLE') }}</ObiText>
                     <ObiFloatingInput
                        :maxLength="7"
                        :inputStyle="'height: 38px !important;min-height: 38px !important;padding:0px 0px 0px 15px;'"
                        :disabled="isLoading"
                        @input="formDataMember.private_key = formatPrivateKey(formDataMember.private_key)"
                        v-model="formDataMember.private_key"
                        :errors="formDataMember.errors.get('private_key')"
                     />
                  </ObiText>
               </ObiCol>
               <ObiCol col="6" v-if="formDataMember.private_key_option === 'auto'">
                  <ObiText class="text-primary">{{ $t('FORM_MEMBER_PRIVATE_KEY_RENEW_OPTION_TITLE') }}</ObiText>
                  <ObiButtonSwitch
                     :items="privateKeyRenewOptions"
                     v-model="formDataMember.private_key_renew_period"
                     :errors="formDataMember.errors.get('private_key_renew_period')"
                     @input="
                        formDataMember.private_key_renew_value =
                           privateKeyOptionMinMaxMap[formDataMember.private_key_renew_period].min
                     "
                  />
               </ObiCol>
               <ObiCol col="2" v-if="formDataMember.private_key_option === 'auto'">
                  <ObiText class="text-primary">{{ $t('FORM_MEMBER_PRIVATE_KEY_RENEW_VALUE_TITLE') }}</ObiText>
                  <ObiFloatingInput
                     :inputStyle="'height: 38px !important;min-height: 38px !important;padding:0px 0px 0px 15px;'"
                     compact
                     type="number"
                     :disabled="isLoading"
                     :minVal="privateKeyOptionMinMaxMap[formDataMember.private_key_renew_period].min"
                     :maxVal="privateKeyOptionMinMaxMap[formDataMember.private_key_renew_period].max"
                     v-model="formDataMember.private_key_renew_value"
                     :errors="formDataMember.errors.get('private_key_renew_value')"
                  />
               </ObiCol>
            </ObiRow>
         </ObiText>
         <ObiText>
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_FIREWALL_RULE_ACTIVE_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_firewall_rules_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_FIREWALL_RULE_ACTIVE_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="formDataMember.is_firewall_rules_active">
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_TRACKERS_RULE_ACTIVE_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_trackers_rule_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_TRACKERS_RULE_ACTIVE_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="formDataMember.is_firewall_rules_active">
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_ADS_RULE_ACTIVE_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_ads_rule_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_ADS_RULE_ACTIVE_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="formDataMember.is_firewall_rules_active">
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_SERVICE_RULE_ACTIVE_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_service_rule_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_SERVICE_RULE_ACTIVE_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="formDataMember.is_firewall_rules_active">
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_BLOCKED_DOMAINS_ACTIVE_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_blocked_domains_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_BLOCKED_DOMAINS_ACTIVE_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="formDataMember.is_firewall_rules_active">
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_IS_LOGGING_ACTIVE_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_logging_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_IS_LOGGING_ACTIVE_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText>
            <ObiText class="d-flex" style="flex-direction: row; align-items: baseline; justify-content: space-between;">
               <ObiText class="text-primary mt-4">{{ $t('FORM_MEMBER_WEEKLY_CALENDARS_TITLE') }}</ObiText>
               <ObiSwitch v-model="formDataMember.is_limitation_active" />
            </ObiText>
            <ObiText>
               <ObiText class="text-secondary">{{ $t('FORM_MEMBER_WEEKLY_CALENDARS_SUBTITLE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="formDataMember.is_limitation_active" class="p-4">
            <ObiText class="d-flex" style="flex-direction: row; align-items: center; justify-content: space-between;">
               <ObiSwitch
                  v-model="formDataMember.limitations.mon.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_MON_LABEL')"
               />
               <ObiSwitch
                  v-model="formDataMember.limitations.tue.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_TUE_LABEL')"
               />
               <ObiSwitch
                  v-model="formDataMember.limitations.wed.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_WED_LABEL')"
               />
               <ObiSwitch
                  v-model="formDataMember.limitations.thu.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_THU_LABEL')"
               />
               <ObiSwitch
                  v-model="formDataMember.limitations.fri.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_FRI_LABEL')"
               />
               <ObiSwitch
                  v-model="formDataMember.limitations.sat.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_SAT_LABEL')"
               />
               <ObiSwitch
                  v-model="formDataMember.limitations.sun.is_active"
                  :label="$t('FORM_MEMBER_WEEKLY_CALENDARS_SUN_LABEL')"
               />
            </ObiText>
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.mon.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.mon"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_MONDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_MONDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.tue.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.tue"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_TUESDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_TUESDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.wed.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.wed"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_WEDNESDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_WEDNESDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.thu.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.thu"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_THURSDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_THURSDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.fri.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.fri"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_FRIDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_FRIDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.sat.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.sat"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_SATURDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_SATURDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
         <ObiText class="mb-2" v-if="formDataMember.is_limitation_active && formDataMember.limitations.sun.is_active">
            <ObiDailyTimeRange
               v-model="formDataMember.limitations.sun"
               :title="$t('FORM_MEMBER_WEEKLY_CALENDARS_SUNDAY_LABEL')"
               :content="$t('FORM_MEMBER_WEEKLY_CALENDARS_SUNDAY_SUBTITLE')"
               :options="timeOptions"
            />
         </ObiText>
      </div>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('MEMBER_CREATE_BUTTON_TEXT')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import IconVisitor from '@/components/Icons/general/IconVisitor';
import formDataMember from '@/views/pages/Member/FormData/form-data-member';
import ObiDailyTimeRange from '@/components/UI/ObiDailyTimeRange';
import { formatPrivateKey } from '@/libs/input-utils';
import ErrorHandler from '@/libs/error-handler';
import FirewallMemberApi from '@/api/FirewallMemberApi';

export default {
   name: 'MemberCreateModal',
   components: { ObiDailyTimeRange },
   i18n: {
      messages: {
         tr: {
            FORM_DATA_MEMBER_NAME_ATTRIBUTE_NAME: 'Üye Adı',
            MEMBER_CREATE_MODAL_TITLE: 'Yeni Üye Oluştur',
            MEMBER_CREATE_MODAL_SUBTITLE: 'Yeni bir üye oluşturmak için ayrıntıları sağlayın',
            MEMBER_CREATE_BUTTON_TEXT: 'Kaydet',
            FORM_MEMBER_PRIVATE_KEY_OPTION_TITLE: 'Özel Anahtar Seçeneği',
            PRIVATE_KEY_AUTO_OPTION_TITLE: 'Otomatik',
            PRIVATE_KEY_CUSTOM_OPTION_TITLE: 'Özel',
            FORM_MEMBER_PRIVATE_KEY_RENEW_OPTION_TITLE: 'Yenileme Süresi',
            PRIVATE_KEY_RENEW_OPTION_MINUTE_TITLE: 'Dakika',
            PRIVATE_KEY_RENEW_OPTION_HOUR_TITLE: 'Saat',
            PRIVATE_KEY_RENEW_OPTION_DAY_TITLE: 'Gün',
            PRIVATE_KEY_RENEW_OPTION_MONTH_TITLE: 'Ay',
            FORM_MEMBER_PRIVATE_KEY_TITLE: 'Özel Anahtar',
            FORM_MEMBER_NAME_TITLE: 'Üye Adı',
            FORM_MEMBER_PRIVATE_KEY_RENEW_VALUE_TITLE: 'Değer',
            FORM_MEMBER_FIREWALL_RULE_ACTIVE_TITLE: 'Güvenlik Duvarı Kuralını Etkinleştir',
            FORM_MEMBER_FIREWALL_RULE_ACTIVE_SUBTITLE:
               'Bu seçeneği etkinleştirerek bu üye üzerinde güvenlik duvarı ayarlarını zorlayın',
            FORM_MEMBER_WEEKLY_CALENDARS_TITLE: 'Haftalık Takvim',
            FORM_MEMBER_WEEKLY_CALENDARS_SUBTITLE:
               'Bu seçeneği etkinleştirerek üyenin haftanın günlerine göre kısıtlamalarını belirleyin.',
            FORM_MEMBER_TRACKERS_RULE_ACTIVE_TITLE: 'İzleyiciler Kuralı',
            FORM_MEMBER_TRACKERS_RULE_ACTIVE_SUBTITLE: 'Bu üye için izleyiciler kuralını uygulamak için açın',
            FORM_MEMBER_ADS_RULE_ACTIVE_TITLE: 'Reklamlar Kuralı',
            FORM_MEMBER_ADS_RULE_ACTIVE_SUBTITLE: 'Bu üye için reklamlar kuralını uygulamak için açın',
            FORM_MEMBER_SERVICE_RULE_ACTIVE_TITLE: 'Hizmetler Kuralı',
            FORM_MEMBER_SERVICE_RULE_ACTIVE_SUBTITLE: 'Bu üye için engellenen hizmetler kuralını uygulamak için açın',
            FORM_MEMBER_BLOCKED_DOMAINS_ACTIVE_TITLE: 'Alan Adı Engelleme Kuralları',
            FORM_MEMBER_BLOCKED_DOMAINS_ACTIVE_SUBTITLE:
               'Bu üye için engellenen alan adları kuralını uygulamak için açın',
            FORM_MEMBER_IS_LOGGING_ACTIVE_TITLE: 'Günlüğe Kaydetmeyi Etkinleştir',
            FORM_MEMBER_IS_LOGGING_ACTIVE_SUBTITLE: 'Bu üye için sorguları günlüğe kaydetmek için açın',
            FORM_MEMBER_WEEKLY_CALENDARS_MON_LABEL: 'Pzt',
            FORM_MEMBER_WEEKLY_CALENDARS_TUE_LABEL: 'Sal',
            FORM_MEMBER_WEEKLY_CALENDARS_WED_LABEL: 'Çar',
            FORM_MEMBER_WEEKLY_CALENDARS_THU_LABEL: 'Per',
            FORM_MEMBER_WEEKLY_CALENDARS_FRI_LABEL: 'Cum',
            FORM_MEMBER_WEEKLY_CALENDARS_SAT_LABEL: 'Cmt',
            FORM_MEMBER_WEEKLY_CALENDARS_SUN_LABEL: 'Paz',
            FORM_MEMBER_WEEKLY_CALENDARS_MONDAY_LABEL: 'Pazartesi',
            FORM_MEMBER_WEEKLY_CALENDARS_MONDAY_SUBTITLE: 'Pazartesi günü uygulanacak kısıtlamalar',
            FORM_MEMBER_WEEKLY_CALENDARS_TUESDAY_LABEL: 'Salı',
            FORM_MEMBER_WEEKLY_CALENDARS_TUESDAY_SUBTITLE: 'Salı günü uygulanacak kısıtlamalar',
            FORM_MEMBER_WEEKLY_CALENDARS_WEDNESDAY_LABEL: 'Çarşamba',
            FORM_MEMBER_WEEKLY_CALENDARS_WEDNESDAY_SUBTITLE: 'Çarşamba günü uygulanacak kısıtlamalar',
            FORM_MEMBER_WEEKLY_CALENDARS_THURSDAY_LABEL: 'Perşembe',
            FORM_MEMBER_WEEKLY_CALENDARS_THURSDAY_SUBTITLE: 'Perşembe günü uygulanacak kısıtlamalar',
            FORM_MEMBER_WEEKLY_CALENDARS_FRIDAY_LABEL: 'Cuma',
            FORM_MEMBER_WEEKLY_CALENDARS_FRIDAY_SUBTITLE: 'Cuma günü uygulanacak kısıtlamalar',
            FORM_MEMBER_WEEKLY_CALENDARS_SATURDAY_LABEL: 'Cumartesi',
            FORM_MEMBER_WEEKLY_CALENDARS_SATURDAY_SUBTITLE: 'Cumartesi günü uygulanacak kısıtlamalar',
            FORM_MEMBER_WEEKLY_CALENDARS_SUNDAY_LABEL: 'Pazar',
            FORM_MEMBER_WEEKLY_CALENDARS_SUNDAY_SUBTITLE: 'Pazar günü uygulanacak kısıtlamalar',
            TIME_OPTION_MINUTE_LABEL: 'Dakika',
            TIME_OPTION_HOUR_LABEL: 'Saat',
         },
         en: {
            FORM_DATA_MEMBER_NAME_ATTRIBUTE_NAME: 'Member Name',
            MEMBER_CREATE_MODAL_TITLE: 'Create New Member',
            MEMBER_CREATE_MODAL_SUBTITLE: 'Provide details to create a new member',
            MEMBER_CREATE_BUTTON_TEXT: 'Save',
            FORM_MEMBER_PRIVATE_KEY_OPTION_TITLE: 'Private Key Option',
            PRIVATE_KEY_AUTO_OPTION_TITLE: 'Auto',
            PRIVATE_KEY_CUSTOM_OPTION_TITLE: 'Custom',
            FORM_MEMBER_PRIVATE_KEY_RENEW_OPTION_TITLE: 'Renew Period',
            PRIVATE_KEY_RENEW_OPTION_MINUTE_TITLE: 'Minute',
            PRIVATE_KEY_RENEW_OPTION_HOUR_TITLE: 'Hour',
            PRIVATE_KEY_RENEW_OPTION_DAY_TITLE: 'Day',
            PRIVATE_KEY_RENEW_OPTION_MONTH_TITLE: 'Month',
            FORM_MEMBER_PRIVATE_KEY_TITLE: 'Private Key',
            FORM_MEMBER_NAME_TITLE: 'Member Name',
            FORM_MEMBER_PRIVATE_KEY_RENEW_VALUE_TITLE: 'Value',
            FORM_MEMBER_FIREWALL_RULE_ACTIVE_TITLE: 'Activate Firewall Rule',
            FORM_MEMBER_FIREWALL_RULE_ACTIVE_SUBTITLE:
               'Activate this option to enforce the firewall settings on this member',
            FORM_MEMBER_WEEKLY_CALENDARS_TITLE: 'Weekly Calendar',
            FORM_MEMBER_WEEKLY_CALENDARS_SUBTITLE:
               "Enable this option to restrict according to the member's days of the week.",
            FORM_MEMBER_TRACKERS_RULE_ACTIVE_TITLE: 'Trackers Rule',
            FORM_MEMBER_TRACKERS_RULE_ACTIVE_SUBTITLE: 'Turn on to apply trackers rule for this member',
            FORM_MEMBER_ADS_RULE_ACTIVE_TITLE: 'Ads Rules',
            FORM_MEMBER_ADS_RULE_ACTIVE_SUBTITLE: 'Turn on to apply ads rule for this member',
            FORM_MEMBER_SERVICE_RULE_ACTIVE_TITLE: 'Services Rule',
            FORM_MEMBER_SERVICE_RULE_ACTIVE_SUBTITLE: 'Turn on to apply blocked services rule for this member',
            FORM_MEMBER_BLOCKED_DOMAINS_ACTIVE_TITLE: 'Domain Blocking Rules',
            FORM_MEMBER_BLOCKED_DOMAINS_ACTIVE_SUBTITLE: 'Turn on to apply blocked domains rule for this member',
            FORM_MEMBER_IS_LOGGING_ACTIVE_TITLE: 'Enable Logging',
            FORM_MEMBER_IS_LOGGING_ACTIVE_SUBTITLE: 'Turn on to logging queries for this member',
            FORM_MEMBER_WEEKLY_CALENDARS_MON_LABEL: 'Mon',
            FORM_MEMBER_WEEKLY_CALENDARS_TUE_LABEL: 'Tue',
            FORM_MEMBER_WEEKLY_CALENDARS_WED_LABEL: 'Wed',
            FORM_MEMBER_WEEKLY_CALENDARS_THU_LABEL: 'Thu',
            FORM_MEMBER_WEEKLY_CALENDARS_FRI_LABEL: 'Fri',
            FORM_MEMBER_WEEKLY_CALENDARS_SAT_LABEL: 'Sat',
            FORM_MEMBER_WEEKLY_CALENDARS_SUN_LABEL: 'Sun',
            FORM_MEMBER_WEEKLY_CALENDARS_MONDAY_LABEL: 'Monday',
            FORM_MEMBER_WEEKLY_CALENDARS_MONDAY_SUBTITLE: 'Restrictions to be applied on Monday',
            FORM_MEMBER_WEEKLY_CALENDARS_TUESDAY_LABEL: 'Tuesday',
            FORM_MEMBER_WEEKLY_CALENDARS_TUESDAY_SUBTITLE: 'Restrictions to be applied on Tuesday',
            FORM_MEMBER_WEEKLY_CALENDARS_WEDNESDAY_LABEL: 'Wednesday',
            FORM_MEMBER_WEEKLY_CALENDARS_WEDNESDAY_SUBTITLE: 'Restrictions to be applied on Wednesday',
            FORM_MEMBER_WEEKLY_CALENDARS_THURSDAY_LABEL: 'Thursday',
            FORM_MEMBER_WEEKLY_CALENDARS_THURSDAY_SUBTITLE: 'Restrictions to be applied on Thursday',
            FORM_MEMBER_WEEKLY_CALENDARS_FRIDAY_LABEL: 'Friday',
            FORM_MEMBER_WEEKLY_CALENDARS_FRIDAY_SUBTITLE: 'Restrictions to be applied on Friday',
            FORM_MEMBER_WEEKLY_CALENDARS_SATURDAY_LABEL: 'Saturday',
            FORM_MEMBER_WEEKLY_CALENDARS_SATURDAY_SUBTITLE: 'Restrictions to be applied on Saturday',
            FORM_MEMBER_WEEKLY_CALENDARS_SUNDAY_LABEL: 'Sunday',
            FORM_MEMBER_WEEKLY_CALENDARS_SUNDAY_SUBTITLE: 'Restrictions to be applied on Sunday',
            TIME_OPTION_MINUTE_LABEL: 'Minute',
            TIME_OPTION_HOUR_LABEL: 'Hour',
         },
      },
   },

   data() {
      return {
         IconVisitor,
         isLoading: false,
         formDataMember,
         dataAuthTypes: [
            { id: 'citizen-id', label: 'TC Kimlik Numarası' },
            { id: 'username', label: 'Kullanıcı Adı & Parola' },
            { id: 'room-number', label: 'Oda Numarası' },
         ],
         privateKeyOptions: [
            {
               label: this.$t('PRIVATE_KEY_AUTO_OPTION_TITLE'),
               id: 'auto',
            },
            {
               label: this.$t('PRIVATE_KEY_CUSTOM_OPTION_TITLE'),
               id: 'custom',
            },
         ],
         privateKeyOptionMinMaxMap: {
            minute: {
               min: 1,
               max: 60,
            },
            hour: {
               min: 1,
               max: 24,
            },
            day: {
               min: 1,
               max: 30,
            },
            month: {
               min: 1,
               max: 12,
            },
         },
         privateKeyRenewOptions: [
            {
               label: this.$t('PRIVATE_KEY_RENEW_OPTION_MINUTE_TITLE'),
               id: 'minute',
            },
            {
               label: this.$t('PRIVATE_KEY_RENEW_OPTION_HOUR_TITLE'),
               id: 'hour',
            },
            {
               label: this.$t('PRIVATE_KEY_RENEW_OPTION_DAY_TITLE'),
               id: 'day',
            },
            {
               label: this.$t('PRIVATE_KEY_RENEW_OPTION_MONTH_TITLE'),
               id: 'month',
            },
         ],
         timeOptions: [
            {
               label: this.$t('TIME_OPTION_MINUTE_LABEL'),
               id: 'minute',
               min: 0,
               max: 60,
               step: 5,
            },
            {
               label: this.$t('TIME_OPTION_HOUR_LABEL'),
               id: 'hour',
               min: 0,
               max: 24,
               step: 1,
            },
         ],
      };
   },
   computed: {},
   async mounted() {
      formDataMember.attributeNames({
         name: this.$t('FORM_DATA_MEMBER_NAME_ATTRIBUTE_NAME'),
      });
   },
   methods: {
      show(member) {
         this.formDataMember.reset();
         if (member) this.formDataMember.merge(member);

         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      formatPrivateKey,
      async submitForm() {
         if (!this.formDataMember.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            if (this.formDataMember.hid == null) await FirewallMemberApi.store(this.formDataMember.toObject());
            if (this.formDataMember.hid != null)
               await FirewallMemberApi.update(this.formDataMember.hid, this.formDataMember.toObject());

            this.$emit('success');
            this.$refs.modal.hide();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>
