<template>
   <ObiText class="col-md-12">
      <ObiPage
         v-if="currentStep === 1"
         class="device-list border p-4 m-4 mx-auto"
         :title="$t('device.title')"
         :subtitle="$t('device.subtitle')"
      >
         <ObiRow v-if="!isLoadingBrands && dataDeviceBrands.get('meta.total')" class="device-items">
            <ObiCol sm="3" v-for="brand in dataDeviceBrands.get('data')" :key="brand.getId()">
               <ObiCard
                  class="border mb-4 brand-item"
                  @click="selectBrand(brand)"
                  :class="{ 'border-primary': isSelectedBrand(brand) }"
               >
                  <ObiImage
                     fit-contain
                     height="60"
                     width="100%"
                     :src="brand.getLogo()"
                     :grayscale="!isSelectedBrand(brand)"
                  />
               </ObiCard>
            </ObiCol>
         </ObiRow>

         <template #footer>
            <ObiText class="d-flex justify-content-center mt-4">
               <ObiButton
                  wider
                  :disabled="!selectedBrand"
                  :loading="isLoading"
                  @click="currentStep += 1"
                  color="primary"
                  :text="$t('actions.next')"
               />
            </ObiText>
         </template>
      </ObiPage>
      <ObiPage
         v-if="currentStep === 2"
         class="device-list border p-4 m-4 mx-auto"
         :title="$t('device_detail.title')"
         :subtitle="$t('device_detail.subtitle')"
      >
         <template #actions>
            <ObiImage fit-contain height="40" :src="selectedBrand.getLogo()" />
         </template>
         <ObiText class="mt-4">
            <ObiFloatingInput
               v-if="selectedBrand._data.slug !== 'watchguard'"
               :label="$t('fields.mac_address')"
               :disabled="isLoading"
               :placeholder="$t('fields.mac_address')"
               v-model="formDataDevice.mac_address"
               @input="formatMAC"
               :errors="formDataDevice.errors.get('mac_address')"
            />

            <ObiFloatingInput
               v-else
               :label="$t('fields.serial_number')"
               :disabled="isLoading"
               :placeholder="$t('fields.serial_number')"
               v-model="formDataDevice.mac_address"
               :errors="formDataDevice.errors.get('mac_address')"
            />

            <ObiFloatingInput
               v-if="selectedBrand._data.slug === 'watchguard'"
               :label="$t('fields.secret')"
               :disabled="true"
               :placeholder="$t('fields.secret')"
               v-model="formDataDevice.secret"
               :errors="formDataDevice.errors.get('secret')"
            />

            <ObiFloatingInput
               :label="$t('fields.name')"
               :disabled="isLoading"
               :placeholder="$t('fields.name')"
               v-model="formDataDevice.name"
               :errors="formDataDevice.errors.get('name')"
            />
         </ObiText>
         <ObiText>
            <ObiRow>
               <ObiCol sm="12">
                  <ObiSessionLimit
                     :title="$t('time_limit_title')"
                     :disabled="isLoading"
                     v-model="formDataProfile.session_duration"
                     :content="$t('time_limit_content')"
                     :options="timeOptions"
                  />
               </ObiCol>
            </ObiRow>

            <ObiRow class="mt-2">
               <ObiCol sm="12">
                  <ObiSessionLimit
                     :title="$t('upload_limit_title')"
                     :disabled="isLoading"
                     v-model="formDataProfile.max_upload"
                     :content="$t('upload_limit_content')"
                     :options="speedOptions"
                  />
               </ObiCol>
            </ObiRow>

            <ObiRow class="mt-2">
               <ObiCol sm="12">
                  <ObiSessionLimit
                     :title="$t('download_limit_title')"
                     :disabled="isLoading"
                     v-model="formDataProfile.max_download"
                     :content="$t('download_limit_content')"
                     :options="speedOptions"
                  />
               </ObiCol>
            </ObiRow>

            <template slot="actions">
               <ObiButton
                  wider
                  icon-right
                  type="submit"
                  :loading="isLoading"
                  :text="$t('actions.save')"
                  icon="mdi mdi-arrow-right"
               />
            </template>
         </ObiText>
         <template #footer>
            <ObiButton
               wider
               color="lighter"
               @click="prevStep"
               :loading="isLoading"
               :disabled="currentStep <= 1"
               icon="mdi mdi-arrow-left"
               :text="$t('actions.prev')"
            />

            <ObiButton
               wider
               icon-right
               @click="submitForm"
               :loading="isLoading"
               :disabled="!formDataDevice.mac_address || !formDataDevice.name"
               eklendi
               class="float-end"
               icon="mdi mdi-arrow-right"
               :text="$t('actions.save')"
            />
         </template>
      </ObiPage>
      <ObiPage
         v-if="currentStep === 3"
         class="col-md-8 border p-4 m-4 mx-auto"
         :title="$t('device_detail.title')"
         :subtitle="$t('device_detail.subtitle')"
      >
         <div class="col-md-12 d-flex">
            <ObiText class="col-md-8 mt-4">
               <ObiTabBar class="p-0">
                  <template #tabs>
                     <ObiTabs :items="tabItems" vertical v-model="selectedTab" />
                  </template>

                  <ObiText class="p-4" v-if="dataPortalDetail && dataPortalDetail.get('id') > 0">
                     <ObiPortalGeneralTab
                        v-if="selectedTab === 'portal-edit' && portalOptions"
                        :portal-id="dataPortalDetail.get('id')"
                        :options="portalOptions"
                        @contentChanged="onContentChanged"
                        @portalStyleChanged="onStyleChanged"
                     />
                     <ObiPortalProviderMethod
                        v-if="selectedTab === 'providers-edit' && portalProviderOptions"
                        :portal-id="dataPortalDetail.get('id')"
                        :options="portalProviderOptions"
                        @portalStyleChanged="onStyleChanged"
                        @contentChanged="onContentChanged"
                        @providerChanged="onProviderChanged"
                     />
                     <DeviceIndex v-if="!isLoading && selectedTab === 'settings-devices'" />
                  </ObiText>
               </ObiTabBar>
            </ObiText>
            <ObiText class="col-md-4">
               <iframe
                  v-if="currentStep === 3 && previewUrl"
                  style="width: 100%;"
                  id="previewFrame"
                  ref="previewFrame"
                  :src="previewUrl"
                  height="100%"
               />
            </ObiText>
         </div>
         <template #footer>
            <ObiButton
               wider
               color="lighter"
               @click="prevStep"
               :loading="isLoading"
               :disabled="currentStep <= 1"
               icon="mdi mdi-arrow-left"
               :text="$t('actions.prev')"
            />

            <ObiButton
               wider
               icon-right
               @click="submitPortalPut"
               :loading="isLoading"
               eklendi
               class="float-end"
               icon="mdi mdi-arrow-right"
               :text="$t('actions.save')"
            />
         </template>
      </ObiPage>
      <ObiPage v-if="currentStep === 4" class="col-md-8 border p-4 m-4 mx-auto">
         <div class="col-md-12 d-flex">
            <ObiCol sm="12" class="text-center p-5 mb-5 mt-5">
               <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="47" cy="47" r="45.5" stroke="#2F71F2" stroke-width="3" />
                  <path
                     d="M68.2352 29L41.7646 59"
                     stroke="#2F71F2"
                     stroke-width="3"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M25 45.7647L41.7647 59"
                     stroke="#2F71F2"
                     stroke-width="3"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <h2 style="font-weight: 500; color: #2f71f2; text-align: center;" class="mt-1">
                  {{ $t('final.completed_title') }}
               </h2>
               <p
                  class="mt-2"
                  style="width: 328px; font-weight: 300; color: #777d96; text-align: center; display: inline-block;"
               >
                  {{ $t('final.completed_desc') }}
               </p>
               <p>
                  <ObiButton
                     wider
                     @click="$router.push({ name: 'dashboard-index' })"
                     :loading="isLoading"
                     color="primary"
                     :text="$t('Kontrol Paneline Git')"
                  />
               </p>
            </ObiCol>
         </div>
      </ObiPage>
   </ObiText>
</template>

<script>
import IconDeviceWhite from '@/components/Icons/general/IconDeviceWhite.svg';
import ErrorHandler from '@/libs/error-handler';
import Form from '@/libs/form';
import Brand from '@/api/Models/Brand';
import Location from '@/api/Models/Location';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import PanelLocationApi from '@/api/PanelLocationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';
import ObiSessionLimit from '@/components/UI/ObiSessionLimit';
import formDataDevice from '@/views/pages/Device/FormData/form-data-device';
import formDataPortal from '@/views/pages/Portal/FormData/form-data-portal';
import ObiPortalGeneralTab from '@/components/Common/ObiPortalGeneralTab';
import ObiPortalProviderMethod from '@/components/Common/ObiPortalProviderMethod';
import { getSubdomain } from '@/libs/url-utils';
import PanelPortalApi from '@/api/PanelPortalApi';
import { each } from 'lodash';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';

export default {
   name: 'OnboardingDeviceCreate',
   components: { ObiSessionLimit, ObiPortalGeneralTab, ObiPortalProviderMethod },
   i18n: {
      messages: {
         tr: {
            title: 'Yeni Kullanıcı Profili Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni Kullanıcı Profili ekleyebilirsiniz',
            paid_sale: 'Misafirlerinize internet satışı yapmak için bu seçeneği aktifleştirin.',
            time_limit_content:
               'Konuklarınızın maksimum oturum süresini ayarlayın. Süre dolduktan sonra tekrar oturum açmaları gerekir.',
            download_limit_content: 'Konuklarınızın maksimum indirme hızını ayarlayın.',
            upload_limit_content: 'Konuklarınızın maksimum yükleme hızını ayarlayın.',
            device: {
               title: 'Donanımınızı Seçin',
               subtitle: 'Listeden kurulum yapmak istediğiniz markayı seçip Devam Et butonuna tıklayın.',
            },
            device_detail: {
               title: 'Cihaz Bilgilerinizi Girin',
               subtitle: 'Cihazını kaydetmek için gerekli bilgileri girin.',
            },
            form_title: 'Lokasyon Bilgileri',
            login_html: 'Mikrotik Login Dosyasını İndirin',
            labels: {
               select_brand: 'Donanımınızı Seçin',
            },
            fields: {
               shared_users: 'Eş Zamanlı Cihaz Sayısı',
               location_id: 'Cihaz Lokasyonu',
               mac_address: 'Cihaz Mac Adresi',
               serial_number: 'Seri Numarası',
               secret: 'Gizli Anahtar',
               name: 'Cihaz Adı',
               nat_interface: 'Nat Interface',
               nat_ip: 'Nat IP',
               hotspot_dhcp_ip: 'Hotspot DHCP IP',
               hotspot_dhcp_subnet: 'Hotspot DHCP Subnet',
            },
            actions: {
               prev: 'Geri',
               next: 'Devam Et',
               save: 'Kaydet',
               deviceList: 'Cihaz Listesi',
               createScript: 'Script Oluştur',
               download: 'indir',
            },
            final: {
               title: 'Kurulum Tamamlandı',
               subtitle: ' ',
               completed_title: 'Kurulumu Başarıyla tamamladınız',
               completed_desc: ' ',
            },
            time_limit_title: 'Zaman Limiti',
            upload_limit_title: 'Yükleme Hızı',
            download_limit_title: 'İndirme Hızı',
            minute: 'Dakika',
            hour: 'Saat',
            day: 'Gün',
            'Giriş Metodları': 'Giriş Metodları',
            'Portal Ayarları': 'Portal Ayarları',
            'Kontrol Paneline Git': 'Kontrol Paneline Git',
         },
         en: {
            title: 'Add New User Profile',
            subtitle: 'You can add a new User Profile by entering the information below',
            paid_sale: 'Activate this option to sell internet to your guests.',
            time_limit_content:
               'Set the maximum session duration for your guests. After the time expires, they will need to log in again.',
            download_limit_content: 'Set the maximum download speed for your guests.',
            upload_limit_content: 'Set the maximum upload speed for your guests.',
            device: {
               title: 'Select Your Hardware',
               subtitle: 'Select the brand you want to install from the list and click the Continue button.',
            },
            device_detail: {
               title: 'Enter Your Device Information',
               subtitle: 'Enter the required information to register your device.',
            },
            form_title: 'Location Information',
            login_html: 'Download the Mikrotik Login File',
            labels: {
               select_brand: 'Select Your Hardware',
            },
            fields: {
               shared_users: 'Concurrent Device Count',
               location_id: 'Device Location',
               mac_address: 'Device Mac Address',
               serial_number: 'Serial Number',
               secret: 'Secret Key',
               name: 'Device Name',
               nat_interface: 'Nat Interface',
               nat_ip: 'Nat IP',
               hotspot_dhcp_ip: 'Hotspot DHCP IP',
               hotspot_dhcp_subnet: 'Hotspot DHCP Subnet',
            },
            actions: {
               prev: 'Back',
               next: 'Continue',
               save: 'Save',
               deviceList: 'Device List',
               createScript: 'Create Script',
               download: 'Download',
            },
            final: {
               title: 'Setup Completed',
               subtitle: ' ',
               completed_title: 'You have successfully completed the setup',
               completed_desc: ' ',
            },
            time_limit_title: 'Session Timeout ',
            upload_limit_title: 'Upload Speed',
            download_limit_title: 'Download Speed',
            minute: 'Minute',
            hour: 'Hour',
            day: 'Day',
            'Giriş Metodları': 'Login Methods',
            'Portal Ayarları': 'Portal Settings',
            'Kontrol Paneline Git': 'Go to Dashboard',
         },
      },
   },

   computed: {
      locationSelectOptions() {
         return this.dataLocations
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
   },

   async beforeMount() {
      this.loadPortalDetail();
      this.loadDeviceBrands();
   },

   data() {
      return {
         IconDeviceWhite,
         currentStep: 1,
         formDataDevice,
         isLoading: false,
         formDataPortal,
         selectedPortalId: null,
         isLoadingBrands: false,
         isLoadingLocations: false,
         isFileDownloading: false,
         savedDevice_id: '',
         previewUrl: null,
         portalId: null,
         dataPortalDetail: SingleResourceResponse.create(),
         portalOptions: null,
         portalProviderOptions: null,
         selectedTab: 'portal-edit',
         tabItems: [
            {
               id: 'portal-edit',
               label: this.$t('Portal Ayarları'),
            },
            {
               id: 'providers-edit',
               label: this.$t('Giriş Metodları'),
            },
         ],
         formDataProfile: Form.create([
            'name',
            'session_duration',
            'max_upload',
            'max_download',
            'shared_user',
            'paid_sale',
         ])
            .validate({
               name: 'required',
               shared_user: 'required',
            })
            .defaults({
               name: 'Standart User Profile',
               shared_user: 3,
               paid_sale: {
                  is_active: false,
                  option: 'euro',
                  value: 1,
               },
               session_duration: {
                  is_active: false,
                  option: 'day',
                  value: 1,
               },
               max_upload: {
                  is_active: false,
                  option: 'mbps',
                  value: 1,
               },
               max_download: {
                  is_active: false,
                  option: 'mbps',
                  value: 1,
               },
            }),

         formDataMikrotikDevice: Form.create([
            'gateway_interface',
            'gateway_ip',
            'hotspot_address',
            'hotspot_subnet',
            'guestInterfaces',
         ]).validate({
            gateway_interface: 'required',
            gateway_ip: 'required',
            hotspot_address: 'required',
            hotspot_subnet: 'required',
            guestInterfaces: 'required',
         }),
         createdScript: '',
         selectedBrand: null,
         dataLocations: PaginationResponse.create().map(Location),
         dataDeviceBrands: PaginationResponse.create().map(Brand),
         timeOptions: [
            {
               label: this.$t('minute'),
               id: 'minute',
               min: 0,
               max: 60,
               step: 5,
            },
            {
               label: this.$t('hour'),
               id: 'hour',
               min: 0,
               max: 24,
               step: 1,
            },
            {
               label: this.$t('day'),
               id: 'day',
               min: 0,
               max: 365,
               step: 1,
            },
         ],
         currencyOptions: [
            {
               label: 'EURO',
               id: 'eur',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'USD',
               id: 'usd',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'GBP',
               id: 'gbp',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'TRY',
               id: 'try',
               min: 0,
               max: 6000,
               step: 5,
            },
         ],
         speedOptions: [
            {
               label: 'Kbps',
               id: 'kbps',
               min: 0,
               max: 1024,
               step: 128,
            },
            {
               label: 'Mbps',
               id: 'mbps',
               min: 1,
               max: 100,
               step: 1,
            },
            {
               label: 'Gbps',
               id: 'gbps',
               min: 1,
               max: 100,
               step: 1,
            },
         ],
      };
   },

   methods: {
      show() {
         this.$refs.modal.show();
         this.clearModal();
      },
      clearModal() {
         this.currentStep = 1;
         this.createdScript = '';
         this.selectedBrand = new Brand({});
         this.isLoading = false;
         this.isLoadingBrands = false;
         this.isLoadingLocations = false;
         this.formDataDevice.reset();
         this.formDataMikrotikDevice.gateway_interface = '';
         this.formDataMikrotikDevice.gateway_ip = '';
         this.formDataMikrotikDevice.hotspot_address = '';
         this.formDataMikrotikDevice.hotspot_subnet = '';
         this.formDataMikrotikDevice.guestInterfaces = '';
      },
      hide() {
         this.$refs.modal.hide();
         this.clearModal();
      },
      formatMAC(value) {
         if (this.formDataDevice.mac_address.length <= 17) {
            this.formDataDevice.mac_address = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.formDataDevice.mac_address = this.formDataDevice.mac_address.substr(0, 17);
         }
      },
      async submitForm() {
         if (!this.formDataDevice.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const formData = this.formDataDevice.toObject();
            formData.user_profile = this.formDataProfile.toObject();
            const response = await PanelDeviceApi.store(formData);
            this.savedDevice_id = response.data.id;
            this.formDataDevice.reset();
            this.formDataProfile.reset();
            this.loadPortalDetail();

            this.currentStep++;
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async submitPortalPut() {
         if (!this.formDataPortal.isValid()) return;

         this.isLoading = true;
         try {
            await PanelPortalApi.update(this.portalId, this.formDataPortal.toObject());
            await this.loadPortalDetail();
            this.currentStep++;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async loadLocations() {
         try {
            this.isLoadingLocations = true;

            this.dataLocations.merge(await PanelLocationApi.index({ per_page: 9999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingLocations = false;
         }
      },
      async downloadMikrotikLogin() {
         this.isFileDownloading = true;
         try {
            const response = await PanelDeviceApi.downloadLoginHtml(this.savedDevice_id, null);

            var fileURL = window.URL.createObjectURL(new Blob([response]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'login.html');
            document.body.appendChild(fileLink);
            fileLink.click();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isFileDownloading = false;
         }
      },
      async createScript() {
         this.isLoading = true;
         try {
            this.createdScript = await PanelDeviceApi.mikrotikScriptGenerate(
               this.savedDevice_id,
               this.formDataMikrotikDevice.toObject()
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async loadDeviceBrands() {
         try {
            this.isLoadingBrands = true;

            this.dataDeviceBrands.merge(await PanelDeviceApi.brands());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingBrands = false;
         }
      },

      async loadPortalDetail() {
         this.isLoading = true;

         try {
            if (!this.portalId) {
               const { data } = await PanelPortalApi.index();

               if (data && data.length > 0) {
                  this.dataPortalDetail.merge(data[0]);
                  this.portalId = data[0].id;
                  await this.loadPortalDetail();
                  return;
               }
            } else {
               this.previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/preview/${this.portalId}?tenant=${getSubdomain()}`;
               try {
                  const { data } = await PanelPortalApi.show(this.portalId);
                  this.dataPortalDetail.merge(data);
                  this.currentStep = 3;
               } catch (error) {
                  console.error(error);
               }
            }

            this.formDataPortal.merge(this.dataPortalDetail.only('name'));
            this.formDataRedirectRules = this.dataPortalDetail.get('redirect_rules', []);
            this.formDataSurveys = this.dataPortalDetail.get('surveys', []);
            this.agreements = this.dataPortalDetail.get('agreements', {});

            const dataStyleOptions = this.dataPortalDetail.get('style_options', []);

            const styles = {};
            each(dataStyleOptions, (item) => (styles[item.key] = item.value));

            this.portalOptions = {
               styles: styles,
               messages: this.dataPortalDetail.get('messages', {}),
            };

            this.portalProviderOptions = {
               providers: this.dataPortalDetail.get('providers', []),
               messages: this.dataPortalDetail.get('messages', {}),
            };
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      selectBrand(brand) {
         this.selectedBrand = brand;
         this.formDataDevice.set('brand_id', brand.getId());
      },

      isSelectedBrand(brand) {
         if (this.selectedBrand === null) {
            return false;
         }
         return brand.getId() === this.selectedBrand.getId();
      },

      prevStep() {
         if (this.currentStep === 1) return;

         this.currentStep = this.currentStep - 1;
      },

      nextStep() {
         this.currentStep = this.currentStep + 1;
      },
      guideUrl(guide) {
         return `https://obifi.com/guide/${guide.slug}`;
      },

      async onContentChanged(value) {
         this.formDataPortal.merge({ messages: value });
         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'message_changed',
               value: value,
            },
            '*'
         );
      },
      async onProviderChanged(value) {
         const providers = value.filter((value) => value.checked);
         this.formDataPortal.merge({ providers: providers });

         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'provider_changed',
               value: providers,
            },
            '*'
         );
      },
      async onStyleChanged(value) {
         this.formDataPortal.merge({ styles: value });
         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'style_changed',
               value: value,
            },
            '*'
         );
      },
   },
};
</script>
<style scoped>
.border-primary {
   color: #2f71f2 !important;
}
.brand-item:hover {
   border: 1px solid #2f71f2 !important;
}
.script-pre {
   height: 200px;
}
.device-list {
   max-width: 750px;
}
.device-items {
   margin-top: 20px;
}
@media (max-width: 375px) {
   .device-items {
      display: flex;
      flex-direction: column;
   }
}
</style>
