<template>
   <ObiText class="page-complete">
      <ObiText class="page-complete-content">
         <IconWelcome />
         <ObiText as="h1" class="page-title" v-html="$t('complete.page_title')" />
         <ObiText class="page-description my-5" v-html="$t('complete.page_description')" />

         <ObiButton
            as="router-link"
            class="mt-4"
            size="lg"
            block
            :text="$t('complete.button_login')"
            :to="{ name: 'auth-login' }"
         />
      </ObiText>
   </ObiText>
</template>

<script>
import IconWelcome from '@/components/Icons/form/IconWelcome';

export default {
   name: 'PageComplete',
   components: {
      IconWelcome,
   },
};
</script>

<style lang="scss" scoped>
.page-complete {
   text-align: center;

   .page-complete-content {
      margin: 0 auto;
      max-width: 400px;
   }

   .page-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .page-description {
      color: #777d96;
      font-size: 13px;
   }
}
</style>
