import Form from '@/libs/form';

const fields = ['name'];

const rules = {
   name: 'required',
};

export default Form.create(fields)
   .defaults({ name: null })
   .validate(rules);
