<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconPortal" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiTitle class="mb-3" color="primary" :title="$t('form_title')" />

      <ObiFloatingInput
         :label="$t('fields.name')"
         :disabled="isLoading"
         :placeholder="$t('fields.name')"
         v-model="formDataPortal.name"
         :errors="formDataPortal.errors.get('name')"
      />

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelPortalApi from '@/api/PanelPortalApi';

import IconPortal from '@/components/Icons/general/IconPortal';

import formDataPortal from '@/views/pages/Portal/FormData/form-data-portal';

export default {
   name: 'ModalPortalCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Portal Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni bir portal oluşturun',
            form_title: 'Portal Bilgileri',
            fields: {
               name: 'Portal Adı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Portal',
            subtitle: 'Create a new portal by entering the following information',
            form_title: 'Portal Information',
            fields: {
               name: 'Portal Name',
               country: 'Country',
               city: 'City',
               district: 'District',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconPortal,
         isLoading: false,
         formDataPortal,
      };
   },
   methods: {
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formDataPortal.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelPortalApi.store(this.formDataPortal.toObject());

            this.formDataPortal.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
