<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('PROFILE_NAME')"
               :disabled="isLoading"
               :placeholder="$t('PROFILE_NAME')"
               v-model="formData.name"
               :errors="formData.errors.get('name')"
            />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import FirewallProfileApi from '@/api/FirewallProfileApi';

import IconLayers from '@/components/Icons/general/IconLayers.svg';

import formData from '@/views/pages/Settings/FormData/form-data-profile';

export default {
   name: 'ModalLocationCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Yeni Profil Oluştur',
            subtitle: 'Yeni bir profil oluşturmak için sizden istenilen bilgileri giriniz',
            PROFILE_NAME: 'Profil Adı',
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add New Profile',
            subtitle: 'Please enter the information to create a new profile',
            PROFILE_NAME: 'Profile Name',
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formData,
      };
   },
   methods: {
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formData.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await FirewallProfileApi.store(this.formData.toObject());

            this.formData.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
