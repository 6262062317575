<template>
   <ObiCard :hideBody="!opened">
      <template #header>
         <ObiTitle :icon="RuleCardIcon" :title="title">
            <template #actions>
               <ObiCol class="d-flex">
                  <ObiButton compact size="lg" color="lighter" :icon="EditIcon" @click="$emit('edit', index)" />
                  <v-popover>
                     <ObiButton class="ms-2" size="lg" compact color="lighter" :icon="DeleteIcon" />

                     <template slot="popover">
                        <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                        <ObiButton
                           class="ms-2"
                           size="sm"
                           color="danger"
                           :text="$t('actions.yes')"
                           v-close-popover
                           @click="deleteItem"
                        />
                        <ObiButton class="ms-2" size="sm" color="warning" :text="$t('actions.no')" v-close-popover />
                     </template>
                  </v-popover>
               </ObiCol>
            </template>
         </ObiTitle>
      </template>
      <template #body>
         <ObiLoading v-if="isLoading" />
         <ObiText v-else class="p-4">
            <ObiRow>
               <ObiCol>
                  <ObiInput
                     @input="onChanged"
                     v-model="formDataAd.name"
                     :label="$t('fields.name')"
                     :placeholder="$t('fields.name')"
                     :errors="formDataAd.errors.get('name')"
                  />
               </ObiCol>
            </ObiRow>
            <ObiRow>
               <ObiCol>
                  <ObiTitle class="mb-2" :title="$t('fields.ad_type')" />
                  <ObiText class="mb-5">
                     <ObiButtonSwitch :items="typeOptions" v-model="formDataAd.type" />
                  </ObiText>
               </ObiCol>
            </ObiRow>
            <ObiRow v-if="formDataAd.type === 'image'">
               <ObiCol col="12">
                  <ObiFileUpload
                     :url="uploadURL"
                     collection="ads"
                     :index="1"
                     :currentImageUrl="formDataAd.images ? formDataAd.images[0] : null"
                     @change="imageChanged"
                     @removed="imageRemoved"
                  />
               </ObiCol>
            </ObiRow>
            <ObiRow class="p-4">
               <ObiCol col="12" class="text-center mt-4">
                  <ObiButton size="lg" wider :text="$t('actions.save')" @click="saveOrUpdate" />
               </ObiCol>
            </ObiRow>
         </ObiText>
      </template>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';
import PanelPortalApi from '@/api/PanelPortalApi';

import RuleCardIcon from '@/components/Icons/portals/RuleCardIcon';
import EditIcon from '@/components/Icons/portals/EditIcon.svg';
import DeleteIcon from '@/components/Icons/portals/DeleteIcon.svg';
import IconExternalLink from '@/components/Icons/general/IconExternalLink.svg';

import formDataAd from '@/views/pages/Portal/FormData/form-data-portal-ad';

export default {
   name: 'ObiAdCard',
   components: {},
   i18n: {
      messages: {
         tr: {
            are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
            fields: {
               name: 'Reklam Adı',
               ad_type: 'Reklam Türü',
            },
            actions: {
               yes: 'Evet',
               no: 'Hayır',
               save: 'Kaydet',
            },
         },
         en: {
            are_you_sure_delete: 'Are you sure you want to delete?',
            fields: {
               name: 'Advertisement Name',
               ad_type: 'Advertisement Type',
            },
            actions: {
               yes: 'Yes',
               no: 'No',
               save: 'Save',
            },
         },
      },
   },
   props: {
      item: {
         type: Object,
      },
      opened: {
         type: Boolean,
         default: false,
      },
      index: {
         type: Number,
         default: 0,
      },
      portalId: {
         type: [Number, String],
         required: true,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      title() {
         return this.formDataAd.name ? this.formDataAd.name : 'Yeni Reklam';
      },
      uploadURL() {
         return `/panel/portal/${this.$props.portalId}/image`;
      },
   },
   mounted() {
      if (this.$props.item !== null) {
         this.formDataAd.merge(this.$props.item);
      } else {
         this.formDataAd.defaults({ type: 'image', images: [], embed_code: null });
      }
   },
   data() {
      return {
         IconExternalLink,
         RuleCardIcon,
         EditIcon,
         DeleteIcon,
         formDataAd,
         editingItem: null,
         isLoading: false,
         images: [],
         typeOptions: [
            {
               label: 'Resim',
               id: 'image',
            },
            {
               label: 'Embed',
               id: 'embed',
            },
         ],
      };
   },
   methods: {
      async onChanged() {},
      async deleteItem() {
         try {
            await PanelPortalApi.ads.delete(this.portalId, this.item.id);
            Notify.success(this.$t('Reklam silindi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            this.$emit('changed', this.formDataAd);
         }
      },
      async saveOrUpdate() {
         if (!this.formDataAd.isValid()) return;

         this.isLoading = true;
         try {
            if (this.item.id) {
               await PanelPortalApi.ads.update(this.portalId, this.item.id, this.formDataAd.toObject());
               Notify.success(this.$t('Reklam güncellendi!'));
            } else {
               await PanelPortalApi.ads.store(this.portalId, this.formDataAd.toObject());
               Notify.success(this.$t('Reklam eklendi!'));
            }

            this.$emit('changed', this.formDataAd);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async startEdit(index) {
         if (this.editingItem === index) {
            this.editingItem = null;
            return;
         }
         this.editingItem = index;
      },
      async imageChanged(image, index) {
         console.log(this.formDataAd.images);
         if (this.formDataAd.images === null) {
            this.formDataAd.images = [];
         }
         this.formDataAd.images[index - 1] = image;
      },
      async imageRemoved(image, index) {
         this.formDataAd.images.shift(index - 1);
      },
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';
</style>
