import get from 'lodash/get';

import Router from '@/router';
import AuthApi from '@/api/AuthApi';
import User from '@/api/UserApi';

const state = {
   accessToken: null,
   ipAddress: null,
   authUser: null,
   isAdmin: false,
};

const getters = {
   isLoggedIn(state) {
      return !!state.accessToken && !!state.authUser;
   },
   accessToken(state) {
      return state.accessToken;
   },
   ipAddress(state) {
      return state.ipAddress;
   },
   authUser(state) {
      return state.authUser;
   },
   isAdmin(state) {
      return state.authUser ? state.authUser.is_team_admin : false;
   },
   currentProfile(state) {
      return state.authUser ? state.authUser.current_profile : null;
   },
   isSetupCompleted(state) {
      return state.authUser &&
         state.authUser.current_profile &&
         state.authUser.current_profile.linked_ip_addresses != null
         ? true
         : false;
   },
};

const actions = {
   setIpAddress(context, ipAddress) {
      context.commit('setIpAddress', ipAddress);
   },
   getAccessToken(context) {
      return context.getters['accessToken'];
   },
   async syncUser(context) {
      const userHid = get(context, 'state.authUser.hid');
      if (!userHid) return;

      const { data: authUser } = await User.show(userHid);

      context.commit('setAuthUser', authUser);

      return authUser;
   },
   async login(context, payload) {
      const response = await AuthApi.login(payload);

      context.commit('setAccessToken', get(response, 'data.token'));
      context.commit('setAuthUser', get(response, 'data.user'));

      return response;
   },
   async register(context, payload) {
      const response = await AuthApi.register(payload);

      context.commit('me', get(response, 'data.tenant'));

      return response;
   },
   async forgotPassword(context, payload) {
      const response = await AuthApi.forgot(payload);

      return response;
   },
   async me(context) {
      const response = await AuthApi.me();

      context.commit('me', get(response, 'data'));

      return response;
   },
   async logout(context, opts = {}) {
      await AuthApi.logout();
      context.commit('logout');

      if (opts.redirectRoute) {
         await Router.push({ name: opts.redirectRoute });
      }
   },
   async cleanSession(context, opts = {}) {
      context.commit('logout');

      if (opts.redirectRoute) {
         await Router.push({ name: opts.redirectRoute });
      }
   },
};

const mutations = {
   setIpAddress(state, ipAddress) {
      state.ipAddress = ipAddress;
   },
   setAuthUser(state, authUser) {
      state.authUser = authUser;
   },
   setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
   },
   me(state, user) {
      state.authUser = user;
   },
   logout(state) {
      state.accessToken = null;
      state.ipAddress = null;
      state.authUser = null;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
