import Onboarding from '@/views/layouts/Onboarding';

import CreateDevice from '@/views/pages/Onboarding/CreateDevice';

export default {
   path: '/onboarding',
   component: Onboarding,
   children: [
      {
         path: 'device',
         name: 'onboarding-create-device',
         component: CreateDevice,
      },
   ],
};
