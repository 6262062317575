import Http from '@/libs/http';

class FirewallProfileApi extends Http {
   show(params) {
      return super.get('cloudfirewall/firewall_profile/show', { params });
   }

   store(params) {
      return super.post('cloudfirewall/firewall_profile', params);
   }

   update(params) {
      return super.put(`cloudfirewall/firewall_profile/${params.hid}`, params);
   }

   linkIp(ipAddress) {
      return super.post('cloudfirewall/firewall_profile/link-ip-address', { ip_address: ipAddress });
   }

   unlinkIp(ipAddress) {
      return super.delete(`cloudfirewall/firewall_profile/unlink-ip-address/${ipAddress}`);
   }

   domains(params) {
      return super.get('cloudfirewall/firewall_profile/domains', { params });
   }

   removeDomain(domain, type) {
      return super.delete('cloudfirewall/firewall_profile/domains/' + domain + '/' + type);
   }

   addDomain(params) {
      return super.post('cloudfirewall/firewall_profile/domains', params);
   }

   ipAddressList(params) {
      return super.get('cloudfirewall/firewall_profile/ip-addresses', { params });
   }

   macAddressList(params) {
      return super.get('cloudfirewall/firewall_profile/mac-addresses', { params });
   }

   changeCaptiveStatus() {
      return super.post(`cloudfirewall/firewall_profile/change-captive-status`);
   }
}

export default new FirewallProfileApi();
