import 'dayjs/locale/tr';

import { mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
   computed: {
      ...mapGetters('app', ['tenant']),
      tenant() {
         if (this.$store) {
            return this.$store.state.app.tenant;
         }
         return {};
      },
      currentLocale() {
         return get(this.$i18n, 'locale', localStorage.getItem('last-locale'));
      },
      language() {
         const foundLocale = this.currentLocale;
         if (this.$i18n) {
            this.$i18n.locale = foundLocale || this.$i18n.fallbackLocale;
         }
         return foundLocale;
      },
   },
   methods: {},
};
