import BaseModel from './BaseModel';

export default class Model extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getSlug() {
      return this.get('slug');
   }

   getLogo() {
      return this.get('logo');
   }
}
