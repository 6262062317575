<template>
   <ObiPage class="p-4">
      <ObiText class="p-2" v-if="!isLoading && dataDevices.get('meta.total')">
         <ObiText class="center-item mb-4 mt-0">
            <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="$refs.modalDeviceCreate.show()" />
         </ObiText>
         <ObiDeviceCardMini class="mb-2" v-for="device in dataDevices.get('data')" :key="device.id" :device="device" />
      </ObiText>

      <Obi404 v-else-if="!isLoading && !dataDevices.get('meta.total')" icon="devices">
         <template #actions>
            <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="$refs.modalDeviceCreate.show()" />
         </template>
      </Obi404>
      <ObiLoading v-if="isLoading" />
      <ModalDeviceCreate ref="modalDeviceCreate" @success="loadDevices" />
   </ObiPage>
</template>

<script>
import merge from 'lodash/merge';

import IconDevice from '@/components/Icons/general/IconDevice.svg';
import ModalDeviceCreate from '@/views/pages/Device/_Modals/Create';

import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';

import Device from '@/api/Models/Device';
import formDataDevice from '@/views/pages/Device/FormData/form-data-device';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

export default {
   name: 'PageDeviceList',

   i18n: {
      messages: {
         tr: {
            page_title: 'Cihazlarım',
            fields: {
               brand: 'Cihaz Marka',
               model: 'Model Adı',
               name: 'Adı',
               status: 'Durumu',
               location: 'Konum',
               mac_address: 'MAC',
               actions: 'İşlemler',
            },
            actions: {
               create: 'Cihaz Ekle',
               edit: 'Düzenle',
               delete: 'Sil',
            },
            tabItems: {
               general: 'Genel',
               info: 'Bilgiler',
               networks: 'Network Tanımları',
               logs: 'Log Dosyaları',
               mac_address: 'MAC Yönetimi',
            },
         },
         en: {
            page_title: 'My Devices',
            fields: {
               brand: 'Device Brand',
               model: 'Model Name',
               name: 'Name',
               status: 'Status',
               location: 'Location',
               mac_address: 'MAC',
               actions: 'Actions',
            },
            actions: {
               create: 'Add Device',
               edit: 'Edit',
               delete: 'Delete',
            },
            tabItems: {
               general: 'General',
               info: 'Information',
               networks: 'Networks',
               logs: 'Log Files',
               mac_address: 'MAC Management',
            },
         },
      },
   },

   components: {
      ModalDeviceCreate,
   },

   data() {
      return {
         IconDevice,
         isLoading: false,
         dataDevices: PaginationResponse.create().map(Device),
         selectedTab: 'devices-show',
         formDataDevice,
         tabItems: [
            {
               id: 'devices-show',
               label: this.$t('tabItems.general'),
            },
            {
               id: 'devices-edit',
               label: this.$t('tabItems.info'),
            },
            {
               id: 'devices-networks',
               label: this.$t('tabItems.networks'),
            },
            {
               id: 'devices-logs',
               label: this.$t('tabItems.logs'),
            },
            {
               id: 'mac-addresses',
               label: this.$t('tabItems.mac_address'),
            },
            // {
            //    id: 'devices-rules',
            //    label: 'Kurallar'
            // },
            // {
            //    id: 'devices-users',
            //    label: 'Kullanıcılar'
            // }
         ],
         tableHeaders: [
            {
               text: this.$t('fields.brand'),
               value: 'brand',
               sortable: true,
               width: 200,
            },
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.location'),
               value: 'location',
               sortable: true,
            },
            {
               text: this.$t('fields.mac_address'),
               value: 'mac_address',
               sortable: true,
            },
            {
               text: this.$t('fields.status'),
               value: 'status',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-end',
               width: 200,
            },
         ],
      };
   },

   async mounted() {
      await this.loadDevices();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadDevices({ page });
      },

      async loadDevices(params) {
         try {
            this.isLoading = true;

            params = merge(this.$route.query, params);

            this.dataDevices.merge(await PanelDeviceApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async deleteDevice(dialog, device) {
         try {
            this.isLoading = true;
            await PanelDeviceApi.delete(device.getId(), null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            dialog.close();
            this.loadDevices();
         }
      },
      async deviceClicked(item) {
         this.formDataDevice.merge(item.toObject());
         this.$refs.showDeviceDetail.show();
      },
   },
};
</script>

<style lang="scss" scoped>
.center-item {
   display: grid;
   grid-gap: 1rem;
   margin-top: 3.5rem;
   align-items: center;
   grid-auto-flow: column;
   justify-content: center;
}
</style>
