import dayjs from 'dayjs';

export const formatDate = (value, format) => {
   if (!value) return null;

   return dayjs(value.date || value).format(format);
};

export const timestampToDate = (value) => {
   if (!value) return null;

   return dayjs(parseInt(value));
};

export const diffFromNow = (value) => {
   if (!value) return null;

   return dayjs().diff(value.value || value, 'minute');
};

export const trFormatDate = (value) => formatDate(value, 'DD.MM.YYYY');
export const trFormatDateLong = (value) => formatDate(value, 'DD.MM.YYYY HH:mm');
