import Http from '../libs/http';

class AuthApi extends Http {
   login(payload) {
      return super.post('user/login', payload);
   }

   register(payload) {
      return super.post('user/register', payload);
   }

   async logout() {
      return super.post(`cloudfirewall/logout`);
   }

   me() {
      return super.get('panel/user/me');
   }

   updateMe(payload) {
      return super.put('panel/user/me', payload);
   }

   forgot(payload) {
      return super.post(`user/forgot-password`, payload);
   }

   reset(payload) {
      return super.post(`user/reset-password`, payload);
   }

   changeTeam(teamId, payload) {
      return super.post(`panel/user/team/change/${teamId}`, payload);
   }

   changeProfile(teamId, payload) {
      return super.post(`cloudfirewall/firewall_profile/change/${teamId}`, payload);
   }

   acceptInvite(token, payload) {
      return super.post(`panel/user/invite/accept/${token}`, payload);
   }

   denyInvite(token, payload) {
      return super.post(`panel/user/invite/deny/${token}`, payload);
   }

   stripeSessionCheck(session_id, payload) {
      return super.post(`user/stripe/session/${session_id}`, payload);
   }
}

export default new AuthApi();
