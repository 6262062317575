<template>
   <ObiPage class="p-2 ps-0">
      <ObiText class="obi-content">
         <ObiRow>
            <ObiCard class="vh-100 col-md-4" elevation="2">
               <template #header>
                  <div class="d-flex justify-content-between">
                     <ObiTitle :title="`Müşterilerim (${dataLocations.get('meta.total')})`"> </ObiTitle>
                     <ObiButtonSwitch :items="licenseTypes" v-model="licenseType" @click="loadLocations" />
                  </div>
                  <ObiInput
                     class="mt-2"
                     clean
                     compact
                     v-model="locationName"
                     icon="mdi mdi-magnify"
                     :placeholder="'Müşteri Arayın'"
                     @change="loadLocations"
                  />
               </template>

               <ObiLoading v-if="isLoadingLocations" />

               <div class="location-list mb-4" v-else>
                  <ObiTitle class="row p-2 text-center mb-4">
                     <ObiButton size="lg" outline wider text="Yeni Müşteri" @click="$refs.modalLocationCreate.show()" />
                  </ObiTitle>
                  <ObiNotFound
                     compact
                     image-height="150"
                     :title="$t('devices.not_found_title')"
                     :description="$t('devices.not_found_description')"
                     v-if="!isLoadingLocations && !dataLocations.get('meta.total')"
                  />
                  <ObiText v-else>
                     <div class="col-md-12" v-for="location in dataLocations.get('data')" :key="location.get('id')">
                        <div
                           class="card h-100"
                           :class="{
                              'border-2': selectedLocation && selectedLocation.get('id') === location.get('id'),
                              'border-primary': selectedLocation && selectedLocation.get('id') === location.get('id'),
                           }"
                           @click="locationClicked(location)"
                        >
                           <ObiLocationCard
                              :showDate="getSubdomain() != 'quedra'"
                              class="h-100 mb-2 border-0 bg-transparent"
                              :location="location"
                           />
                        </div>
                        <hr />
                     </div>
                  </ObiText>
               </div>
            </ObiCard>

            <ObiText class="col-md-8" v-if="selectedLocation === null">
               <Obi404 title="Lütfen bir müşteri seçin" desc="" icon="locations" />
            </ObiText>

            <ObiText class="col-md-8" v-else-if="isLoading">
               <ObiLoading />
            </ObiText>

            <ObiText v-else-if="!isLoading && selectedLocation !== null" class="col-md-8 d-flex p-2">
               <ObiCard v-if="!locationNotConfigured" class="col-md-12 border-0" elevation="0">
                  <template #body>
                     <ObiTabBar class="p-0" noPadding>
                        <template #actions v-if="['portal-edit', 'providers-edit'].includes(selectedTab)">
                           <ObiButton
                              wider
                              :loading="saveButtonLoading"
                              color="primary"
                              text="Kaydet"
                              @click="submitPortalPut"
                           />
                        </template>
                        <template #tabs>
                           <ObiTabs
                              :items="getSubdomain() == 'quedra' ? quedraTabItems : tabItems"
                              vertical
                              v-model="selectedTab"
                           />
                        </template>
                        <ObiText class="p-0">
                           <ObiRow>
                              <ObiCol col="8">
                                 <LocationEdit
                                    class="p-2"
                                    :isRouted="false"
                                    v-if="selectedTab === 'settings-location'"
                                 />
                                 <ObiPortalGeneralTab
                                    v-if="selectedTab === 'portal-edit' && portalOptions"
                                    :portal-id="selectedPortalId"
                                    :options="portalOptions"
                                    @contentChanged="onContentChanged"
                                    @portalStyleChanged="onStyleChanged"
                                 />
                                 <ObiPortalProviderMethod
                                    v-if="selectedTab === 'providers-edit' && portalProviderOptions"
                                    :portal-id="selectedPortalId"
                                    :options="portalProviderOptions"
                                    @portalStyleChanged="onStyleChanged"
                                    @contentChanged="onContentChanged"
                                    @providerChanged="onProviderChanged"
                                 />
                                 <DeviceIndex v-if="!isLoading && selectedTab === 'settings-devices'" />
                                 <ObiCard class="p-0" v-if="!isLoading && selectedTab === 'settings-license'">
                                    <ObiText
                                       class="text-success h3"
                                       :class="{
                                          'text-danger': selectedLocation.get('license_remain') <= 0,
                                          'text-success': selectedLocation.get('license_remain') > 0,
                                       }"
                                    >
                                       <span
                                          v-if="
                                             getSubdomain() != 'quedra' &&
                                                !selectedLocation.get('is_trial') &&
                                                selectedLocation.get('license_remain') <= 0
                                          "
                                       >
                                          Lisans Bitti: {{ trFormatDate(selectedLocation.getLicenseEndAt()) }}
                                       </span>
                                       <span
                                          v-if="
                                             getSubdomain() != 'quedra' &&
                                                selectedLocation.get('is_trial') &&
                                                selectedLocation.get('trial_remain') > 0
                                          "
                                       >
                                          Demo Bitmesine Kalan {{ selectedLocation.get('trial_remain') }} Gün</span
                                       >
                                       <span
                                          v-if="
                                             getSubdomain() != 'quedra' &&
                                                selectedLocation.get('trial_remain') <= 0 &&
                                                selectedLocation.get('is_trial')
                                          "
                                       >
                                          Demo Bitti</span
                                       >
                                       <span
                                          v-if="
                                             getSubdomain() != 'quedra' &&
                                                !selectedLocation.get('is_trial') &&
                                                selectedLocation.get('license_remain') > 0
                                          "
                                       >
                                          Lisans Bitiş Tarihi:
                                          {{ trFormatDate(selectedLocation.getLicenseEndAt()) }}</span
                                       >
                                       <ObiText class="mt-4 text-primary">
                                          <ObiTitle>Yeni Lisans Tarihi</ObiTitle>
                                          <ObiText class="d-flex">
                                             <ObiInput
                                                clean
                                                compact
                                                class="col-md-4"
                                                type="date"
                                                v-model="license_end_at"
                                             />
                                             <ObiButton
                                                class="ms-2"
                                                size="xs"
                                                outline
                                                wider
                                                text="UYGULA"
                                                @click="applyLicense"
                                             />
                                          </ObiText>
                                       </ObiText>
                                    </ObiText>
                                 </ObiCard>
                              </ObiCol>

                              <ObiCol
                                 v-if="!locationNotConfigured"
                                 class="col-md-4 border-2 p-2 vh-100"
                                 elevation="2"
                                 id="iframe-container"
                              >
                                 <ObiLoading v-if="frameLoading" />
                                 <iframe
                                    style="width: 100%;"
                                    @load="frameIsLoaded"
                                    id="previewFrame"
                                    ref="previewFrame"
                                    :src="previewUrl"
                                    height="100%"
                                 ></iframe>
                              </ObiCol>
                           </ObiRow>
                        </ObiText>
                     </ObiTabBar>
                  </template>
               </ObiCard>
               <Obi404
                  v-if="locationNotConfigured"
                  title="KURULUM TAMAMLANMAMIŞ"
                  desc="Kurulum sihirbazıyla şimdi devam edebilirsiniz"
               >
                  <template #actions>
                     <ObiButton
                        size="lg"
                        outline
                        wider
                        text="KURULUMA DEVAM ET"
                        @click="$router.push({ name: 'onboarding-create-device' }).catch(() => {})"
                     />
                  </template>
               </Obi404>
            </ObiText>
         </ObiRow>
      </ObiText>
      <AdminLocationCreate ref="modalLocationCreate" @success="loadLocations" size="sm" />
   </ObiPage>
</template>

<script>
import IconDevice from '@/components/Icons/general/IconDevice';
import IconVisitor from '@/components/Icons/general/IconVisitor';
import IconLocation from '@/components/Icons/general/IconLocation';
import IconIntegration from '@/components/Icons/general/IconIntegration';
import IconNotification from '@/components/Icons/general/IconNotification';
import { getSubdomain } from '@/libs/url-utils';
import ErrorHandler from '@/libs/error-handler';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import Device from '@/api/Models/Device';
import { each } from 'lodash';
import Location from '@/api/Models/Location';
import PanelLocationApi from '@/api/PanelLocationApi';
import AuthApi from '@/api/AuthApi';
import AdminLocationCreate from '@/views/pages/Dashboard/_Modals/AdminLocationCreate';
import PanelPortalApi from '@/api/PanelPortalApi';
import Portal from '@/api/Models/Portal';
import ObiPortalGeneralTab from '@/components/Common/ObiPortalGeneralTab';
import ObiPortalProviderMethod from '@/components/Common/ObiPortalProviderMethod';
import formDataPortal from '@/views/pages/Portal/FormData/form-data-portal';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import DeviceIndex from '@/views/pages/Device/DeviceList';
import LocationEdit from '@/views/pages/Location/Edit';
import { trFormatDate, formatDate } from '@/libs/date-utils';
import Notify from '@/libs/notify';

export default {
   name: 'PageDashboardIndex',

   components: { AdminLocationCreate, ObiPortalGeneralTab, ObiPortalProviderMethod, DeviceIndex, LocationEdit },
   i18n: {
      messages: {
         tr: {
            page_title: 'Management',
            platforms_statistic_title: 'Popüler Platfromlar',
            browsers_statistic_title: 'Popüler Tarayıcılar',
            devices_statistic_title: 'Popüler Cihaz Türleri',
            visitors: {
               title: 'Ziyaretçi Girişleri',
               statistic_not_enough: 'İstatistik için henüz yeterli veriye sahip değiliz :(',
            },
            devices: {
               title: 'Cihazlarım',
               create: 'Yeni Cihaz',
               not_found_title: 'Müşteri Bulunamadı',
               not_found_description: 'Müşteri Bulunamadı, Yeni bir müşteri ekleyin.',
            },
            metrics: {
               visitors_title: 'Kullanıcılar',
               devices_title: 'Cihazlar',
               locations_title: 'Konumlar',
               notifications_title: 'Bildirimler',
               integrations_title: 'Entegrasyonlar',
               online_visitor: 'Online Kullanıcı',
               unsuccessful_logins: 'Başarısız Girişler',
               returned_users: 'Tekrar Oturumlar',
            },
         },
         en: {
            page_title: 'Management',
            platforms_statistic_title: 'Popular Platforms',
            browsers_statistic_title: 'Popular Browsers',
            devices_statistic_title: 'Popular Device Types',
            visitors: {
               title: 'Visitor Entries',
               statistic_not_enough: 'We do not have enough data for statistics yet :(',
            },
            devices: {
               title: 'My Devices',
               create: 'New Device',
               not_found_title: 'Customer Not Found',
               not_found_description: 'Customer Not Found, add a new customer.',
            },
            metrics: {
               visitors_title: 'Users',
               devices_title: 'Devices',
               locations_title: 'Locations',
               notifications_title: 'Notifications',
               integrations_title: 'Integrations',
               online_visitor: 'Online User',
               unsuccessful_logins: 'Unsuccessful Logins',
               returned_users: 'Returning Sessions',
            },
         },
      },
   },

   data() {
      return {
         IconDevice,
         IconVisitor,
         IconLocation,
         IconIntegration,
         IconNotification,
         selectedLocation: null,
         selectedPortalId: null,
         formDataPortal,
         selectedTab: 'settings-devices',
         portalOptions: null,
         showHeaderSaveButton: true,
         locationNotConfigured: false,
         saveButtonLoading: false,
         isSaving: false,
         isLoading: false,
         isTeamChanging: false,
         license_end_at: null,
         dataPortalDetail: SingleResourceResponse.create(),
         dataDevices: PaginationResponse.create().map(Device),
         dataLocations: PaginationResponse.create().map(Location),
         dataPortals: PaginationResponse.create().map(Portal),
         timer: null,
         previewUrl: null,
         tabItems: [
            /*{
               id: 'settings-location',
               label: 'Bilgiler',
            },*/
            {
               id: 'settings-devices',
               label: 'Cihazlar',
            },
            {
               id: 'portal-edit',
               label: 'Portal Ayarları',
            },
            {
               id: 'providers-edit',
               label: 'Giriş Metodları',
            },
            {
               id: 'settings-license',
               label: 'Lisans Ayarları',
            },
            /*{
               id: 'settings-packages',
               label: 'Paketler & Entegrasyonlar',
            },*/
         ],
         quedraTabItems: [
            /*{
               id: 'settings-location',
               label: 'Bilgiler',
            },*/
            {
               id: 'settings-devices',
               label: 'Cihazlar',
            },
            {
               id: 'portal-edit',
               label: 'Portal Ayarları',
            },
            {
               id: 'providers-edit',
               label: 'Giriş Metodları',
            },
            /*{
               id: 'settings-packages',
               label: 'Paketler & Entegrasyonlar',
            },*/
         ],
         portalProviderOptions: null,
         firstLoadFinished: false,
         isLoadingLocations: true,
         frameLoading: false,
         isLoadingVisitsStatistics: false,
         isLoadingStatisticsOverview: false,
         isLoadingBrowsersStatistics: false,
         isLoadingPlatformsStatistics: false,
         isLoadingDevicesStatistics: false,
         isLoadingFailedLogin: false,
         isLoadingOnlineUser: false,
         dataVisitorBandwidth: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         locationName: null,
         licenseType: 'all',
         licenseTypes: [
            {
               label: 'Tümü',
               id: 'all',
            },
            {
               label: 'Demo',
               id: 'in_demo',
            },
            {
               label: 'Bitenler',
               id: 'suspended',
            },
            {
               label: 'Aktif',
               id: 'active',
            },
         ],
      };
   },

   async mounted() {
      await this.refreshData();
   },

   beforeDestroy() {
      clearInterval(this.timer);
   },

   methods: {
      trFormatDate,
      async refreshData() {
         this.loadLocations();
      },

      async locationClicked(location) {
         const lObj = location.toObject();
         var d = new Date(lObj.license_end_at !== null ? lObj.license_end_at : lObj.created_at);
         var year = d.getFullYear();
         var month = d.getMonth();
         var day = d.getDate();
         var c = new Date(year + 1, month, day);

         this.license_end_at = formatDate(c, 'YYYY-MM-DD');
         this.selectedLocation = location;
         this.changeTeam(location.get('id'));
      },
      async changeTeam(teamId) {
         this.isLoading = true;
         try {
            await AuthApi.changeTeam(teamId);
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.loadPortalDetail();
         }
      },

      async onContentChanged(value) {
         this.formDataPortal.merge({ messages: value });
         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'message_changed',
               value: value,
            },
            '*'
         );
      },

      async onStyleChanged(value) {
         this.formDataPortal.merge({ styles: value });
         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'style_changed',
               value: value,
            },
            '*'
         );
      },

      async loadPortalDetail() {
         this.isLoading = true;

         try {
            this.selectedPortalId = this.selectedLocation.get('portal_id');
            if (this.selectedPortalId === null || this.selectedPortalId === undefined) {
               this.locationNotConfigured = true;
               return;
            }
            this.locationNotConfigured = false;
            this.frameLoading = true;
            this.previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/preview/${
               this.selectedPortalId
            }?tenant=${getSubdomain()}`;
            const { data } = await PanelPortalApi.show(this.selectedPortalId);
            this.dataPortalDetail.merge(data);

            this.formDataPortal.merge(this.dataPortalDetail.only('name'));
            this.formDataRedirectRules = this.dataPortalDetail.get('redirect_rules', []);
            this.formDataSurveys = this.dataPortalDetail.get('surveys', []);
            this.agreements = this.dataPortalDetail.get('agreements', {});

            const dataStyleOptions = this.dataPortalDetail.get('style_options', []);

            const styles = {};
            each(dataStyleOptions, (item) => (styles[item.key] = item.value));

            this.portalOptions = {
               styles: styles,
               messages: this.dataPortalDetail.get('messages', {}),
            };

            this.portalProviderOptions = {
               providers: this.dataPortalDetail.get('providers', []),
               messages: this.dataPortalDetail.get('messages', {}),
            };
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async submitPortalPut() {
         if (!this.formDataPortal.isValid()) return;

         this.saveButtonLoading = true;
         try {
            await PanelPortalApi.update(this.selectedPortalId, this.formDataPortal.toObject());
            await this.loadPortalDetail();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.saveButtonLoading = false;
         }
      },
      async loadLocations() {
         try {
            this.selectedLocation = null;
            this.isLoadingLocations = true;
            this.dataLocations.merge(
               await PanelLocationApi.index({ per_page: 9999, license: this.licenseType, name: this.locationName })
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingLocations = false;
         }
      },
      async onProviderChanged(value) {
         const providers = value.filter((value) => value.checked);
         this.formDataPortal.merge({ providers: providers });

         this.$refs.previewFrame.contentWindow.postMessage(
            {
               command: 'provider_changed',
               value: providers,
            },
            '*'
         );
      },
      async frameIsLoaded() {
         this.frameLoading = false;
      },
      async applyLicense() {
         this.isLoading = true;
         try {
            const response = await PanelLocationApi.applyLicense(this.selectedLocation.get('id'), {
               license_end_at: this.license_end_at,
            });
            this.selectedLocation.merge(response.data);
            this.license_end_at = null;
            Notify.success(this.$t('Müşteri lisanslandı'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      getSubdomain,
   },
};
</script>

<style lang="scss" scoped>
.obi-content {
   padding: 1rem;
   background-color: #ffffff;
}

.obi-page {
   overflow: hidden;
}

.stat-item-wrap {
   border-radius: 10px;

   .stat-item {
      gap: 12px;
      display: flex;
      padding-top: 30px;
      align-items: center;
      padding-bottom: 30px;
      flex-direction: column;

      &:hover {
         color: #fff;
         background-color: #2f71f2;
      }

      .stat-item-metric {
         font-size: 24px;
      }
   }
}
</style>
