<template>
   <ObiText class="obi-eg">
      <ObiText class="obi-eg-body">
         <ObiLoading v-if="isLoading" />
         <ObiText v-else>
            <ObiAdCard
               v-for="(ad, index) in dataAds"
               :key="index"
               :index="index"
               :item="ad"
               :opened="editingItem === index"
               :portal-id="portalId"
               @changed="onChanged"
               @delete="deleteItem"
               @edit="startEdit"
               class="mt-2"
            />

            <Obi404 v-if="!dataAds || dataAds.length === 0" :title="$t('ads_not_found')" desc="">
               <template #actions>
                  <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="addNew" />
               </template>
            </Obi404>
            <ObiText v-else class="text-center mt-4">
               <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="addNew" />
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import ObiAdCard from '@/components/Common/ObiAdCard';

import ErrorHandler from '@/libs/error-handler';
import PanelPortalApi from '@/api/PanelPortalApi';

export default {
   name: 'PortalAdvertisements',
   components: {
      ObiAdCard,
   },
   i18n: {
      messages: {
         tr: {
            ads_not_found: 'Henüz kayıtlı bir reklamınız yok :(',
            labels: {},
            actions: {
               create: 'Yeni Ekle',
            },
         },
         en: {
            ads_not_found: "You don't have any registered ads yet :(",
            labels: {},
            actions: {
               create: 'Add New',
            },
         },
      },
   },

   props: {
      portalId: {
         type: [String, Number],
         required: true,
      },
   },

   data() {
      return {
         dataAds: [],
         isLoading: false,
         selectedItem: null,
         editingItem: null,
      };
   },

   mounted() {
      this.getAds();
   },

   methods: {
      async getAds() {
         try {
            this.isLoading = true;

            const { data } = await PanelPortalApi.ads.index(this.portalId, null);
            this.dataAds = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      addNew() {
         this.dataAds.push({});
      },
      async deleteItem() {},
      async onChanged() {
         await this.getAds();
      },
      async startEdit(index) {
         if (this.editingItem === index) {
            this.editingItem = null;
            return;
         }
         this.editingItem = index;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: block;
   margin-left: -1.5rem;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }

   .btn-new {
      height: 80px;
   }
}
</style>
