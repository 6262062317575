<template>
   <ObiText class="page-register">
      <ObiImage class="tenant-logo" :src="tenant.login.image.src" />
      <ObiText as="h1" class="register-title" v-html="$t('forgot_password.page_title')" />
      <ObiText class="register-description" v-html="$t('forgot_password.page_description')" />

      <ObiForm @submit.prevent="submitCode" v-if="step === 'send-code'">
         <ObiText class="my-4">
            <ObiFloatingInput
               type="email"
               :icon="IconMail"
               :disabled="isLoading"
               name="email"
               v-model="formDataRegister.email"
               :label="$t('forgot_password.fields.email')"
               :placeholder="$t('forgot_password.fields.email')"
               :errors="formDataRegister.errors.get('email')"
            />
         </ObiText>

         <ObiButton
            block
            icon-right
            type="submit"
            :loading="isLoading"
            icon="mdi mdi-arrow-right"
            :text="$t('forgot_password.form.send_code')"
         />

         <router-link
            class="btn btn-link mt-4"
            v-html="$t('forgot_password.form.login_button_label')"
            :to="{ name: 'auth-login' }"
         />
      </ObiForm>

      <ObiForm @submit.prevent="resetPassword" v-else>
         <ObiText class="my-4">
            <ObiFloatingInput
               :disabled="isLoading"
               name="phone"
               v-model="formDataRegister.code"
               :label="$t('forgot_password.fields.code')"
               :placeholder="$t('forgot_password.fields.code')"
               :errors="formDataRegister.errors.get('code')"
            />

            <ObiFloatingInput
               type="password"
               name="password"
               :icon="IconPassword"
               :disabled="isLoading"
               v-model="formDataRegister.password"
               :label="$t('forgot_password.fields.password')"
               :placeholder="$t('forgot_password.fields.password')"
               :errors="formDataRegister.errors.get('password')"
            />

            <ObiFloatingInput
               type="password"
               name="password_confirmation"
               :icon="IconPassword"
               :disabled="isLoading"
               v-model="formDataRegister.password_confirmation"
               :label="$t('forgot_password.fields.password_confirmation')"
               :placeholder="$t('forgot_password.fields.password_confirmation')"
               :errors="formDataRegister.errors.get('password_confirmation')"
            />
         </ObiText>

         <ObiButton
            block
            icon-right
            type="submit"
            :loading="isLoading"
            icon="mdi mdi-arrow-right"
            :text="$t('forgot_password.form.reset_password')"
         />

         <router-link
            class="btn btn-link mt-4"
            v-html="$t('forgot_password.form.login_button_label')"
            :to="{ name: 'auth-login' }"
         />
      </ObiForm>
   </ObiText>
</template>

<script>
import IconUser from '@/components/Icons/form/IconUser';
import IconMail from '@/components/Icons/form/IconMail';
import IconPhone2 from '@/components/Icons/form/IconPhone2';
import IconGlobe from '@/components/Icons/form/IconGlobe';
import IconCompany from '@/components/Icons/form/IconCompany';
import IconPassword from '@/components/Icons/form/IconPassword';
import AuthApi from '@/api/AuthApi';
import Notify from '@/libs/notify';
import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'AuthRegister',
   components: {},
   data() {
      return {
         IconUser,
         IconMail,
         IconPhone2,
         IconGlobe,
         IconCompany,
         IconPassword,
         isLoading: false,
         step: 'send-code',
         formDataRegister: Form.create(['email', 'password', 'password_confirmation', 'code']).validate({
            email: 'required',
         }),
      };
   },
   methods: {
      async submitCode() {
         if (!this.formDataRegister.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            await AuthApi.forgot(this.formDataRegister.toObject());

            Notify.success(this.$t('Sıfırlama kodunuz gönderildi.'));
            this.step = 'reset-password';
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async resetPassword() {
         if (!this.formDataRegister.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            await AuthApi.reset(this.formDataRegister.toObject());

            Notify.success(this.$t('Şifreniz sıfırlandı lütfen giriş yapın.'));
            await this.$router.push({ name: 'auth-complete' });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.page-register {
   text-align: center;

   .obi-form {
      margin: 0 auto;
      max-width: 400px;
   }

   .register-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .register-description {
      color: #777d96;
      font-size: 13px;
   }

   .form-register {
      margin-top: 30px;
   }
}
</style>
