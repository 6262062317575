import Http from '@/libs/http';

class FirewallFilterList extends Http {
   apps(params) {
      return super.get('cloudfirewall/filter_list/app_website_game', { params });
   }

   categories(params) {
      return super.get('cloudfirewall/filter_list/categories', { params });
   }

   filterList(params) {
      return super.get('cloudfirewall/firewall_profile/filter_list', { params });
   }

   add(params) {
      return super.post('cloudfirewall/firewall_profile/filter_list', params);
   }

   remove(hid) {
      return super.delete('cloudfirewall/firewall_profile/filter_list/' + hid);
   }
}

export default new FirewallFilterList();
