<template>
   <ObiRow justify-center>
      <ObiCol col="12" sm="10">
         <ObiRow justify-between align-start class="mb-3">
            <ObiCol col="12">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.enter_user_info')" />
               <ObiRow>
                  <ObiCol col="12" lg="6">
                     <ObiFloatingInput
                        class="m-2"
                        :label="$t('fields.first_name')"
                        v-model="formDataAccount.first_name"
                        :errors="formDataAccount.errors.get('first_name')"
                     />
                  </ObiCol>
                  <ObiCol col="12" lg="6">
                     <ObiFloatingInput
                        class="m-2"
                        :label="$t('fields.last_name')"
                        v-model="formDataAccount.last_name"
                        :errors="formDataAccount.errors.get('last_name')"
                     />
                  </ObiCol>
               </ObiRow>
            </ObiCol>
         </ObiRow>
         <ObiRow justify-between align-end class="mb-3">
            <ObiCol col="12">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.mail_and_phone')" />
               <ObiRow>
                  <ObiCol col="12" lg="6">
                     <ObiFloatingInput
                        class="m-2"
                        :label="$t('fields.email')"
                        v-model="formDataAccount.email"
                        :errors="formDataAccount.errors.get('email')"
                     />
                  </ObiCol>
                  <!-- <ObiCol col="12" lg="6">
                     <ObiFloatingInput
                        class="m-2"
                        :label="$t('fields.country_phone_code')"
                        type="tel"
                        v-model="formDataAccount.country_phone_code"
                        :errors="formDataAccount.errors.get('country_phone_code')"
                     />
                  </ObiCol> -->
                  <ObiCol col="12" lg="6">
                     <ObiFloatingInput
                        class="m-2"
                        :label="$t('fields.phone_number')"
                        type="tel"
                        v-model="formDataAccount.phone_number"
                        :errors="formDataAccount.errors.get('phone_number')"
                     />
                  </ObiCol>
               </ObiRow>
            </ObiCol>
         </ObiRow>
      </ObiCol>
   </ObiRow>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/libs/form';

export default {
   i18n: {
      messages: {
         tr: {
            fields: {
               enter_user_info: 'Kullanıcı Bilgilerini Giriniz',
               mail_and_phone: 'Mail ve Numara',
               first_name: 'Adı',
               last_name: 'Soyadı',
               email: 'Mail Adresi',
               phone_number: 'Telefon',
            },
         },
         en: {
            fields: {
               enter_user_info: 'Enter User Information',
               mail_and_phone: 'Email and Phone',
               first_name: 'First Name',
               last_name: 'Last Name',
               email: 'Email Address',
               phone_number: 'Phone Number',
            },
         },
      },
   },
   props: {
      formDataAccount: {
         type: Form,
      },
   },
   mounted() {
      console.log(this.user);
   },
   computed: {
      ...mapGetters({
         user: 'auth/authUser',
      }),
   },
};
</script>
