import BaseResponse from '@/api/DTO/BaseResponse';

class PaginationResponse extends BaseResponse {
   reset() {
      this.set({
         data: [],
         links: {
            first: null,
            last: null,
            next: null,
            prev: null,
         },
         meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: null,
            per_page: null,
            to: 1,
            total: 0,
         },
      });

      return this;
   }

   getMapPath() {
      return 'data';
   }
}

export default PaginationResponse;
