<template>
   <div class="obi-auth">
      <div class="obi-router-view">
         <router-view />
      </div>
   </div>
</template>
<script>
export default {
   name: 'ObiAuth',
   computed: {
      backgroundColor() {
         return this.tenant.landing.background;
      },
   },
   data() {
      return {
         lang: 'tr',
      };
   },
   methods: {},
};
</script>

<style lang="scss" scoped>
.obi-auth {
   height: 100%;
   grid-template-columns: 800px 1fr;

   @media (max-width: 1200px) {
      display: block;
   }

   .obi-router-view {
      height: 100%;
      display: grid;
      overflow-x: hidden;
      align-items: center;
   }

   .obi-landing {
      color: #fff;
      height: 100%;
      overflow-x: hidden;
      padding: 180px 120px 0 120px;

      @media (max-width: 1200px) {
         display: none;
      }

      .obi-landing--top {
         width: 350px;
         margin: 0 auto;
      }

      .obi-landing--bottom {
         margin-top: 25px;
      }

      .obi-landing-logo {
         margin-bottom: 2rem;
         max-width: 200px;
      }

      .obi-landing-title {
         font-size: 20px;
         font-weight: 600;
         margin-bottom: 1rem;
      }

      .obi-landing-description {
         color: #ccc;
         font-size: 15px;
      }

      .obi-landing-image {
         width: 500px;
      }

      .obi-landing-copyright {
         margin: 50px 0 10px;
         font-size: 10px;
         text-align: center;
      }
   }
}
</style>
