<template>
   <ObiText class="obi-eg">
      <ObiText class="obi-eg-nav">
         <ObiNav
            class="--for-obi-eg"
            vertical
            pills
            :items="navItems"
            v-model="selectedItem"
            @orderChanged="orderChanged"
         />
      </ObiText>
      <ObiText class="obi-eg-body">
         <ObiText v-if="selectedItem === 'selling'">
            <ObiTitle class="mb-2" :title="$t('labels.selling')" />
            <ObiText class="mb-5" v-html="$t('labels.selling_description')" />

            <ObiTitle class="mt-2" title="Bilgilendirme Mesajı" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="paid_information" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" title="Satın Al Mesajı" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="select_paid" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" title="Günlük" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="daily" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" title="Toplam Gün" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="selected_paid_day" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" title="Ödenecek Tutar" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="total_paid_amount" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" title="Satın Al" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="buy" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" title="Değiştir" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="change_paid" @change="contentChanged" />
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'kvkk'">
            <ObiTitle class="mb-2" title="KVKK/GDPR Sözleşmesi" />
            <ObiText class="mb-5">Sözleşme içeriğinizi her dil için ayrı ayrı girebilirsiniz</ObiText>

            <ObiTitle class="mt-2" title="Sözleşme İçeriği" />
            <ObiText class="mt-2">
               <ObiTabs :items="languages" v-model="selectedLanguage" />
               <vue-editor v-model="agreementForm.kvkk[selectedLanguage]" @text-change="agreementChanged"></vue-editor>
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'general_usage'">
            <ObiTitle class="mb-2" title="Kullanım Sözleşmesi" />
            <ObiText class="mb-5">Sözleşme içeriğinizi her dil için ayrı ayrı girebilirsiniz</ObiText>

            <ObiTitle class="mt-2" title="Sözleşme İçeriği" />
            <ObiText class="mt-2">
               <ObiTabs :items="languages" v-model="selectedLanguage" />
               <vue-editor
                  v-model="agreementForm.general[selectedLanguage]"
                  @text-change="agreementChanged"
               ></vue-editor>
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
   name: 'ObiPortalAdvanced',
   components: {
      VueEditor,
   },
   i18n: {
      messages: {
         tr: {
            labels: {
               selling: 'Ücretli Satış Ayarları',
               selling_description:
                  'Satış ekranlarınızı özelleştirin. Satış yapmak için Network tanımlarından Satış seçeneğini aktif edin',
            },
         },
         en: {
            labels: {
               selling: 'Paid Sales Settings',
               selling_description:
                  'Customize your sales screens. Activate the Sales option from Network definitions to make sales.',
            },
         },
      },
   },

   props: {
      portalId: {
         type: [String, Number],
         required: true,
      },
      options: {
         type: Object,
         required: true,
      },
      agreements: {
         type: Object,
         required: false,
      },
   },

   computed: {
      uploadURL() {
         return `/panel/portal/${this.portalId}/image`;
      },
      selectedProvider() {
         const result = this.navItems.filter((item) => item.id === this.selectedItem);
         if (result) {
            return result[0];
         }
         return null;
      },
   },

   data() {
      return {
         selectedItem: 'kvkk',
         selectedLanguage: 'tr',
         languages: [
            { id: 'tr', label: 'Türkçe' },
            { id: 'en', label: 'İngilizce' },
            { id: 'de', label: 'Almanca' },
            { id: 'ru', label: 'Rusça' },
            { id: 'ar', label: 'Arapça' },
         ],
         agreementForm: {
            kvkk: {},
            general: {},
         },
         navItems: [
            {
               id: 'kvkk',
               label: 'KVKK/GDPR Sözleşmesi',
               checked: false,
               options: {},
            },
            {
               id: 'selling',
               label: 'Ücretli Satış',
               checked: false,
               options: {},
            },
            {
               id: 'general_usage',
               label: 'Kullanım Sözleşmesi',
               checked: false,
               options: {},
            },
         ],
         messages: {
            paid_information: {},
            select_paid: {},
            daily: {},
            selected_paid_day: {},
            total_paid_amount: {},
            buy: {},
            change_paid: {},
         },
      };
   },

   async mounted() {
      this.messages = this.options.messages || {};
      this.agreementForm = this.agreements !== null ? this.agreements : { kvkk: {}, general: {} };

      if (this.options.providers && this.options.providers.length > 0) {
         this.options.providers.forEach((provider) => {
            this.navItems
               .filter((item) => item.id === provider.slug)
               .map((item) => {
                  return (item.checked = true) & (item.order = provider.order);
               });
         });

         this.options.providers.forEach((provider) => {
            this.navItems
               .filter((item) => item.id === provider.slug)
               .map((item) => {
                  item.options = provider.options;
               });
         });
      }
   },

   methods: {
      async contentChanged(value) {
         this.$emit('contentChanged', value);
      },
      async agreementChanged(value, html) {
         console.log(html);
         this.$emit('agreementChanged', this.agreementForm);
      },
      async orderChanged(value) {
         this.navItems = value;
         this.$emit('providerChanged', this.navItems);
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: grid;
   margin-left: -1.5rem;
   grid-template-columns: 15rem 1fr;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }
}
</style>
