import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

import Collection from '@/libs/collection';

class BaseResponse extends Collection {
   map(model) {
      this._model = model;

      return this;
   }

   getMapPath() {
      return 'root';
   }

   merge(attributes = {}) {
      const mapPath = this.getMapPath();

      if (!this._model) {
         return super.merge(attributes);
      }

      const source = get(attributes, mapPath);

      if (isArray(source)) {
         set(
            attributes,
            mapPath,
            map(get(attributes, mapPath), (item) => new this._model(item))
         );
      } else {
         set(attributes, mapPath, new this._model(get(attributes, mapPath)));
      }

      return super.merge(attributes);
   }
}

export default BaseResponse;
