import Vue from 'vue';
import VueGoogleMaps from 'vue-googlemaps';
import 'vue-googlemaps/dist/vue-googlemaps.css';

const options = {
   load: {
      apiKey: 'AIzaSyBRjDrOH3Wzny-MaUOqDJCxpcCte4U3O7c',
      libraries: ['places'],
      useBetaRenderer: false,
   },
};

Vue.use(VueGoogleMaps, options);
