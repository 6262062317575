import BaseModel from './BaseModel';

export default class FirewallStatisticOverview extends BaseModel {
   getAllowedQueryCount() {
      return this.get('allowed_query', 0);
   }

   getBlockedQueryCount() {
      return this.get('blocked_query', 0);
   }

   getQueryByClientCount() {
      return this.get('query_by_client', 0);
   }

   getTotalQueryCount() {
      return this.get('total_query', 0);
   }
}
