import Http from '@/libs/http';

class FirewallMemberApi extends Http {
   index(params) {
      return super.get('cloudfirewall/members', { params });
   }

   store(payload, params) {
      return super.post('cloudfirewall/members', payload, { params });
   }

   show(id, params) {
      return super.get(`cloudfirewall/members/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`cloudfirewall/members/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`cloudfirewall/members/${id}`, { params });
   }
}

export default new FirewallMemberApi();
