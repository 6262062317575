// import Store from '@/store';
import Http from '../libs/http';

class TenantUserApi extends Http {
   index() {
      return super.get('panel/tenant/users');
   }

   store(payload) {
      return super.post('panel/tenant/users', payload);
   }

   update(id, payload) {
      return super.put(`panel/tenant/users/${id}`, payload);
   }

   delete(id) {
      return super.delete(`panel/tenant/users/${id}`);
   }
}

export default new TenantUserApi();
