// import { Http } from './Api';
//
// const resource = '/users';
export default {
   //    index(params) {
   //       return Repository.get(`${resource}`, { params });
   //    },
   //
   //    store(payload) {
   //       return Repository.post(`${resource}`, payload);
   //    },
   //
   //    show(userId, params) {
   //       return Repository.get(`${resource}/${userId}`, { params });
   //    },
   //
   //    update(userId, payload) {
   //       return Repository.put(`${resource}/${userId}`, payload);
   //    }
};
