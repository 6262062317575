import Vue from 'vue';
import { get, set, each, pick, reduce } from 'lodash';

class Collection {
   constructor(data) {
      this._data = new Vue({ data: () => data });

      this._defaults = {};

      this.reset();
   }

   static create(attributes = {}) {
      return new this(attributes || {});
   }

   defaults(attributes) {
      this._defaults = attributes;

      return this;
   }

   reset() {
      each(this._defaults, (value, key) => Vue.set(this._data, key, value));

      return this;
   }

   cleaned(isCleaned) {
      this._cleaned = isCleaned;

      return this;
   }

   get(key, defaultValue = null) {
      return get(this._data, key, defaultValue);
   }

   set(key, value = null) {
      if (typeof key === 'object') {
         this._data = key;
      } else {
         Vue.set(this._data, key, value);
      }

      return this;
   }

   merge(attributes = {}) {
      each(attributes, (value, key) => {
         Vue.set(this._data, key, value);
      });

      return this;
   }

   only(...keys) {
      return pick(this.toObject(), keys);
   }

   toObject() {
      const reducerFn = (acc, value, key) => {
         if (value && value.constructor && value.constructor.name === 'Portal' && value.toObject instanceof Function) {
            value = value.toObject();

            if (get(value, '_isVue')) {
               value = get(value, '_data');
            }
         }

         if (this._cleaned && !value) {
            return acc;
         }

         set(acc, key, value);

         return acc;
      };

      const obj = reduce(this._data, reducerFn, {});

      return obj.data ? obj.data : obj;
   }

   toJson(indent = 3) {
      return JSON.stringify(...[this.toObject(), null, indent]);
   }
}

export default Collection;
