// import Store from '@/store';
import Http from '../libs/http';
import TenantUserApi from './TenantUserApi';

class TenantApi extends Http {
   config() {
      return super.get('config');
   }

   get() {
      return super.get('panel/tenant');
   }

   post(payload) {
      return super.post('panel/tenant', payload);
   }

   get users() {
      return TenantUserApi;
   }
}

export default new TenantApi();
