import LayoutEmpty from '@/views/layouts/Empty';
import ConfirmEmail from '@/views/pages/Auth/ConfirmEmail';

export default {
   path: '/visitor/confirm-email',
   component: LayoutEmpty,
   children: [
      {
         path: ':token',
         name: 'confirm-email-accept',
         component: ConfirmEmail,
      },
   ],
};
