<template>
   <ObiText class="obi-eg">
      <ObiText class="obi-eg-nav">
         <ObiNav class="--for-obi-eg" vertical pills :items="navItems" v-model="selectedItem" />
      </ObiText>
      <ObiText class="obi-eg-body">
         <ObiText v-if="selectedItem === 'theme-select'">
            <ObiRow>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.primary_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.primary_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.secondary_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.secondary_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.form_background_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.form_background_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.alert_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.alert_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.warning_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.warning_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.info_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.info_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.success_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.success_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="4">
                  <ObiTitle class="mb-2" :title="$t('labels.border_color')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.border_color" />
                  </ObiText>
               </ObiCol>
               <ObiCol sm="6">
                  <ObiTitle class="mb-2" :title="$t('labels.provider_layout_type')" />
                  <ObiButtonSwitch :items="providerLayoutOptions" v-model="formDataGeneral.styles.provider_layout" />
               </ObiCol>
            </ObiRow>
         </ObiText>
         <ObiText v-if="selectedItem === 'logo'">
            <ObiTitle class="mb-1" :title="$t('labels.logo')" />
            <ObiText class="mb-4" v-html="$t('labels.logo_description')" />

            <ObiText class="mb-5">
               <ObiButtonSwitch :items="dataLogoTypes" v-model="formDataGeneral.styles.logo_type" />
            </ObiText>
            <ObiText class="mb-2">
               <ObiFileUpload :url="uploadURL" collection="logo" v-model="formDataGeneral.styles.portal_logo" />
            </ObiText>

            <ObiTitle class="mb-1" :title="$t('labels.logo_resize')" />
            <ObiText class="mb-4">
               <RangeSlider
                  class="obi-slider"
                  min="10"
                  max="100"
                  step="5"
                  v-model="formDataGeneral.styles.logo_scale"
               />
            </ObiText>

            <ObiTitle :title="$t('labels.background_color')" />
            <ObiText>
               <ObiColorPicker v-model="formDataGeneral.styles.logo_background_color" />
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'background'">
            <ObiTitle class="mb-2" :title="$t('labels.background_appearance')" />
            <ObiText class="mb-5" v-html="$t('labels.background_appearance_description')" />
            <ObiTitle class="mb-2" :title="$t('labels.background_type')" />
            <ObiText class="mb-5">
               <ObiButtonSwitch :items="dataBackgroundTypes" v-model="formDataGeneral.styles.background_type" />
            </ObiText>
            <ObiText v-if="['image', 'color_image'].includes(formDataGeneral.styles.background_type)">
               <ObiTitle class="mb-2" :title="$t('labels.background_image')" />
               <ObiText class="mb-5">
                  <ObiFileUpload
                     :url="uploadURL"
                     collection="background"
                     v-model="formDataGeneral.styles.background_image"
                  />
               </ObiText>
            </ObiText>
            <ObiText v-if="['color', 'color_image'].includes(formDataGeneral.styles.background_type)">
               <ObiText v-if="formDataGeneral.styles.color_type === 'solid'">
                  <ObiTitle class="mb-2" :title="$t('labels.color_select_solid')" />
                  <ObiText class="mb-5">
                     <ObiColorPicker v-model="formDataGeneral.styles.background_color" />
                  </ObiText>
               </ObiText>
               <ObiText v-if="formDataGeneral.styles.color_type === 'gradient'">
                  <ObiTitle class="mb-2" :title="$t('labels.color_select_gradient')" color="black-50" />
                  <ObiText class="mb-5">
                     <ObiRow>
                        <ObiCol sm="3">
                           <ObiColorPicker v-model="formDataGeneral.styles.background_color" />
                        </ObiCol>

                        <ObiCol sm="3">
                           <ObiColorPicker v-model="formDataGeneral.styles.background_color" />
                        </ObiCol>
                     </ObiRow>
                  </ObiText>
               </ObiText>
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'content'">
            <ObiTitle class="mb-2" :title="$t('labels.content_appearance')" />
            <ObiText class="mb-5" v-html="$t('labels.content_appearance_description')" />

            <ObiTitle class="mb-1" :title="$t('labels.content_welcome')" />
            <ObiText class="mb-4">
               <MultiLanguageInput
                  v-model="formDataGeneral.messages"
                  name="welcome_message_title"
                  @change="contentChanged"
               />

               <ObiText class="mb-5">
                  <ObiRow>
                     <ObiCol sm="4">
                        <ObiTitle class="mb-2" :title="$t('labels.select_color')" color="black-50" />
                        <ObiText class="mb-5">
                           <ObiColorPicker v-model="formDataGeneral.styles.welcome_message_text_color" />
                        </ObiText>
                     </ObiCol>
                     <ObiCol sm="8">
                        <ObiTitle class="mb-2" :title="$t('labels.font_size')" color="black-50" />
                        <ObiText class="mb-4">
                           <RangeSlider
                              class="obi-slider"
                              min="14"
                              max="40"
                              step="1"
                              v-model="formDataGeneral.styles.welcome_font_size"
                           />
                        </ObiText>
                     </ObiCol>
                  </ObiRow>
               </ObiText>
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
   name: 'ObiPortalGeneralTab',
   components: {
      RangeSlider,
   },
   i18n: {
      messages: {
         tr: {
            labels: {
               logo: 'Logo',
               logo_description: 'Portal için logonuzu özelleştirin. Tavsiye edilen boyut 600x150 pikseldir.',
               logo_resize: 'Boyutlandırın',
               color_scheme: 'Renk Şeması',
               background_appearance: 'Arkaplan Özellikleri',
               background_appearance_description: 'Portalınızın arkaplan rengini, resmini ayarlayın',
               background_type: 'Arkaplan Türü',
               background_image: 'Resim Seçiniz',
               background_color: 'Arkaplan Rengi Seçiniz',
               color_select_solid: 'Renk Seçiniz',
               select_color: 'Renk Seçiniz',
               color_select_gradient: 'Gradient Oluşturun',
               primary_color: 'Birincil Renk',
               secondary_color: 'İkincil Renk',
               content_appearance: 'İçerik Özelleştirme',
               content_appearance_description: 'Portalınızda bulunan mesajları özelleştirin!',
               content_welcome: 'Hoşgeldin Mesajı',
               font_size: 'Yazı Boyutu',
               form_background_color: 'Form Arkaplan Rengi',
               alert_color: 'Hata Rengi',
               warning_color: 'Uyarı Rengi',
               info_color: 'Bilgi Rengi',
               success_color: 'Başarı Rengi',
               border_color: 'Çerçeve Rengi',
               provider_layout_type: 'Giriş Metodları Düzeni',
            },
            tabs: {
               customize: 'Özelleştirme',
               logo: 'Logo',
               background_settings: 'Arkaplan',
               content_customize: 'İçerik',
            },
            Renk: 'Renk',
            Göster: 'Göster',
            Gösterme: 'Gösterme',
            Resim: 'Resim',
            Scroll: 'Kaydırma',
         },
         en: {
            labels: {
               logo: 'Logo',
               logo_description: 'Customize your logo for the portal. Recommended size is 600x150 pixels.',
               logo_resize: 'Resize',
               color_scheme: 'Color Scheme',
               background_appearance: 'Background Appearance',
               background_appearance_description: 'Set the background color or image for your portal.',
               background_type: 'Background Type',
               background_image: 'Select Image',
               background_color: 'Select Background Color',
               color_select_solid: 'Select Color',
               select_color: 'Select Color',
               color_select_gradient: 'Create Gradient',
               primary_color: 'Primary Color',
               secondary_color: 'Secondary Color',
               content_appearance: 'Customize Content',
               content_appearance_description: 'Customize messages on your portal!',
               content_welcome: 'Welcome Message',
               font_size: 'Font Size',
               form_background_color: 'Form Background Color',
               alert_color: 'Error Color',
               warning_color: 'Warning Color',
               info_color: 'Information Color',
               success_color: 'Success Color',
               border_color: 'Border Color',
               provider_layout_type: 'Login Methods Layout',
            },
            tabs: {
               customize: 'Customize',
               logo: 'Logo',
               background_settings: 'Background',
               content_customize: 'Content',
            },
            Renk: 'Color',
            Göster: 'Show',
            Gösterme: 'Hide',
            Resim: 'Image',
            Scroll: 'Scroll',
         },
      },
   },

   props: {
      portalId: {
         required: true,
      },
      options: {
         type: Object,
         required: true,
      },
   },

   beforeMount() {
      this.formDataGeneral = this.$props.options;
   },

   computed: {
      uploadURL() {
         return `/panel/portal/${this.portalId}/image`;
      },
   },

   data() {
      return {
         formDataGeneral: {},
         selectedItem: 'theme-select',
         navItems: [
            {
               id: 'theme-select',
               label: this.$t('tabs.customize'),
            },
            {
               id: 'logo',
               label: this.$t('tabs.logo'),
            },
            {
               id: 'background',
               label: this.$t('tabs.background_settings'),
            },
            {
               id: 'content',
               label: this.$t('tabs.content_customize'),
            },
         ],
         dataColorSchemes: [
            { id: 'light', label: this.$t('Aydınlık Tema') },
            { id: 'dark', label: this.$t('Koyu Tema') },
         ],
         dataBackgroundTypes: [
            { id: 'color', label: this.$t('Renk') },
            { id: 'image', label: this.$t('Resim') },
         ],
         dataLogoTypes: [
            { id: 'active', label: this.$t('Göster') },
            { id: 'passive', label: this.$t('Gösterme') },
         ],
         dataColorTypes: [
            { id: 'solid', label: this.$t('Tek Renk') },
            { id: 'gradient', label: this.$t('Gradient') },
         ],
         providerLayoutOptions: [
            { id: 'dropdown', label: 'Dropdown' },
            { id: 'scroll', label: this.$t('Scroll') },
         ],
      };
   },

   methods: {
      contentChanged() {
         this.$emit('contentChanged', this.formDataGeneral.messages);
      },
   },

   watch: {
      'formDataGeneral.styles': {
         deep: true,
         handler(newVal) {
            this.$emit('portalStyleChanged', newVal);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: grid;
   margin-left: -1.5rem;
   grid-template-columns: 15rem 1fr;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }
}
.obi-slider {
   width: 100%;
}
</style>
