<template>
   <ObiCard :hideBody="!isEditing">
      <template #header>
         <ObiTitle :icon="RuleCardIcon" :title="title">
            <template #actions>
               <ObiCol class="d-flex">
                  <ObiButton compact size="lg" color="lighter" :icon="EditIcon" @click="$emit('edit', index)" />
                  <v-popover>
                     <ObiButton class="ms-2" size="lg" compact color="lighter" :icon="DeleteIcon" />

                     <template slot="popover">
                        <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                        <ObiButton
                           class="ms-2"
                           size="sm"
                           color="danger"
                           :text="$t('actions.yes')"
                           v-close-popover
                           @click="deleteItem"
                        />
                        <ObiButton class="ms-2" size="sm" color="warning" :text="$t('actions.no')" v-close-popover />
                     </template>
                  </v-popover>
               </ObiCol>
            </template>
         </ObiTitle>
      </template>
      <template #body class="p-4">
         <ObiRow class="p-4">
            <ObiCol col="12">
               <MultiLanguageInput reverse v-model="formDataQuestion" name="label" @input="onChanged" />
            </ObiCol>
            <ObiCol col="4">
               <ObiTitle class="mb-2" :title="$t('labels.answer_type')" />
               <ObiText class="mb-5">
                  <ObiButtonSwitch
                     :items="answerTypeOptions"
                     v-model="formDataQuestion.answer_type"
                     @input="onChanged"
                  />
               </ObiText>
            </ObiCol>
            <ObiCol col="6" v-if="false">
               <ObiTitle class="mb-2" :title="$t('labels.answer_type')" />
               <ObiText class="mb-5">
                  <ObiButtonSwitch :items="typeOptions" v-model="formDataQuestion.type" @input="onChanged" />
               </ObiText>
            </ObiCol>
            <ObiText v-if="formDataQuestion.answer_type !== 'text'">
               <ObiText class="d-flex justify-content-between">
                  <ObiTitle class="mb-2" :title="$t('labels.answers')" />
                  <ObiButton size="lg" outline wider :text="$t('actions.add_answer')" @click="addNewAnswer" />
               </ObiText>
               <ObiSurveyQuestionAnswerCard
                  v-for="(answer, index) in formDataQuestion.answers"
                  :key="index"
                  :index="index"
                  :is-editing="editingItem === index"
                  class="mt-2"
                  :answer="answer"
                  :type="formDataQuestion.answer_type"
                  @edit="startEdit"
                  @change="onChanged"
                  @delete="deleteAnswer"
               />
            </ObiText>
         </ObiRow>
      </template>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';

import RuleCardIcon from '@/components/Icons/portals/RuleCardIcon';
import EditIcon from '@/components/Icons/portals/EditIcon.svg';
import DeleteIcon from '@/components/Icons/portals/DeleteIcon.svg';
import IconExternalLink from '@/components/Icons/general/IconExternalLink.svg';

import ObiSurveyQuestionAnswerCard from '@/components/UI/ObiSurveyQuestionAnswerCard';

export default {
   name: 'ObiSurveyQuestionCard',
   components: {
      ObiSurveyQuestionAnswerCard,
   },
   i18n: {
      messages: {
         tr: {
            last_seen: 'Son Görülme',
            are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
            labels: {
               answer_type: 'Cevap Türü',
               answers: 'Cevaplar',
            },
            fields: {
               name: 'Soru',
               state: 'Durum',
               action: 'Aksiyon',
               survey: 'Anket',
               target_url: 'Hedef Adres',
            },
            actions: {
               yes: 'Evet',
               no: 'Hayır',
               add_answer: 'Cevap Ekle',
            },
         },
         en: {
            last_seen: 'Last Seen',
            are_you_sure_delete: 'Are you sure you want to delete?',
            labels: {
               answer_type: 'Answer Type',
               answers: 'Answers',
            },
            fields: {
               name: 'Question',
               state: 'Status',
               action: 'Action',
               survey: 'Survey',
               target_url: 'Target URL',
            },
            actions: {
               yes: 'Yes',
               no: 'No',
               add_answer: 'Add Answer',
            },
         },
      },
   },
   props: {
      question: {
         type: Object,
      },
      index: {
         type: Number,
      },
      isEditing: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      title() {
         return this.$props.question && this.$props.question.label && this.$props.question.label.tr
            ? this.$props.question.label.tr
            : 'Yeni Soru';
      },
   },
   mounted() {
      if (this.$props.question !== null) {
         this.formDataQuestion = this.$props.question;
      }
   },
   data() {
      return {
         IconExternalLink,
         RuleCardIcon,
         EditIcon,
         DeleteIcon,
         editingItem: null,
         formDataQuestion: {
            label: { tr: null, en: null, de: null, ru: null, ar: null },
            answers: [],
            answer_type: 'text',
            type: 'single',
         },
         answerTypeOptions: [
            {
               label: 'Çoktan Seçmeli',
               id: 'emoji',
            },
            {
               label: 'Yazı',
               id: 'text',
            },
         ],
         typeOptions: [
            {
               label: 'Tekil Seçim',
               id: 'single',
            },
            {
               label: 'Çoklu Seçim',
               id: 'multiple',
            },
         ],
      };
   },
   methods: {
      async onChanged() {
         this.$emit('change', this.formDataQuestion);
      },
      async addNewAnswer() {
         this.formDataQuestion.answers.push({ label: {}, emoji: null });
         this.editingItem = this.formDataQuestion.answers.length - 1;
         this.onChanged();
      },
      async deleteItem() {
         this.$emit('delete', this.formDataQuestion);
      },
      async deleteAnswer(answer) {
         this.formDataQuestion.answers.shift(answer);
         this.$emit('change', this.formDataQuestion);
      },
      async startEdit(index) {
         if (this.editingItem === index) {
            this.editingItem = null;
            return;
         }
         this.editingItem = index;
      },
   },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-dcm {
   padding: 20px;
   display: grid;
   grid-gap: 20px;
   border-radius: 8px;
   align-items: center;
   background-color: #f3f8fe;
   justify-content: space-between;
   grid-template-columns: minmax(120px, max-content) 1fr auto;

   .obi-dcm-image {
      text-align: center;

      .obi-image {
         width: unset;
         max-width: 90px;
         max-height: 45px;
         object-fit: cover;
         object-position: center;
      }
   }

   .obi-dcm-content {
      display: grid;
   }

   .obi-dcm-name {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-dcm-location {
      font-size: 0.8rem;
   }

   .obi-dcm-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 12px;

      &.online {
         background: #31ba3f;
      }

      &.offline {
         background: #ff8e8e;
      }
   }

   .obi-dcm-action {
   }
}
</style>
