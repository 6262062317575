<template>
   <ObiModal as="form" ref="modal">
      <template #header>
         <ObiTitle icon-stacked :title="$t('title')" />
      </template>
      <ObiRow class="mt-2">
         <ObiTable :headers="headers" hideHeader :data="data.get('data')">
            <template #value="{ item: item }">
               <ObiText>
                  <img width="16" height="16" :src="item.get('thumbnail')" />
                  {{ item.get('name') }}
               </ObiText>
            </template>
            <template #button="{ item: item }">
               <ObiButton
                  class="float-end"
                  compact
                  size="md"
                  color="primary"
                  :text="$t('Add')"
                  v-if="!isInCurrentList(item)"
                  @click="addToFilterList(item)"
               />
               <ObiButton
                  class="float-end"
                  compact
                  size="md"
                  color="danger"
                  :text="$t('Remove')"
                  v-else
                  @click="removeFilterList(item)"
               />
            </template>
         </ObiTable>
      </ObiRow>
   </ObiModal>
</template>

<script>
import { trFormatDateLong } from '@/libs/date-utils';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import MyModel from '@/api/Models/MyModel';
export default {
   name: 'AddFilterListModal',
   i18n: {
      messages: {
         tr: {
            title: 'Engellemek istediğiniz servisleri seçin',
            Add: 'Ekle',
            Remove: 'Çıkar',
         },
         en: {
            title: 'Select services the you want to block',
            Add: 'Add',
            Remove: 'Remove',
         },
      },
   },

   components: {},
   props: {
      currentList: {
         type: Array,
      },
   },

   data() {
      return {
         data: SingleResourceResponse.create().map(MyModel),
         headers: [
            {
               text: '',
               value: 'value',
               sortable: false,
            },
            {
               text: '',
               value: 'button',
               sortable: false,
            },
         ],
      };
   },
   computed: {
      currentLanguage() {
         return this.$i18n.locale;
      },
   },

   methods: {
      trFormatDateLong,
      show(data) {
         this.data.merge(data);
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      addToFilterList(item) {
         this.$emit('addList', item);
      },
      removeFilterList(item) {
         this.$emit('removeList', item);
      },
      isInCurrentList(item) {
         if (this.$props.currentList && this.$props.currentList.length > 0) {
            const result = this.$props.currentList.filter((list) => list.code === item.get('code'));
            return result.length > 0;
         }

         return false;
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';

.obi-online-user {
   padding: 10px;
   border-radius: 8px;
   background-color: #f3f8fe;

   .bottom-white {
      border-bottom: 1px solid #fff;
   }

   .obi-online-user-content-wrapper {
      display: flex;
      gap: 20px;
   }

   .obi-online-user-content {
      display: grid;
   }

   .obi-online-user-value {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-online-user-field {
      font-size: 0.8rem;
   }
}
</style>
