import Http from '@/libs/http';

class FirewallNetworkApi extends Http {
   index(params) {
      return super.get('cloudfirewall/networks', { params });
   }

   store(payload, params) {
      return super.post('cloudfirewall/networks', payload, { params });
   }

   show(id, params) {
      return super.get(`cloudfirewall/networks/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`cloudfirewall/networks/${id}`, payload, { params });
   }

   delete(id) {
      return super.delete(`cloudfirewall/networks/${id}`);
   }

   changeCaptiveStatus(id, payload) {
      return super.post(`cloudfirewall/networks/${id}/change-captive-status`, payload);
   }
}

export default new FirewallNetworkApi();
