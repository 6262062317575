<template>
   <ObiPage>
      <ObiToolbar :icon="IconLocation" :title="formDataLocation.name" hideActions hideSearch />

      <ObiCard class="my-4">
         <template #body>
            <ObiTabBar class="mt-4">
               <template #tabs>
                  <ObiTabs :items="tabItems" @tabChanged="onTabChanged" v-model="selectedTab" />
               </template>

               <template #actions>
                  <ObiButton
                     class="px-4"
                     :text="$t('actions.save')"
                     :loading="isLoading"
                     @click="submitLocationPut"
                     v-if="showHeaderSaveButton"
                  />
               </template>

               <router-view :formDataLocation="formDataLocation" :formDataSessions="formDataSessions" />
            </ObiTabBar>
         </template>

         <template #footer> </template>
      </ObiCard>
   </ObiPage>
</template>

<script>
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import PanelLocationApi from '@/api/PanelLocationApi';

import IconLocation from '@/components/Icons/general/IconLocation';

import formDataLocation from '@/views/pages/Location/FormData/form-data-location';
import formDataSessions from '@/views/pages/Location/FormData/form-data-sessions';

export default {
   i18n: {
      messages: {
         tr: {
            page_title: 'Konumlarım',
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            page_title: 'Locations',
            actions: {
               save: 'Save',
            },
         },
      },
   },
   computed: {
      showHeaderSaveButton() {
         return this.$route.name !== 'locations-edit-users';
      },
   },
   props: {
      isRouted: {
         type: Boolean,
         default: true,
      },
   },
   data() {
      return {
         selectedTab: null,
         isLoading: false,
         IconLocation,
         formDataLocation,
         formDataSessions,
         tabItems: [
            {
               id: 'locations-edit',
               label: 'Genel',
            },
            // {
            //    id: 'locations-edit-sessions',
            //    label: 'Oturum Ayarları'
            // },
            {
               id: 'locations-edit-users',
               label: 'Yöneticiler',
            },
            {
               id: 'locations-user-profiles',
               label: 'Oturum Profilleri',
            },
            // {
            //    id: 'locations-edit-advanced',
            //    title: 'Gelişmiş Ayarlar'
            // }
         ],
      };
   },
   beforeMount() {
      this.selectedTab = this.$route.name != 'dashboard-admin' ? this.$route.name : 'locations-edit';
   },
   methods: {
      async submitLocationPut() {
         const routeName = this.$route.name;

         if (routeName === 'locations-edit') {
            await this.submitLocationGeneral();
         } else if (routeName === 'locations-edit-sessions') {
            await this.submitLocationSessions();
         }
      },
      async submitLocationGeneral() {
         if (!this.formDataLocation.isValid()) return;

         this.isLoading = true;

         try {
            const locationId = this.$route.params.locationId;

            await PanelLocationApi.update(locationId, this.formDataLocation.toObject());

            Notify.success(this.$t('Konum bilgileri güncellendi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async submitLocationSessions() {
         if (!this.formDataSessions.isValid()) return console.log('Edit.vue:167', this.formDataSessions.errors.all());

         this.isLoading = true;

         try {
            const locationId = this.$route.params.locationId;

            await PanelLocationApi.update(locationId, this.formDataSessions.toObject());

            Notify.success(this.$t('Oturum ayarları güncellendi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async onTabChanged(tabItem) {
         if (!this.$props.isRouted) return;
         if (this.$route.name === tabItem) return;

         await this.$router.push({ name: tabItem, params: { ...this.$route.params } });
      },
   },
};
</script>

<style lang="scss"></style>
