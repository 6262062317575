import BaseModel from './BaseModel';
import Brand from '@/api/Models/Brand';
import Model from '@/api/Models/Model';
import Location from '@/api/Models/Location';

export default class Device extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getMacAddress() {
      return this.get('mac_address');
   }

   getLocationFormatted() {
      return this.getLocation().getLocationFormatted();
   }

   /**
    * @returns {Location}
    */
   getLocation() {
      return Location.create(this.get('location'));
   }

   /**
    * @returns {Location}
    */
   getLocationName() {
      return this.getLocation().getName();
   }

   /**
    * @returns {string}
    */
   getStatus() {
      return this.get('status');
   }

   /**
    * @returns {string}
    */
   getLastSeenAt() {
      return this.get('last_seen_at');
   }

   /**
    * @returns {Brand}
    */
   getBrand() {
      return Brand.create(this.get('brand'));
   }

   /**
    * @returns {Model}
    */
   getModel() {
      return Model.create(this.get('model'));
   }

   /**
    * @returns {string}
    */
   getIsTrial() {
      return this.get('is_trial');
   }

   /**
    * @returns {string}
    */
   getLicenseEndAt() {
      return this.get('license_end_at');
   }

   /**
    * @returns {string}
    */
   getLicenseRemain() {
      return this.get('license_remain');
   }

   /**
    * @returns {string}
    */
   getTrialRemain() {
      return this.get('trial_remain');
   }
}
