<!-- eslint-disable prettier/prettier -->
<template>
   <ObiPage class="my-4">
      <ObiCard class="no-border no-background">
         <template #header>
            <ObiTitle :icon="IconVisitor" :title="$t('CAMPAIGN_TITLE')" class="mb-4">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('ADD_CAMPAIGN_BUTTON_TEXT')"
                     icon="mdi mdi-plus"
                     @click="$refs.createCampaignModal.show()"
                  />
               </template>
            </ObiTitle>
         </template>

         <template #body>
            <ObiLoading v-if="isLoading" />
            <ObiRow v-else-if="!isLoading && dataMemberListArray && dataMemberListArray.length > 0">
               <ObiCol v-for="item in dataMemberListArray" :key="item.hid" class="mb-2" col="4">
                  <ObiCard class="mb-2">
                     <template #header>
                        <ObiTitle
                           :title="item.name"
                           :subtitleClass="item.last_seen_at == null ? 'text-danger' : 'text-success'"
                        >
                           <template #slotSubtitle>
                              <ObiText
                                 class="text-success d-flex align-items-center"
                                 v-if="parseInt(item.diff_from_now) < 5"
                              >
                                 <ObiIcon :icon="MemberOnline" class="me-2" />
                                 {{ $t('USER_IS_ONLINE_TEXT') }}
                              </ObiText>
                              <ObiText class="text-danger d-flex align-items-center" v-else>
                                 <ObiIcon :icon="MemberOffline" class="me-2" />
                                 {{ $t('USER_IS_OFFLINE_TEXT') }}
                              </ObiText>
                           </template>
                           <template #actions>
                              <ObiButton compact size="md" :icon="EditIcon" color="primary" @click="editMember(item)" />
                              <ObiButton compact size="md" color="danger" :icon="DeleteIcon" class="ms-2" />
                           </template>
                        </ObiTitle>
                     </template>
                     <template #body>
                        <ObiText class="d-flex flex-row justify-content-between p-2">
                           <div>
                              <ObiText class="text-primary"> {{ $t('LAST_SEEN_AT_TEXT') }}</ObiText>
                              <timeago
                                 v-if="item.last_seen_at"
                                 :datetime="item.last_seen_at"
                                 :locale="$i18n.locale"
                                 :auto-update="60"
                              ></timeago>
                              <ObiText v-else>{{ $t('CAMPAIGN_NEVER_SEEN_TEXT') }}</ObiText>
                           </div>
                           <div class="d-flex flex-column align-items-center">
                              <ObiText class="text-secondary"> {{ $t('CAMPAIGN_CURRENT_KEY_TITLE') }}</ObiText>
                              <ObiText v-if="item.isPrivateKeyVisible" class="text-primary h2 mb-0 mt-0">
                                 {{ item.private_key != null ? formatPrivateKey(item.private_key) : 'No Key' }}</ObiText
                              >
                              <ObiText v-else class="text-primary h2 mb-0 mt-0">***-***</ObiText>
                              <ObiButton
                                 v-if="!item.isPrivateKeyVisible"
                                 class="bg-white"
                                 :icon="IconEyeOpen"
                                 @click="item.isPrivateKeyVisible = !item.isPrivateKeyVisible"
                              />
                              <ObiButton
                                 v-else
                                 class="bg-white"
                                 :icon="IconEyeClose"
                                 @click="item.isPrivateKeyVisible = !item.isPrivateKeyVisible"
                              />
                           </div>
                        </ObiText>
                     </template>
                  </ObiCard>
               </ObiCol>
            </ObiRow>
            <Obi404 :title="$t('LIST_NOT_FOUND_TEXT')" :desc="$t('LIST_NOT_FOUND_DESC')" v-else>
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('ADD_CAMPAIGN_BUTTON_TEXT')"
                     @click="$refs.createCampaignModal.show()"
                  />
               </template>
            </Obi404>
         </template>
      </ObiCard>

      <CampaignCreateModal ref="createCampaignModal" @success="getMemberList" />
   </ObiPage>
</template>

<script>
import IconVisitor from '@/components/Icons/general/IconVisitor';
import IconEyeOpen from '@/components/Icons/general/IconEyeOpen.svg';
import IconEyeClose from '@/components/Icons/general/IconEyeClose.svg';
import MemberOffline from '@/components/Icons/general/MemberOffline.svg';
import MemberOnline from '@/components/Icons/general/MemberOnline.svg';
import CampaignCreateModal from '@/views/pages/Campaign/_Modals/CampaignCreateModal';
import { timestampToDate, diffFromNow } from '@/libs/date-utils';
import IconSearch from '@/components/Icons/header/IconSearch.svg';
import { mapGetters } from 'vuex';
import FirewallMemberApi from '@/api/FirewallMemberApi';
import ErrorHandler from '@/libs/error-handler';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import MyModel from '@/api/Models/MyModel';
import { formatPrivateKey } from '@/libs/input-utils';
import EditIcon from '@/components/Icons/portals/EditIcon.svg';
import DeleteIcon from '@/components/Icons/portals/DeleteIcon.svg';

export default {
   name: 'MemberIndexPage',
   i18n: {
      messages: {
         tr: {
            CAMPAIGN_TITLE: 'Kampanyalar',
            ADD_CAMPAIGN_BUTTON_TEXT: 'Yeni Kampanya Ekle',
            LAST_SEEN_AT_TEXT: 'Son görülme',
            USER_IS_OFFLINE_TEXT: 'Çevrimdışı',
            USER_IS_ONLINE_TEXT: 'Çevrimiçi',
            CAMPAIGN_CURRENT_KEY_TITLE: 'Mevcut Anahtar',
            CAMPAIGN_EDIT_BUTTON_TEXT: 'Düzenle',
            CAMPAIGN_DELETE_BUTTON_TEXT: 'Sil',
            LIST_NOT_FOUND_TEXT: 'Hiçbir Kampanya bulunamadı',
            LIST_NOT_FOUND_DESC: "'Yeni Üye Kampanya'yi tıklayarak yeni bir Kampanya oluşturun",
            CAMPAIGN_NEVER_SEEN_TEXT: 'Hiç görülmedi',
         },
         en: {
            CAMPAIGN_TITLE: 'Campaigns',
            ADD_CAMPAIGN_BUTTON_TEXT: 'Add New Campaign',
            LAST_SEEN_AT_TEXT: 'Last seen at',
            USER_IS_OFFLINE_TEXT: 'Offline',
            USER_IS_ONLINE_TEXT: 'Online',
            CAMPAIGN_CURRENT_KEY_TITLE: 'Current Key',
            CAMPAIGN_EDIT_BUTTON_TEXT: 'Edit',
            CAMPAIGN_DELETE_BUTTON_TEXT: 'Delete',
            LIST_NOT_FOUND_TEXT: 'No campaigns found',
            LIST_NOT_FOUND_DESC: "Click 'Add New Campaign' to create a new campaign",
            CAMPAIGN_NEVER_SEEN_TEXT: 'Never seen',
         },
      },
   },
   components: {
      CampaignCreateModal,
   },
   computed: {
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      ...mapGetters('auth', ['authUser', '']),
   },
   data() {
      return {
         IconVisitor,
         EditIcon,
         DeleteIcon,
         IconEyeOpen,
         IconEyeClose,
         MemberOffline,
         MemberOnline,
         isLoading: false,
         IconSearch,
         tableHeaders: [
            {
               text: '',
               value: 'hid',
               sortable: false,
            },
         ],
         dataMemberList: SingleResourceResponse.create().map(MyModel),
         dataMemberListArray: [],
      };
   },
   async mounted() {
      this.getMemberList();
   },
   methods: {
      timestampToDate,
      diffFromNow,
      formatPrivateKey,
      async getMemberList() {
         try {
            this.isLoading = true;
            const response = await FirewallMemberApi.index();
            response.data.forEach((element) => {
               element.isPrivateKeyVisible = false;
               element.last_seen_at = element.last_seen_at != null ? this.timestampToDate(element.last_seen_at) : null;
               element.diff_from_now = element.last_seen_at != null ? this.diffFromNow(element.last_seen_at) : null;
            });
            this.dataMemberListArray = response.data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async editMember(member) {
         console.log('editMember', member);
         this.$refs.createCampaignModal.show(member);
      },
   },
};
</script>

<style lang="scss" scoped></style>
