import Form from '@/libs/form';

const fields = ['first_name', 'last_name', 'email', 'phone_number', 'password', 'password_confirmation'];

const rules = {
   first_name: 'required',
   last_name: 'required',
   email: 'required|email',
   phone_number: 'required',
   password: 'required|min:6|confirmed',
   password_confirmation: 'required|min:6',
};

export default Form.create(fields).validate(rules);
