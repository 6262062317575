import Http from '@/libs/http';

class PanelPortalSurveyApi extends Http {
   index(portalId, params) {
      return super.get(`panel/portal/${portalId}/surveys`, { params });
   }

   store(portalId, payload, params) {
      return super.post(`panel/portal/${portalId}/surveys`, payload, { params });
   }

   show(portalId, params) {
      return super.get(`panel/portal/${portalId}/surveys`, { params });
   }

   update(portalId, surveyId, payload, params) {
      return super.put(`panel/portal/${portalId}/surveys/${surveyId}`, payload, { params });
   }

   delete(portalId, surveyId, params) {
      return super.delete(`panel/portal/${portalId}/surveys/${surveyId}`, { params });
   }
}

export default new PanelPortalSurveyApi();
