<template>
   <div class="obi-header">
      <div class="obi-header-wrapper">
         <div class="obi-header--navbar">
            <RouterLink :to="{ name: 'dashboard-index' }" class="obi-brand">
               <ObiLogo />
            </RouterLink>
         </div>

         <div class="tab-menu-wrapper">
            <ObiTabMenu />
         </div>

         <div class="menu-item-team-list d-flex align-items-center">
            <div class="language-bar d-flex align-items-center">
               <ObiDropdown class="obi-team-dropdown ms-2" as="team-list">
                  <template #title>
                     <IconFlagTr v-if="currentLanguage.key == 'tr'" class="language-flag mr-2" />
                     <IconFlagEn v-else-if="currentLanguage.key == 'en'" class="language-flag mr-2" />
                     <span class="language-text"> {{ currentLanguage.name }}</span>
                  </template>
                  <a
                     class="dropdown-item"
                     href="#"
                     v-for="language in languages"
                     :key="language.key"
                     @click="changeLocale(language)"
                  >
                     <IconFlagTr v-if="language.key == 'tr'" class="mr-2" />
                     <IconFlagEn v-else-if="language.key == 'en'" class="mr-2" />
                     <span> {{ language.name }}</span>
                  </a>
               </ObiDropdown>
            </div>
            <ObiDropdown
               class="obi-team-dropdown"
               :title="currentProfile.name"
               as="btn-profile"
               :isLoading="isTeamChanging"
               v-if="currentProfile"
            >
               <a
                  class="dropdown-item"
                  href="#"
                  v-for="profile in profiles"
                  :key="profile.hid"
                  @click="changeProfile(profile)"
               >
                  {{ profile.name }}
               </a>
               <div class="divider mt-2" />
               <a class="dropdown-item" href="#" @click="addNewProfile">
                  {{ $t('ADD_NEW_PROFILE') }}
               </a>
               <div class="divider" />
               <a class="dropdown-item" href="#" @click="logout">
                  {{ $t('LOGOUT') }}
               </a>
            </ObiDropdown>
         </div>
      </div>

      <div class="tab-menu-wrapper--mobile">
         <ObiTabMenu />
      </div>

      <ModalCreateNewProfile ref="modalCreateNewProfile" @success="changeProfile" />
   </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import ErrorHandler from '@/libs/error-handler';

import AuthApi from '@/api/AuthApi';
import IconNotification from '@/components/Icons/general/IconNotification';
import ObiTabMenu from '@/components/Common/ObiTabMenu';
import ModalCreateNewProfile from '@/views/pages/Settings/_Modals/CreateProfile';

export default {
   name: 'ObiHeader',
   components: { ObiTabMenu, ModalCreateNewProfile },
   i18n: {
      messages: {
         tr: {
            LOGOUT: 'Oturumu Kapat',
            ADD_NEW_PROFILE: 'Yeni Profil Oluştur',
         },
         en: {
            LOGOUT: 'Logout',
            ADD_NEW_PROFILE: 'Add New Profile',
         },
      },
   },
   props: {
      isAdmin: {
         type: [Boolean],
         default: false,
      },
   },
   data() {
      return {
         IconNotification,
         isTeamChanging: false,
         languages: [
            {
               name: 'Türkçe',
               key: 'tr',
            },
            {
               name: 'English',
               key: 'en',
            },
         ],
      };
   },
   computed: {
      ...mapGetters('auth', ['authUser', '']),
      teams() {
         return get(this, 'authUser.teams', {});
      },
      profiles() {
         return get(this, 'authUser.profiles', {});
      },
      currentProfile() {
         return get(this, 'authUser.current_profile', {});
      },
      currentTeam() {
         return get(this, 'authUser.current_team', {});
      },
      notifications() {
         const notifications = get(this, 'authUser.notifications', []);
         const readNotifications = get(this, 'authUser.readNotifications', []);
         return notifications.concat(readNotifications);
      },
      unread() {
         return get(this, 'authUser.notifications.length');
      },
      currentLanguage() {
         const locales = this.languages.filter((x) => x.key == this.$i18n.locale);

         return locales && locales.length > 0 ? locales[0] : this.locales[0];
      },
   },
   methods: {
      async changeProfile(team) {
         this.isTeamChanging = true;
         try {
            await AuthApi.changeProfile(team.hid);
            await this.$router.push({ name: 'dashboard-index' }).catch(() => {});
            window.location.reload();
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.isTeamChanging = false;
         }
      },
      async changeTeamToNew(response) {
         this.changeTeam(response.data);
      },
      changeLocale(lang) {
         localStorage.setItem('last-locale', lang.key);
         window.location.reload();
      },
      logout() {
         this.$store.dispatch('auth/logout', { redirectRoute: 'auth-login' });
      },
      addNewProfile() {
         this.$refs.modalCreateNewProfile.show();
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-header {
   position: relative;
   -webkit-box-pack: justify;
   -webkit-box-align: center;
   background-color: #2f71f2;
   display: flex;
   justify-content: center;
   padding: 10px 10px 0px 30px;

   .tab-menu-wrapper--mobile {
      display: none;
      background-color: #2f71f2;
      padding: 5px;
      position: relative;
      width: 100%;
      overflow: scroll;
   }

   .tab-menu-wrapper {
      display: flex;
      padding-left: 60px;
   }

   .obi-header-wrapper {
      display: flex !important;
      justify-content: space-between;
      max-width: 1240px;
      width: 100%;
   }
   .obi-header--navbar--mobile {
      display: none;
   }

   .obi-team-button {
      background: #ffffff !important;
      border-color: #ffffff !important;

      &.first {
         border-right: 2px solid #225ac0 !important;
      }
   }

   .obi-header--top {
      display: flex;
      flex: 2 1 0%;
      -webkit-box-align: center;
      align-items: center;
      min-width: 0px;
   }

   .obi-header--pane {
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      min-width: 0px;
   }

   .obi-header--account-link {
      gap: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .shake {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      z-index: 999;
   }

   @keyframes shake {
      10%,
      90% {
         transform: translate3d(-1px, 0, 0);
      }
      20%,
      80% {
         transform: translate3d(2px, 0, 0);
      }
      30%,
      50%,
      70% {
         transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
         transform: translate3d(4px, 0, 0);
      }
   }
}

.divider {
   width: 100%;
   border: 1px solid rgba(0, 0, 0, 0.125);
}

@media (max-width: 768px) {
   .obi-header-wrapper {
      padding: 5px;
   }
   .obi-header--navbar--mobile {
      display: block !important;
   }
   .tab-menu-wrapper {
      display: none !important;
   }
   .language-text {
      display: none !important;
   }
   .menu-item-team-list {
      flex-direction: row-reverse !important;
   }
   .obi-tab-menu {
      margin-bottom: -20px !important;
   }
   .tab-menu-wrapper--mobile {
      display: flex !important;
      background-color: #2f71f2;
      padding: 5px;
   }
   .obi-header {
      display: block !important;
      padding: 0px !important;
   }
}
</style>
