import Http from '@/libs/http';

class FirewallStatisticApi extends Http {
   overview(params) {
      return super.get('cloudfirewall/statistics/overview', { params });
   }

   topDomains(params) {
      return super.get('cloudfirewall/statistics/domains', { params });
   }

   historyData(params) {
      return super.get('cloudfirewall/statistics/history', { params });
   }
}

export default new FirewallStatisticApi();
