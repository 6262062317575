import Form from '@/libs/form';

const fields = [
   'hid',
   'name',
   'is_firewall_rules_active',
   'is_trackers_rule_active',
   'is_ads_rule_active',
   'is_service_rule_active',
   'is_blocked_domains_active',
   'is_logging_active',
   'is_custom_retention_active',
   'private_key_option',
   'private_key',
   'private_key_renew_period',
   'private_key_renew_value',
   'is_limitation_active',
   'limitations',
];

const rules = {
   name: 'required',
   private_key_option: 'required',
   private_key_renew_period: 'required_if:private_key_option,auto',
   private_key_renew_value: 'required_if:private_key_option,auto',
   private_key: 'required_if:private_key_option,manual',
   is_limitation_active: 'required',
   limitations: 'required_if:is_limitation_active',
};

export default Form.create(fields)
   .defaults({
      hid: null,
      name: null,
      private_key: null,
      private_key_option: 'auto',
      private_key_renew_period: 'hour',
      private_key_renew_value: 1,
      is_firewall_rules_active: true,
      is_trackers_rule_active: true,
      is_ads_rule_active: true,
      is_service_rule_active: true,
      is_blocked_domains_active: true,
      is_logging_active: true,
      is_custom_retention_active: false,
      is_limitation_active: false,
      limitations: {
         mon: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         tue: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         wed: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         thu: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         fri: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         sat: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         sun: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
      },
   })
   .validate(rules);
