<template>
   <ObiText class="page-register">
      <ObiImage class="tenant-logo" :src="tenant.login.image.src" />
      <ObiText as="h1" class="register-title" v-html="$t('register.page_title')" />
      <ObiText class="register-description" v-html="$t('register.page_description')" />

      <ObiForm @submit.prevent="submitRegister">
         <ObiText class="my-4">
            <ObiRow>
               <ObiCol sm="6">
                  <ObiFloatingInput
                     :icon="IconUser"
                     :disabled="isLoading"
                     v-model="formDataRegister.first_name"
                     name="firstname"
                     :label="$t('register.fields.first_name')"
                     :placeholder="$t('register.fields.first_name')"
                     :errors="formDataRegister.errors.get('first_name')"
                  />
               </ObiCol>
               <ObiCol sm="6">
                  <ObiFloatingInput
                     :icon="IconUser"
                     :disabled="isLoading"
                     v-model="formDataRegister.last_name"
                     name="lastname"
                     :label="$t('register.fields.last_name')"
                     :placeholder="$t('register.fields.last_name')"
                     :errors="formDataRegister.errors.get('last_name')"
                  />
               </ObiCol>
            </ObiRow>

            <ObiRow>
               <ObiCol sm="12">
                  <ObiFloatingInput
                     :icon="IconCompany"
                     :disabled="isLoading"
                     name="location_name"
                     v-model="formDataRegister.location_name"
                     :label="$t('register.fields.name')"
                     :placeholder="$t('register.fields.name')"
                     :errors="formDataRegister.errors.get('location_name')"
                  />
               </ObiCol>
            </ObiRow>

            <ObiFloatingInput
               type="email"
               :icon="IconMail"
               :disabled="isLoading"
               name="email"
               v-model="formDataRegister.email"
               :label="$t('register.fields.email')"
               :placeholder="$t('register.fields.email')"
               :errors="formDataRegister.errors.get('email')"
            />

            <ObiFloatingInput
               :icon="IconPhone2"
               :disabled="isLoading"
               name="phone"
               v-model="formDataRegister.phone_number"
               :label="$t('register.fields.phone_number')"
               :placeholder="$t('register.fields.phone_number')"
               :errors="formDataRegister.errors.get('phone_number')"
            />

            <ObiFloatingInput
               compact
               type="password"
               name="password"
               :icon="IconPassword"
               :disabled="isLoading"
               v-model="formDataRegister.password"
               :label="$t('register.fields.password')"
               :placeholder="$t('register.fields.password')"
               :errors="formDataRegister.errors.get('password')"
            />
         </ObiText>

         <ObiButton
            block
            icon-right
            type="submit"
            :loading="isLoading"
            icon="mdi mdi-arrow-right"
            :text="$t('register.form.button_label')"
         />

         <router-link
            class="btn btn-link mt-4"
            v-html="$t('register.form.login_button_label')"
            :to="{ name: 'auth-login' }"
         />
      </ObiForm>
   </ObiText>
</template>

<script>
import IconUser from '@/components/Icons/form/IconUser';
import IconMail from '@/components/Icons/form/IconMail';
import IconPhone2 from '@/components/Icons/form/IconPhone2';
import IconGlobe from '@/components/Icons/form/IconGlobe';
import IconCompany from '@/components/Icons/form/IconCompany';
import IconPassword from '@/components/Icons/form/IconPassword';

import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'AuthRegister',
   components: {},
   data() {
      return {
         IconUser,
         IconMail,
         IconPhone2,
         IconGlobe,
         IconCompany,
         IconPassword,
         isLoading: false,
         formDataRegister: Form.create([
            'first_name',
            'last_name',
            'location_name',
            'email',
            'phone_number',
            'password',
         ]).validate({
            first_name: 'required',
            last_name: 'required',
            location_name: 'required',
            email: 'required',
            phone_number: 'required',
            password: 'required',
         }),
      };
   },
   methods: {
      async submitRegister() {
         const params = this.$route.query;
         console.log(params);
         if (!this.formDataRegister.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            await this.$store.dispatch('auth/register', this.formDataRegister.toObject());

            await this.$router.push({ name: 'auth-complete' });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.page-register {
   text-align: center;

   .obi-form {
      margin: 0 auto;
      max-width: 400px;
   }

   .register-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .register-description {
      color: #777d96;
      font-size: 13px;
   }

   .form-register {
      margin-top: 30px;
   }
}
</style>
