import LayoutAuth from '@/views/layouts/Auth';
import InvitationAccept from '@/views/pages/Auth/Invitation';

export default {
   path: '/invitation',
   component: LayoutAuth,
   children: [
      {
         path: 'accept/:token',
         name: 'invitation-accept',
         component: InvitationAccept,
      },
   ],
};
