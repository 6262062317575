import Http from '@/libs/http';

class PaneLocationUserApi extends Http {
   index(locationId, params) {
      return super.get(`panel/location/${locationId}/user`, { params });
   }

   store(locationId, payload, params) {
      return super.post(`panel/location/${locationId}/user`, payload, { params });
   }

   show(locationId, id, params) {
      return super.get(`panel/location/${locationId}/user/${id}`, { params });
   }

   update(locationId, id, payload, params) {
      return super.put(`panel/location/${locationId}/user/${id}`, payload, { params });
   }

   delete(locationId, id, params) {
      return super.delete(`panel/location/${locationId}/user/${id}`, { params });
   }

   invite(locationId, payload, params) {
      return super.post(`team/members/invite/${locationId}`, payload, { params });
   }

   invites(locationId, params) {
      return super.get(`panel/location/${locationId}/invites`, { params });
   }

   resendInvite(inviteId, params) {
      return super.post(`team/members/invite/resend/${inviteId}`, { params });
   }

   cancelInvite(inviteId, params) {
      return super.delete(`team/members/invite/${inviteId}`, { params });
   }
}

export default new PaneLocationUserApi();
