import Http from '@/libs/http';
import PanelLocationUserApi from '@/api/PanelLocationUserApi';
import PanelLocationUserProfileApi from '@/api/PanelLocationUserProfileApi';

class PaneLocationApi extends Http {
   index(params) {
      return super.get('panel/location', { params });
   }

   store(payload, params) {
      return super.post('panel/location', payload, { params });
   }

   show(id, params) {
      return super.get(`panel/location/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`panel/location/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`panel/location/${id}`, { params });
   }
   applyLicense(id, params) {
      return super.post(`panel/location/${id}/license`, params);
   }

   updateDevice(id, payload, params) {
      return super.put(`panel/user/team/device/${id}`, payload, { params });
   }

   get users() {
      return PanelLocationUserApi;
   }

   get userProfiles() {
      return PanelLocationUserProfileApi;
   }
}

export default new PaneLocationApi();
