<template>
   <ObiText class="obi-eg">
      <ObiText class="obi-eg-nav">
         <ObiNav
            class="--for-obi-eg"
            vertical
            pills
            draggable
            selectable
            :items="navItems"
            v-model="selectedItem"
            @orderChanged="orderChanged"
         />
      </ObiText>
      <ObiText class="obi-eg-body">
         <ObiText v-if="selectedItem === 'citizen-id'">
            <ObiTitle class="mb-2" :title="$t('labels.citizen_id')" />
            <ObiText class="mb-5" v-html="$t('labels.citizen_id_description')" />

            <ObiRow>
               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.provider_type')" />
                  <ObiButtonSwitch :items="citizenProviderTypes" v-model="selectedProvider.options.provider_type" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.citizen_id_field')"
                     v-if="selectedProvider.options.provider_type === 'local'"
                  />
                  <ObiButtonSwitch
                     :items="citizenIdFieldOptions"
                     v-model="selectedProvider.options.method"
                     v-if="selectedProvider.options.provider_type === 'local'"
                  />
               </ObiCol>
               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.email_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_email" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_email"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.email_required"
                     v-if="selectedProvider.options.collect_email"
                  />
               </ObiCol>

               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.phone_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_phone" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_phone"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.phone_required"
                     v-if="selectedProvider.options.collect_phone"
                  />
               </ObiCol>
            </ObiRow>
            <ObiTitle class="mb-1" :title="$t('labels.provider_title')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="login_with_citizen_id" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mt-2" :title="$t('labels.info_message')" />
            <ObiText class="mt-2">
               <MultiLanguageInput v-model="messages" name="citizen_id_info" @change="contentChanged" />
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'room-number'">
            <ObiTitle class="mb-2" :title="$t('labels.room_number')" />
            <ObiText class="mb-5" v-html="$t('labels.room_number_description')" />

            <ObiRow>
               <ObiCol col="12" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.provider_type')" />
                  <ObiButtonSwitch :items="roomNoOptions" v-model="selectedProvider.options.method" />
               </ObiCol>
            </ObiRow>
            <ObiRow>
               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.email_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_email" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_email"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.email_required"
                     v-if="selectedProvider.options.collect_email"
                  />
               </ObiCol>

               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.phone_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_phone" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_phone"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.phone_required"
                     v-if="selectedProvider.options.collect_phone"
                  />
               </ObiCol>
            </ObiRow>
            <ObiTitle class="mb-1" :title="$t('labels.provider_title')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="login_with_room_number" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mb-1" :title="$t('labels.info_message')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="room_number_info" @change="contentChanged" />
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'sms'">
            <ObiTitle class="mb-2" :title="$t('labels.sms')" />
            <ObiText class="mb-5" v-html="$t('labels.sms_description')" />

            <ObiRow>
               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.email_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_email" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_email"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.email_required"
                     v-if="selectedProvider.options.collect_email"
                  />
               </ObiCol>
            </ObiRow>
            <ObiTitle class="mb-1" :title="$t('labels.provider_title')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="login_with_sms" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mb-1" :title="$t('labels.info_message')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="sms_info" @change="contentChanged" />
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'username'">
            <ObiTitle class="mb-2" :title="$t('labels.username_title')" />
            <ObiText class="mb-5" v-html="$t('labels.username_description')" />

            <ObiRow>
               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.email_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_email" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_email"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.email_required"
                     v-if="selectedProvider.options.collect_email"
                  />
               </ObiCol>

               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.phone_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_phone" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_phone"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.phone_required"
                     v-if="selectedProvider.options.collect_phone"
                  />
               </ObiCol>
            </ObiRow>
            <ObiTitle class="mb-1" :title="$t('labels.provider_title')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="login_with_username" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mb-1" :title="$t('labels.info_message')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="username_id_info" @change="contentChanged" />
            </ObiText>
         </ObiText>
         <ObiText v-if="selectedItem === 'temp'">
            <ObiTitle class="mb-2" :title="$t('labels.temp_title')" />
            <ObiText class="mb-5" v-html="$t('labels.temp_description')" />

            <ObiSelect
               class="mb-4"
               v-model="selectedProvider.options.profile_id"
               :label="$t('labels.user_profile')"
               :options="dataLocationUserProfiles"
               :placeholder="$t('labels.user_profile')"
               labelField="name"
               valueField="id"
            />

            <ObiRow>
               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.email_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_email" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_email"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.email_required"
                     v-if="selectedProvider.options.collect_email"
                  />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.confirm_email')"
                     v-if="selectedProvider.options.email_required"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.confirm_email"
                     v-if="selectedProvider.options.email_required"
                  />
               </ObiCol>

               <ObiCol col="4" class="mb-4">
                  <ObiTitle class="mb-2" :title="$t('labels.phone_collect')" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.collect_phone" />
                  <ObiTitle
                     class="mt-2 mb-2"
                     :title="$t('labels.is_required')"
                     v-if="selectedProvider.options.collect_phone"
                  />
                  <ObiButtonSwitch
                     :items="collectOptions"
                     v-model="selectedProvider.options.phone_required"
                     v-if="selectedProvider.options.collect_phone"
                  />
               </ObiCol>
            </ObiRow>
            <ObiTitle class="mb-1" :title="$t('labels.provider_title')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="login_temp" @change="contentChanged" />
            </ObiText>

            <ObiTitle class="mb-1" :title="$t('labels.info_message')" />
            <ObiText class="mb-4">
               <MultiLanguageInput v-model="messages" name="temp_info" @change="contentChanged" />
            </ObiText>
         </ObiText>
         <ObiText>
            <ObiTitle class="mb-2" title="Güvenlik Kodu" />
            <ObiText class="mb-5" v-html="$t('labels.security_code_message')" />

            <ObiRow>
               <ObiCol col="12" class="mb-4">
                  <ObiTitle class="mb-2" title="Güvenlik Kodu Aktif" />
                  <ObiButtonSwitch :items="collectOptions" v-model="selectedProvider.options.require_security_code" />
               </ObiCol>

               <ObiCol col="12" class="mb-4">
                  <ObiTitle class="mb-2" title="Güvenlik Kodu Belirleyin" />
                  <ObiInput
                     :disabled="!selectedProvider.options.require_security_code"
                     v-model="selectedProvider.options.security_code"
                  />
               </ObiCol>
            </ObiRow>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import PanelLocationApi from '@/api/PanelLocationApi';

export default {
   name: 'ObiPortalProviderMethod',

   i18n: {
      messages: {
         tr: {
            labels: {
               security_code_message:
                  'Girişleri kontrol etmek için güvenlik kodu belirleyerek oturum açan kullanıcıları filtreleyebilirsiniz',
               citizen_id: 'TC Kimlik Numarası İle Giriş',
               citizen_id_description:
                  'Misafirlerinizin Wi-Fi ağınızda TC Kimlik Numarasıyla oturum açmalarına imkan verir. TCK ile birlikte Ad, Soyad ve Doğum Yılı bilgileri istenir, Bu bilgiler nüfus müdürlüğü servisleri kullanılarak doğrulanır.',
               info_message: 'Bilgilendirme Mesajı',
               provider_title: 'Giriş Yöntemi Adı',
               room_number_description:
                  'Misafirlerinizin Wi-Fi ağınızda Oda Numaralarıyla oturum açmalarına imkan verir. Oda Numarası ile birlikte Doğum Tarihi Gün, Ay, Yıl olarak istenir, Bu bilgiler PMS Entegrasyonunuz kullanılarak doğrulanır.',
               room_number: 'Oda Numarası İle Giriş',
               sms: 'SMS Doğrulama',
               login_with_email: 'E-Posta Doğrulama',
               sms_description:
                  'Misafirlerinizin girecekleri cep telefonlarına gönderilecek doğrulama kodu ile kimliğini doğrulayabilirsiniz.',
               username_title: 'Kullanıcı Adı',
               username_description: 'Oluşturacağınız Kullanıcı adı ve parola ile oturum açtırabilirsiniz.',
               select_color: 'Renk Seçiniz',
               font_size: 'Yazı Boyutu',
               provider_type: 'Doğrulama Türü',
               email_collect: 'E-Posta İste',
               phone_collect: 'Telefon İste',
               is_required: 'Zorunlu?',
               citizen_id_field: 'Doğ. Alanları',
               temp_title: 'Geçici Bağlantı',
               temp_description:
                  'Misafirlerinize seçtiğiniz Kullanım profiline göre doğrulama yapmadan giriş yapmalarına imkan verebilirsiniz.',
               user_profile: 'Uygulanacak Profil',
               confirm_email: 'E-Posta Doğrulansın',
            },
         },
         en: {
            labels: {
               security_code_message:
                  'You can filter users who log in by setting a security code for login verification',
               citizen_id: 'Login with Turkish ID Number',
               citizen_id_description:
                  'Allows your guests to log in to your Wi-Fi network with their Turkish ID numbers. First Name, Last Name, and Birth Year are required along with TCK (Turkish ID Number). These details are verified using civil registry services.',
               info_message: 'Information Message',
               provider_title: 'Authentication Method Name',
               room_number_description:
                  'Allows your guests to log in to your Wi-Fi network with Room Numbers. Birth Date (Day, Month, Year) is required along with the Room Number. These details are verified using your PMS Integration.',
               room_number: 'Login with Room Number',
               sms: 'SMS Verification',
               sms_description:
                  'You can verify the identity of your guests by sending a verification code to their provided mobile phone numbers.',
               username_title: 'Username',
               username_description: 'You can allow users to log in with a username and password that you create.',
               select_color: 'Select Color',
               font_size: 'Font Size',
               provider_type: 'Authentication Type',
               email_collect: 'Request Email',
               phone_collect: 'Request Phone',
               is_required: 'Required?',
               citizen_id_field: 'Verification Fields',
               temp_title: 'Temporary Connection',
               temp_description:
                  'You can allow guests to connect without verification based on the selected usage profile.',
               user_profile: 'Applied Profile',
               confirm_email: 'Confirm Email',
               login_with_email: 'With E-Mail',
            },
         },
      },
   },

   props: {
      portalId: {
         required: true,
      },
      options: {
         type: Object,
         required: true,
      },
   },

   computed: {
      uploadURL() {
         return `/panel/portal/${this.portalId}/image`;
      },
      selectedProvider() {
         const result = this.navItems.filter((item) => item.id === this.selectedItem);
         if (result) {
            const options = result[0].options;
            if (!options.security_code || !options.require_security_code) {
               options.require_security_code = false;
               options.security_code = null;
            }
            return result[0];
         }
         return null;
      },
   },

   data() {
      return {
         selectedItem: 'sms',
         dataLocationUserProfiles: [],
         navItems: [
            {
               id: 'sms',
               label: this.$t('labels.sms'),
               checked: true,
               component: 'SmsLogin',
               order: 3,
               options: {
                  collect_email: false,
                  collect_phone: false,
                  email_required: false,
                  phone_required: false,
                  require_security_code: false,
                  security_code: null,
               },
            },
            {
               id: 'username',
               label: this.$t('labels.username_title'),
               checked: false,
               component: 'UserLogin',
               order: 4,
               options: {
                  collect_email: false,
                  collect_phone: false,
                  email_required: false,
                  phone_required: false,
                  require_security_code: false,
                  security_code: null,
               },
            },
            {
               id: 'temp',
               label: this.$t('labels.temp_title'),
               checked: false,
               component: 'Temp',
               order: 5,
               options: {
                  collect_email: false,
                  collect_phone: false,
                  email_required: false,
                  phone_required: false,
                  profile_id: null,
                  confirm_email: false,
                  require_security_code: false,
                  security_code: null,
               },
            },
            {
               id: 'email',
               label: this.$t('labels.login_with_email'),
               checked: false,
               component: 'Email',
               order: 5,
               options: {
                  collect_email: false,
                  collect_phone: false,
                  email_required: false,
                  phone_required: false,
                  profile_id: null,
                  confirm_email: false,
                  require_security_code: false,
                  security_code: null,
               },
            },
            {
               id: 'room-number',
               label: this.$t('labels.room_number'),
               checked: false,
               component: 'RoomNoLogin',
               order: 2,
               options: {
                  collect_email: false,
                  collect_phone: false,
                  email_required: false,
                  phone_required: false,
                  method: 'room_number',
                  require_security_code: false,
                  security_code: null,
               },
            },
            {
               id: 'citizen-id',
               label: 'TC Kimlik',
               checked: false,
               component: 'TcLogin',
               order: 1,
               options: {
                  collect_email: false,
                  collect_phone: false,
                  email_required: false,
                  phone_required: false,
                  provider_type: 'nvi',
                  citizen_id_field: 'both',
                  require_security_code: false,
                  security_code: null,
               },
            },
         ],
         citizenProviderTypes: [
            { id: 'nvi', label: 'NVİ' },
            { id: 'local', label: 'Lokal' },
         ],
         collectOptions: [
            { id: true, label: 'Evet' },
            { id: false, label: 'Hayır' },
         ],
         citizenIdFieldOptions: [
            { id: 'only_citizen_id', label: 'TCK' },
            { id: 'both', label: 'TCK + Tarih' },
         ],
         roomNoOptions: [
            { id: 'both', label: 'Oda Numarası+Doğum Tarihi' },
            { id: 'idNumber', label: 'Oda Numarası + Kimlik/Pasaport' },
            { id: 'onylIdNumber', label: 'Pasaport/TC No + Doğum Tarihi' },
         ],
         messages: {
            usages_policy_title: {},
            room_number_info: {},
            sms_info: {},
            citizen_id_info: {},
            username_id_info: {},
         },
      };
   },

   async beforeMount() {
      const navItems = this.navItems;
      this.messages = this.options.messages || {};

      this.options.providers = Object.values(this.options.providers).sort((a, b) => (a.order > b.order ? 1 : -1));

      if (this.options.providers && this.options.providers.length > 0) {
         this.options.providers.forEach((provider) => {
            navItems
               .filter((item) => item.id === provider.slug)
               .map((item) => {
                  return (item.checked = true) & (item.order = provider.order);
               });
         });

         this.options.providers.forEach((provider) => {
            navItems
               .filter((item) => item.id === provider.slug)
               .map((item) => {
                  if (!provider.options || Array.isArray(provider.options)) {
                     provider.options = {};
                  }

                  if (!provider.options.method) {
                     provider.options.method = 'both';
                  }
                  item.options = provider.options;
               });
         });

         this.navItems = Object.values(navItems).sort((a, b) => (a.order > b.order ? 1 : -1));
      }

      await this.loadLocationUserProfiles();
   },

   methods: {
      async loadLocationUserProfiles() {
         this.isLoadingUserProfiles = true;

         try {
            const { data } = await PanelLocationApi.userProfiles.index();
            this.dataLocationUserProfiles = data;
         } catch (err) {
            this.isLoadingUserProfiles = false;
         } finally {
            this.isLoadingUserProfiles = false;
         }
      },
      async contentChanged(value) {
         this.$emit('contentChanged', value);
      },
      async orderChanged(value) {
         value.forEach((navItem, index) => {
            navItem.order = index;
         });
         this.navItems = value;
         this.$emit('providerChanged', this.navItems);
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: grid;
   margin-left: -1.5rem;
   grid-template-columns: 15rem 1fr;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }
}
</style>
