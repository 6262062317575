<template>
   <ObiPage>
      <ObiToolbar />

      <ObiCard class="my-4 ">
         <template #header>
            <ObiTitle :icon="IconPortal" :title="$t('page_title')" />
         </template>

         <template #body>
            <ObiPortalEditor :portalId="$route.params.portalId" />
         </template>

         <template #footer> </template>
      </ObiCard>
   </ObiPage>
</template>

<script>
import IconPortal from '@/components/Icons/general/IconPortal';

export default {
   name: 'PagePortalEdit',

   i18n: {
      messages: {
         tr: {
            page_title: 'Portal Düzenlemesi',
         },
         en: {
            page_title: 'Edit Portal',
         },
      },
   },

   computed: {},

   data() {
      return {
         IconPortal,
      };
   },

   methods: {},
};
</script>

<style lang="scss"></style>
