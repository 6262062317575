import Form from '@/libs/form';

const fields = ['hid', 'name', 'ad_type', 'is_limitation_active', 'limitations', 'private_key_option', 'private_key'];

const rules = {
   name: 'required',
   ad_type: 'required',
};

export default Form.create(fields)
   .defaults({
      hid: null,
      name: null,
      ad_type: 'Image',
      private_key_option: 'custom',
      private_key: 'manual',
      is_limitation_active: false,
      limitations: {
         mon: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         tue: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         wed: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         thu: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         fri: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         sat: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
         sun: {
            is_active: true,
            unit: 'hour',
            value: 2,
            time_start_at: null,
            time_start_end: null,
         },
      },
   })
   .validate(rules);
