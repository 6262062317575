<template>
   <ObiText class="page-register">
      <ObiImage class="tenant-logo" :src="tenant.login.image.src" />
      <ObiText as="h1" class="register-title" v-html="$t('page_title')" />
      <ObiText class="register-description" v-html="$t('subtitle')" />

      <ObiForm @submit.prevent="submitRegister">
         <ObiText class="my-4">
            <ObiRow>
               <ObiCol sm="6">
                  <ObiFloatingInput
                     :icon="IconUser"
                     :disabled="isLoading"
                     v-model="formDataRegister.first_name"
                     :label="$t('register.fields.first_name')"
                     :placeholder="$t('register.fields.first_name')"
                     :errors="formDataRegister.errors.get('first_name')"
                  />
               </ObiCol>
               <ObiCol sm="6">
                  <ObiFloatingInput
                     :icon="IconUser"
                     :disabled="isLoading"
                     v-model="formDataRegister.last_name"
                     :label="$t('register.fields.last_name')"
                     :placeholder="$t('register.fields.last_name')"
                     :errors="formDataRegister.errors.get('last_name')"
                  />
               </ObiCol>
            </ObiRow>

            <ObiFloatingInput
               type="email"
               :icon="IconMail"
               :disabled="isLoading"
               name="email"
               v-model="formDataRegister.email"
               :label="$t('register.fields.email')"
               :placeholder="$t('register.fields.email')"
               :errors="formDataRegister.errors.get('email')"
            />

            <ObiFloatingInput
               type="text"
               :icon="IconPhone2"
               :disabled="isLoading"
               name="phone"
               v-model="formDataRegister.phone_number"
               :label="$t('register.fields.phone_number')"
               :placeholder="$t('register.fields.phone_number')"
               :errors="formDataRegister.errors.get('phone_number')"
            />

            <ObiFloatingInput
               compact
               type="password"
               :icon="IconPassword"
               :disabled="isLoading"
               v-model="formDataRegister.password"
               :label="$t('register.fields.password')"
               :placeholder="$t('register.fields.password')"
               :errors="formDataRegister.errors.get('password')"
            />
         </ObiText>

         <ObiButton
            block
            icon-right
            type="submit"
            :loading="isLoading"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.accept')"
         />
      </ObiForm>
   </ObiText>
</template>

<script>
import IconUser from '@/components/Icons/form/IconUser';
import IconMail from '@/components/Icons/form/IconMail';
import IconPhone2 from '@/components/Icons/form/IconPhone2';
import IconGlobe from '@/components/Icons/form/IconGlobe';
import IconCompany from '@/components/Icons/form/IconCompany';
import IconPassword from '@/components/Icons/form/IconPassword';

import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'InvitationAccept',
   i18n: {
      messages: {
         tr: {
            page_title: 'Ekibe Katılmaya Davet Edildiniz',
            subtitle: 'Aşağıdaki bilgileri doldururak hemen dahil olun!',
            fields: {
               name: 'Konum Adı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               portal: 'Portal',
            },
            actions: {
               accept: 'Katıl',
            },
         },
         en: {
            page_title: "You've Been Invited to Join the Team",
            subtitle: 'Join right away by filling in the information below!',
            fields: {
               name: 'Location Name',
               country: 'Country',
               city: 'City',
               district: 'District',
               portal: 'Portal',
            },
            actions: {
               accept: 'Join',
            },
         },
      },
   },
   components: {},
   data() {
      return {
         IconUser,
         IconMail,
         IconPhone2,
         IconGlobe,
         IconCompany,
         IconPassword,
         isLoading: false,
         formDataRegister: Form.create([
            'first_name',
            'last_name',
            'email',
            'phone_number',
            'password',
            'invite_token',
         ]).validate({
            first_name: 'required',
            last_name: 'required',
            email: 'required',
            password: 'required',
            phone_number: 'required',
            invite_token: 'required',
         }),
      };
   },
   mounted() {},
   methods: {
      async submitRegister() {
         this.formDataRegister.invite_token = this.$route.params.token;
         console.log(this.formDataRegister);
         if (!this.formDataRegister.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            await this.$store.dispatch('auth/register', this.formDataRegister.toObject());

            await this.$router.push({ name: 'auth-login' });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.page-register {
   text-align: center;

   .obi-form {
      margin: 0 auto;
      max-width: 400px;
   }

   .register-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .register-description {
      color: #777d96;
      font-size: 13px;
   }

   .form-register {
      margin-top: 30px;
   }
}
</style>
