<!-- eslint-disable prettier/prettier -->
<template>
   <ObiPage class="page-wrapper">
      <div class="metrics-interval-option-wrapper">
         <div class="col-md-8"></div>
         <ObiButtonSwitch :items="statisticsOptions" v-model="statisticsInterval" @click="applyFilter()" />
      </div>
      <ObiRow class="metrics-wrapper h-100">
         <ObiCol class="metrics-col-item">
            <ObiCardMetric
               :loading="isLoadingDataStatistics"
               class="h-100 text-primary"
               :title="$t('metrics.total_dns_query')"
               :metric="dataStatistics.getTotalQueryCount()"
            />
         </ObiCol>
         <ObiCol class="metrics-col-item">
            <ObiCardMetric
               :loading="isLoadingDataStatistics"
               class="h-100 text-success"
               :title="$t('metrics.total_allowed_query')"
               :metric="dataStatistics.getAllowedQueryCount()"
            />
         </ObiCol>
         <ObiCol class="metrics-col-item">
            <ObiCardMetric
               :loading="isLoadingDataStatistics"
               class="h-100 text-danger"
               :title="$t('metrics.total_blocked_query')"
               :metric="dataStatistics.getBlockedQueryCount()"
            />
         </ObiCol>
         <ObiCol class="metrics-col-item">
            <ObiCardMetric
               :loading="isLoadingDataStatistics"
               class="h-100 text-secondary"
               :title="$t('metrics.query_by_clients')"
               :metric="dataStatistics.getQueryByClientCount()"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow class="mt-4">
         <ObiCol col="12">
            <ObiCard>
               <template #header>
                  <ObiTitle :title="$t('query_history')" :subtitle="$t('QUERY_HISTORY_SUBTITLE')"> </ObiTitle>
               </template>
               <template #body>
                  <vue-loading
                     v-if="isLoadingQueryHistory"
                     type="bubbles"
                     color="#2f71f2"
                     :size="{ width: '50px', height: '50px' }"
                  ></vue-loading>
                  <div v-else style="max-height: 240px;">
                     <apexchart height="200px" type="area" :options="chartOptions" :series="series"></apexchart>
                  </div>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-4 domains-wrapper">
         <ObiCol class="domains-list-item" col="4">
            <ObiCard class="h-100">
               <template #header>
                  <ObiTitle :title="$t('RESOLVED_DOMAINS')" :subtitle="$t('RESOLVED_DOMAINS_SUBTITLE')"></ObiTitle>
               </template>
               <template #body>
                  <div>
                     <vue-loading
                        v-if="isLoadingTopDomains"
                        type="bubbles"
                        color="#2f71f2"
                        :size="{ width: '50px', height: '50px' }"
                     ></vue-loading>
                     <ObiTable v-else :headers="topDomainsHeaders" hideHeader :data="dataTopDomains.get('data')">
                        <template #domain="{ item: item }">
                           <ObiDomainItem :domain="item" />
                        </template>
                     </ObiTable>
                     <ObiNotFound
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                        v-if="
                           !isLoadingRootDomains &&
                           (!dataTopDomains.get('data') || dataTopDomains.get('data').length === 0)
                        "
                     />
                  </div>
               </template>
            </ObiCard>
         </ObiCol>
         <ObiCol class="domains-list-item" col="4">
            <ObiCard class="h-100">
               <template #header>
                  <ObiTitle :title="$t('BLOCKED_DOMAINS')" :subtitle="$t('BLOCKED_DOMAINS_SUBTITLE')"></ObiTitle>
               </template>
               <template #body>
                  <div>
                     <vue-loading
                        v-if="isLoadingTopBlockedDomains"
                        type="bubbles"
                        color="#2f71f2"
                        :size="{ width: '50px', height: '50px' }"
                     ></vue-loading>
                     <ObiTable v-else :headers="topDomainsHeaders" hideHeader :data="dataTopBlockedDomains.get('data')">
                        <template #domain="{ item: item }">
                           <ObiDomainItem :domain="item" />
                        </template>
                     </ObiTable>
                     <ObiNotFound
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                        v-if="
                           !isLoadingRootDomains &&
                           (!dataTopBlockedDomains.get('data') || dataTopBlockedDomains.get('data').length === 0)
                        "
                     />
                  </div>
               </template>
            </ObiCard>
         </ObiCol>
         <ObiCol class="domains-list-item" col="4">
            <ObiCard class="h-100">
               <template #header>
                  <ObiTitle :title="$t('ROOT_DOMAINS')" :subtitle="$t('ROOT_DOMAINS_SUBTITLE')"> </ObiTitle>
               </template>
               <template #body>
                  <div>
                     <vue-loading
                        v-if="isLoadingRootDomains"
                        type="bubbles"
                        color="#2f71f2"
                        :size="{ width: '50px', height: '50px' }"
                     ></vue-loading>
                     <ObiTable v-else :headers="topDomainsHeaders" hideHeader :data="dataTopRootDomains.get('data')">
                        <template #domain="{ item: item }">
                           <ObiDomainItem :domain="item" />
                        </template>
                     </ObiTable>
                     <ObiNotFound
                        compact
                        image-height="150"
                        type="user"
                        :title="$t('no_data_to_see')"
                        v-if="
                           !isLoadingRootDomains &&
                           (!dataTopRootDomains.get('data') || dataTopRootDomains.get('data').length === 0)
                        "
                     />
                  </div>
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
   </ObiPage>
</template>

<script>
import IconDevice from '@/components/Icons/general/IconDevice';
import IconVisitor from '@/components/Icons/general/IconVisitor';
import IconLocation from '@/components/Icons/general/IconLocation';
import IconIntegration from '@/components/Icons/general/IconIntegration';
import IconNotification from '@/components/Icons/general/IconNotification';

import ErrorHandler from '@/libs/error-handler';
import PanelStateApi from '@/api/PanelStateApi';
import FirewallStatisticApi from '@/api/FirewallStatisticApi';

import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import StatisticOverview from '@/api/Models/FirewallStatisticOverview';
import DataStatistic from '@/api/Models/DataStatistic';

import ObiCardMetric from '@/components/UI/ObiCardMetric';
//import ModalFailedLogin from '@/views/pages/Dashboard/_Modals/FailedLogin';
//import ModalOnlineUser from '@/views/pages/Dashboard/_Modals/OnlineUser';
import { VueLoading } from 'vue-loading-template';
export default {
   name: 'PageDashboardIndex',

   components: {
      ObiCardMetric,
      VueLoading,
      //ModalFailedLogin,
      //ModalOnlineUser,
   },
   i18n: {
      messages: {
         tr: {
            page_title: 'Genel Bakış',
            platforms_statistic_title: 'Popüler Platfromlar',
            browsers_statistic_title: 'Popüler Tarayıcılar',
            devices_statistic_title: 'Popüler Cihaz Türleri',
            network_statistics: 'Network İstatistikleri',
            top_clients: 'En Yüksek Kullanımlar',
            query_history: 'Sorgu Geçmişi',
            QUERY_HISTORY_SUBTITLE: 'Zamana göre sorgu geçmişi',
            RESOLVED_DOMAINS: 'En Çok Ziyaret Edilenler',
            RESOLVED_DOMAINS_SUBTITLE:
               'Engelleme veya manuel izin verilme olmadan çözümlenen alan adları, DNS çözümleyici müdahaleleri olmadan kısıtlamasız erişimi temsil eder',
            BLOCKED_DOMAINS: 'Engellenmiş Ziyaretler',
            BLOCKED_DOMAINS_SUBTITLE:
               'Güvenlik, gizlilik veya güvenlik duvarı ayarları tarafından engellenen alan adlarını görüntüler veya manuel olarak reddedilenleri temsil eder.',
            ROOT_DOMAINS: 'Kök Alan Adları',
            ROOT_DOMAINS_SUBTITLE:
               'Tüm kök alan adları ve bunların alt alan adları için tüm sorguları birleştirir. Kök alan adlarına ilişkin ağ trafiği ve etkinlikle ilgili genel bir bakış sunar',
            no_data_to_see: 'Gösterilecek veri yok',
            visitors: {
               title: 'Ziyaretçi Girişleri',
               statistic_not_enough: 'İstatistik için henüz yeterli veriye sahip değiliz :(',
            },
            devices: {
               title: 'Cihazlarım',
               create: 'Yeni Cihaz',
               not_found_title: 'Cihaz Bulunamadı',
               not_found_description:
                  'Hiç cihaz eklenmedi. Yeni cihaz eklemek için sağ üstteki Yeni Cihaz butonunu kullanabilirsiniz.',
            },
            metrics: {
               visitors_title: 'Kullanıcılar',
               devices_title: 'Cihazlar',
               locations_title: 'Konumlar',
               notifications_title: 'Bildirimler',
               integrations_title: 'Entegrasyonlar',
               online_visitor: 'Online Kullanıcı',
               unsuccessful_logins: 'Başarısız Girişler',
               returned_users: 'Tekrar Oturumlar',
               total_dns_query: 'Toplam İstek',
               total_allowed_query: 'İzin Verilen İstek',
               total_blocked_query: 'Engellenen İstek',
               query_by_clients: 'Cihaz Başına İstek Sayısı',
            },
            '24 Hour': '24 Saat',
            '3 Days': '3 Gün',
            '7 Days': '7 Gün',
            '30 Days': '30 Gün',
            'Evolution of queries over time': '',
            ALLOWED_TITLE: 'İzin Verilmiş',
            BLOCKED_TITLE: 'Engellenmiş',
         },
         en: {
            page_title: 'Overview',
            platforms_statistic_title: 'Popular Platforms',
            browsers_statistic_title: 'Popular Browsers',
            devices_statistic_title: 'Popular Device Types',
            network_statistics: 'Network Statistics',
            top_clients: 'Top Clients',
            query_history: 'Query History',
            QUERY_HISTORY_SUBTITLE: 'Evolution of queries over time',
            RESOLVED_DOMAINS: 'Most Visited Domains',
            RESOLVED_DOMAINS_SUBTITLE:
               'Domains that resolved without being blocked or manually allowed, representing unrestricted access without DNS resolver interventions',
            BLOCKED_DOMAINS: 'Blocked Domains',
            BLOCKED_DOMAINS_SUBTITLE:
               'Displays domains blocked by security, privacy, or firewall settings, or manually denied. Provides visibility into restricted domains on your network',
            ROOT_DOMAINS: 'Root Domains',
            ROOT_DOMAINS_SUBTITLE:
               'Aggregates all queries for root domains and their subdomains. Provides an overview of network traffic and activity related to root domains',
            no_data_to_see: 'Nothing to see here',
            visitors: {
               title: 'Visitor Entries',
               statistic_not_enough: "We don't have enough data for statistics yet :(",
            },
            devices: {
               title: 'My Devices',
               create: 'New Device',
               not_found_title: 'Device Not Found',
               not_found_description:
                  'No devices have been added. You can use the New Device button in the upper right corner to add a new device.',
            },
            metrics: {
               visitors_title: 'Users',
               devices_title: 'Devices',
               locations_title: 'Locations',
               notifications_title: 'Notifications',
               integrations_title: 'Integrations',
               online_visitor: 'Online User',
               unsuccessful_logins: 'Failed Logins',
               returned_users: 'Return Sessions',
               total_dns_query: 'Total Query',
               total_allowed_query: 'Allowed Query',
               total_blocked_query: 'Blocked',
               query_by_clients: 'Query By Client',
            },
            '24 Hour': '24 Hour',
            '3 Days': '3 Days',
            '7 Days': '7 Days',
            '30 Days': '30 Days',
            ALLOWED_TITLE: 'Allowed',
            BLOCKED_TITLE: 'Blocked',
         },
      },
   },

   computed: {
      /**
       * @returns {FirewallStatisticOverview}
       */
      dataStatistics() {
         return this.dataStatisticsOverview.getModel();
      },
   },

   data() {
      return {
         IconDevice,
         IconVisitor,
         IconLocation,
         IconIntegration,
         IconNotification,
         dataTopDomains: SingleResourceResponse.create().map(DataStatistic),
         dataTopBlockedDomains: SingleResourceResponse.create().map(DataStatistic),
         dataTopRootDomains: SingleResourceResponse.create().map(DataStatistic),
         dataStatisticsOverview: SingleResourceResponse.create().map(StatisticOverview),
         timer: null,
         firstLoadFinished: false,
         isLoadingDataStatistics: false,
         isLoadingDevice: false,
         isLoadingVisitsStatistics: false,
         isLoadingStatisticsOverview: false,
         isLoadingBrowsersStatistics: false,
         isLoadingPlatformsStatistics: false,
         isLoadingDevicesStatistics: false,
         isLoadingFailedLogin: false,
         isLoadingOnlineUser: false,
         isLoadingQueryHistory: false,
         isLoadingTopDomains: false,
         isLoadingTopBlockedDomains: false,
         isLoadingRootDomains: false,
         topDomainsHeaders: [
            {
               text: '',
               value: 'domain',
               sortable: false,
            },
            {
               text: '',
               value: 'queries',
               sortable: false,
            },
         ],
         dataVisitorBandwidth: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataPlatformsStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataBrowsersStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataDevicesStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         chartOptions: {
            chart: {
               id: 'vuechart-example',
            },
            dataLabels: {
               enabled: false,
            },
            stroke: {
               curve: 'smooth',
            },
            fill: {
               type: 'gradient',
               gradient: {
                  opacityFrom: 0.6,
                  opacityTo: 0.8,
               },
            },
            xaxis: {},
         },
         series: [],
         statisticsInterval: '-24hours',
         statisticsOptions: [
            {
               label: this.$t('24 Hour'),
               id: '-24hours',
               unit: 'hours',
               from: 24,
            },
            {
               label: this.$t('3 Days'),
               id: '-3days',
               unit: 'days',
               from: 3,
            },
            {
               label: this.$t('7 Days'),
               id: '-7days',
               unit: 'days',
               from: 7,
            },
            {
               label: this.$t('30 Days'),
               id: '-30days',
               unit: 'days',
               from: 30,
            },
         ],
         networkStatisticsSelectedTab: 'bandwidth',
         topClientsInterval: '-24hours',
         topClientsOptions: [
            {
               label: this.$t('Last 30 days'),
               id: '-30days',
            },
            {
               label: this.$t('Last 7 days'),
               id: '-7days',
            },
            {
               label: this.$t('Last 24 hrs'),
               id: '-24hours',
            },
         ],
         topClientsSelectedTab: 'bandwidth',
         tabItems: [
            {
               id: 'bandwidth',
               label: this.$t('Bandwidth'),
            },
            {
               id: 'concurrent_clients',
               label: this.$t('Concurrent Clients'),
            },
         ],
      };
   },

   async mounted() {
      await this.refreshData();
   },

   beforeDestroy() {},

   methods: {
      async applyFilter() {
         this.firstLoadFinished = false;
         await this.refreshData();
      },
      async refreshData() {
         this.loadStatisticsOverview();
         this.loadTopDomains();
         this.loadTopBlockedDomains();
         this.loadQueryHistory();
         this.loadTopRootDomains();
         //this.loadDevices();
         //this.loadStatisticsOverview();
         //this.loadPlatformStatistics();
         //this.loadBrowserStatistics();
         //this.loadDeviceStatistics();
      },
      async loadTopDomains() {
         try {
            this.isLoadingTopDomains = true;
            this.dataTopDomains.merge(
               await FirewallStatisticApi.topDomains({ limit: 10, interval: this.statisticsInterval })
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingTopDomains = false;
         }
      },

      async loadTopBlockedDomains() {
         try {
            this.isLoadingTopBlockedDomains = true;
            this.dataTopBlockedDomains.merge(
               await FirewallStatisticApi.topDomains({ type: 'blocked', limit: 10, interval: this.statisticsInterval })
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingTopBlockedDomains = false;
         }
      },

      async loadTopRootDomains() {
         try {
            this.isLoadingRootDomains = true;
            this.dataTopRootDomains.merge(
               await FirewallStatisticApi.topDomains({
                  limit: 10,
                  key: 'root_domain',
                  interval: this.statisticsInterval,
               })
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingRootDomains = false;
         }
      },

      async loadQueryHistory() {
         try {
            this.isLoadingQueryHistory = true;
            const { data } = await FirewallStatisticApi.historyData({
               type: 'blocked',
               interval: this.statisticsInterval,
            });
            this.chartOptions = {
               ...this.chartOptions,
               ...{
                  xaxis: {
                     categories: data.series,
                     labels: { show: false },
                  },
               },
            };

            this.series = [
               {
                  name: this.$t('ALLOWED_TITLE'),
                  data: data.data[0].entries,
                  color: '#198754',
               },
               {
                  name: this.$t('BLOCKED_TITLE'),
                  data: data.data[1].entries,
                  color: '#dc3545',
               },
            ];
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingQueryHistory = false;
         }
      },

      async loadStatisticsOverview() {
         this.isLoadingDataStatistics = true;

         try {
            this.dataStatisticsOverview.merge(
               await FirewallStatisticApi.overview({ interval: this.statisticsInterval })
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingDataStatistics = false;
         }
      },

      statisticMetric(metricSource) {
         if (!this[metricSource]) return 0;

         return this[metricSource].get('meta.total');
      },

      statisticLoadingState(loadingKey) {
         if (!this[loadingKey]) return false;

         return this[loadingKey];
      },
      async showFailedLogin() {
         this.isLoadingFailedLogin = true;

         try {
            const params = this.$route.query;
            const { data } = await PanelStateApi.failedLogins(params);
            this.$refs.modalFailedLogin.show(data);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingFailedLogin = false;
         }
      },
      async showOnlineUser() {
         this.isLoadingOnlineUser = true;

         try {
            const params = this.$route.query;
            params.per_page = 200;
            const response = await PanelStateApi.onlines(params);
            this.$refs.modalOnlineUser.show(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingOnlineUser = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.device-list {
   max-height: 300px;
   overflow: auto;
}
.page-wrapper {
   padding: 10px;
   margin-bottom: 20px;
}

.metrics-interval-option-wrapper {
   margin-bottom: 0.5rem !important;
   justify-content: space-between !important;
   display: flex !important;
}

.stat-item-wrap {
   border-radius: 10px;

   .stat-item {
      gap: 12px;
      display: flex;
      padding-top: 30px;
      align-items: center;
      padding-bottom: 30px;
      flex-direction: column;

      &:hover {
         color: #fff;
         background-color: #2f71f2;
      }

      .stat-item-metric {
         font-size: 24px;
      }
   }
}

@media (max-width: 768px) {
   .metrics-col-item {
      flex: 0 0 100% !important;
      margin-bottom: 10px;
   }
   .domains-wrapper {
      display: flex !important;
      flex-direction: column;

      .domains-list-item {
         width: 100%;
         margin-bottom: 10px;
      }
   }
   .metrics-interval-option-wrapper {
      margin-bottom: 0.5rem !important;
      justify-content: center !important;
      display: flex !important;
   }
}
</style>
