import Http from '@/libs/http';

class PanelDeviceLogApi extends Http {
   index(deviceId, params) {
      return super.get(`panel/device/${deviceId}/logs`, { params });
   }

   histogram(id, params) {
      return super.get(`panel/device/${id}/logs/histogram`, { params });
   }

   popularWebsite(id, params) {
      return super.get(`panel/device/${id}/logs/popular_website`, { params });
   }

   popularApplication(id, params) {
      return super.get(`panel/device/${id}/logs/popular_application`, { params });
   }

   popularCountry(id, params) {
      return super.get(`panel/device/${id}/logs/popular_country`, { params });
   }
}

export default new PanelDeviceLogApi();
