import get from 'lodash/get';

export const getSubdomain = () => {
   const hostname = window.location.hostname;
   if (hostname !== 'localhost' && hostname.indexOf('wifibulut.com') < 0 && hostname.indexOf('quedra.com') < 0) {
      const expression = /^(?<subdomain>.*)\.(?<domain>.*)\.(?<tld>.*)$/;
      const { groups } = hostname.match(expression);
      return get(groups, 'subdomain', 'panel');
   } else if (hostname.indexOf('wifibulut.com') >= 0) {
      return 'wifibulut';
   } else if (hostname.indexOf('quedra.com') >= 0) {
      return 'quedra';
   }

   return 'panel';
};
