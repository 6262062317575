<template>
   <ObiText class="obi-eg">
      <ObiText class="obi-eg-body">
         <ObiSurveyCard
            v-for="(survey, index) in dataSurveys"
            :key="index"
            :index="index"
            :survey="survey"
            :is-editing="editingItem === index"
            @change="onChanged"
            @delete="deleteSurvey"
            @edit="startEdit"
            class="mt-2"
         />

         <Obi404 v-if="!dataSurveys || dataSurveys.length === 0">
            <template #actions>
               <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="addNew" />
            </template>
         </Obi404>
         <ObiText v-else class="text-center mt-4">
            <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="addNew" />
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import ObiSurveyCard from '@/components/UI/ObiSurveyCard';

export default {
   name: 'PortalSurveys',
   components: {
      ObiSurveyCard,
   },
   i18n: {
      messages: {
         tr: {
            not_found: 'Henüz kayıtlı bir anketiniz yok :(',
            labels: {},
            actions: {
               create: 'Yeni Ekle',
            },
         },
         en: {
            not_found: "You don't have any registered surveys yet :(",
            labels: {},
            actions: {
               create: 'Add New',
            },
         },
      },
   },

   props: {
      portalId: {
         type: [String, Number],
         required: true,
      },
      surveys: {
         type: Array,
         required: false,
      },
   },

   mounted() {
      if (this.$props.surveys !== null) {
         this.dataSurveys = this.$props.surveys;
      } else {
         this.dataSurveys = [];
      }
   },

   data() {
      return {
         dataSurveys: [],
         selectedItem: null,
         editingItem: null,
      };
   },

   methods: {
      async onChanged() {
         this.$emit('change', this.dataSurveys);
      },
      async addNew() {
         this.dataSurveys.push({ questions: [] });
         this.editingItem = this.dataSurveys.length - 1;
         this.$emit('change', this.dataSurveys);
      },
      async deleteSurvey(survey) {
         this.dataSurveys.splice(survey, 1);
         this.$emit('change', this.dataSurveys);
      },
      async startEdit(index) {
         if (this.editingItem === index) {
            this.editingItem = null;
            return;
         }
         this.editingItem = index;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: block;
   margin-left: -1.5rem;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }

   .btn-new {
      height: 80px;
   }
}
</style>
