<template>
   <ObiText class="obi-eg">
      <ObiLoading v-if="isLoading" />
      <ObiCard v-else class="h-100" elevation="0">
         <ObiRuleCard
            v-for="(rule, index) in dataRules"
            :key="rule.id"
            :rule="rule"
            :index="index"
            class="mt-2"
            :portalId="portalId"
            :surveys="dataSurveys"
            :ads="dataAds"
            @deleted="removeRuleFromIndex"
         />
         <ObiButton
            class="btn-new mt-4"
            size="lg"
            inline
            block
            color="lighter"
            :text="$t('actions.add_new')"
            icon="mdi mdi-plus"
            @click="addNewRule"
         />
      </ObiCard>
   </ObiText>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelPortalApi from '@/api/PanelPortalApi';

export default {
   name: 'PortalRules',
   i18n: {
      messages: {
         tr: {
            labels: {},
            actions: {
               add_new: 'Yeni Ekle',
            },
         },
         en: {
            labels: {},
            actions: {
               add_new: 'Add New',
            },
         },
      },
   },

   props: {
      portalId: {
         type: [String, Number],
         required: true,
      },
   },

   async mounted() {
      await this.getRules();
      await this.getAds();
      await this.getSurveys();
   },

   data() {
      return {
         dataRules: [],
         dataAds: [],
         dataSurveys: [],
         isLoading: false,
         isLoadingSurveys: false,
         isLoadingAds: false,
         addingNew: false,
      };
   },

   methods: {
      async getRules() {
         try {
            this.isLoading = true;

            const { data } = await PanelPortalApi.rules.index(this.portalId, null);
            this.dataRules = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async getAds() {
         try {
            this.isLoadingAds = true;

            const { data } = await PanelPortalApi.ads.index(this.portalId, null);
            this.dataAds = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingAds = false;
         }
      },
      async getSurveys() {
         try {
            this.isLoadingSurveys = true;

            const { data } = await PanelPortalApi.surveys.index(this.portalId, null);
            this.dataSurveys = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingSurveys = false;
         }
      },
      async addNewRule() {
         this.dataRules.push({});
      },
      async onChanged() {},
      async removeRuleFromIndex(index) {
         this.dataRules.splice(index, 1);
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: block;
   margin-left: -1.5rem;

   .obi-eg-body {
      height: 100%;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }

   .btn-new {
      height: 80px;
   }
}
</style>
