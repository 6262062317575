<template>
   <div class="obi-main">
      <div class="obi-main-wrapper">
         <div class="obi-main-content">
            <ObiHeader />
            <ObiContent>
               <template slot="content">
                  <router-view />
               </template>
            </ObiContent>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   components: {},
   data() {
      return {
         routeIsDashboard: this.$router.currentRoute.name === 'dashboard-index',
      };
   },
   watch: {
      $route(newVal) {
         this.routeIsDashboard = newVal.name === 'dashboard-index';
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-main {
   margin: 0px auto;
   position: relative;
   width: 100%;
   height: 100%;
   min-height: 100vh;
}
.obi-main-wrapper {
   background-color: #ffffff;
   height: 100%;
   position: relative;
}
.obi-main-content {
   display: flex;
   flex-direction: column;
   min-height: 100vh;
}
</style>
