import Http from '@/libs/http';

class PanelStateApi extends Http {
   failedLogins(params) {
      return super.get('panel/failed-login', { params });
   }

   onlines(params) {
      return super.get('panel/onlines', { params });
   }

   kick(hid, payload) {
      return super.post(`panel/kick/${hid}`, payload, null);
   }
}

export default new PanelStateApi();
