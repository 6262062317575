import TenantApi from '../../api/TenantApi';
import i18n from '@/i18n';

const state = {
   pageTitle: process.env.VUE_APP_APP_NAME,
   tenant: null,
};

const getters = {
   pageTitle(state) {
      return state.pageTitle;
   },
   tenant(state) {
      return state.tenant;
   },
   currentLanguage() {
      if (localStorage.getItem('last-locale')) {
         const currentLocale = localStorage.getItem('last-locale');
         i18n.locale = currentLocale;
      }

      return i18n.locale;
   },
};

const actions = {
   async setPageTitle(context, pageTitle) {
      document.title = pageTitle;

      context.commit('pageTitle', pageTitle);
   },
   async tenant(context) {
      const response = await TenantApi.config();
      context.commit('tenant', response);
   },
};

const mutations = {
   pageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
   },
   tenant(state, tenant) {
      state.tenant = tenant;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
