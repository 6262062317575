import Form from '@/libs/form';

const fields = [
   'name',
   'country',
   'city',
   'district',
   'portal_id',
   'default_profile_id',
   'available_providers',
   'sms_limit',
];

const rules = {
   name: 'required',
};

export default Form.create(fields)
   .validate(rules)
   .defaults({ available_providers: [], sms_limit: 1000 });
