<template>
   <ObiText class="page-complete">
      <ObiText class="page-complete-content" v-if="!isLoading && !hasError">
         <IconWelcome />
         <ObiText as="h1" class="page-title" v-html="$t('page_title')" />
         <ObiText class="page-description my-5" v-html="$t('page_description')" />
      </ObiText>
      <ObiText class="page-complete-content" v-if="!isLoading && hasError">
         <IconActivationFailed />
         <ObiText as="h1" class="page-title" v-html="$t('error_title')" />
         <ObiText class="page-description my-5" v-html="$t(error_message)" />
      </ObiText>
      <ObiLoading v-if="isLoading" :title="$t('please_wait')" />
   </ObiText>
</template>

<script>
import IconWelcome from '@/components/Icons/form/IconWelcome';
import IconActivationFailed from '@/components/Icons/form/IconActivationFailed';
import InvitationApi from '@/api/InvitationApi';
import get from 'lodash/get';

export default {
   i18n: {
      messages: {
         tr: {
            page_title: 'Teşekkürler',
            page_description: 'E-Posta adresiniz doğrulandı. Artık tekrar kullanabilirsiniz.',
            please_wait: 'Lütfen bekleyin',
            error_title: 'Bir sorun oluştu',
         },
         en: {
            page_title: 'Thank You',
            page_description: 'Your email address has been verified. You can now use it again.',
            please_wait: 'Please wait',
            error_title: 'An issue occurred',
         },
      },
   },
   name: 'PageComplete',
   components: {
      IconWelcome,
      IconActivationFailed,
   },
   data() {
      return {
         isLoading: true,
         hasError: false,
         error_message: null,
      };
   },
   mounted() {
      this.confirmMail();
   },
   methods: {
      async confirmMail() {
         const token = this.$route.params.token;
         const email = this.$route.params.email;
         this.isLoading = true;
         this.hasError = false;

         try {
            await InvitationApi.confirmEmail({ token: token, email: email });
         } catch (err) {
            this.hasError = true;
            this.error_message = get(err, 'data.message');
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.page-complete {
   text-align: center;

   .page-complete-content {
      margin: 0 auto;
      max-width: 400px;
   }

   .page-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .page-description {
      color: #777d96;
      font-size: 18px;
   }
}
</style>
