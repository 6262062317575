<template>
   <ObiApp :style="cssProps">
      <router-view />

      <notifications position="top center" :max="1" width="100%" />
   </ObiApp>
</template>

<script>
export default {
   name: 'App',
   mounted() {
      this.initHMRControl();
   },
   computed: {
      cssProps() {
         return {
            '--primary-color': this.tenant && this.tenant.brand ? this.tenant.brand.primary_color : '#2f71f2',
         };
      },
   },
   methods: {
      initHMRControl() {
         if (module && module.hot) {
            // noinspection JSUnresolvedFunction
            module.hot.addStatusHandler(function(status) {
               const elmBackdrop = document.body.querySelector('.modal-backdrop');

               if (!elmBackdrop) return;

               if (status === 'apply') {
                  elmBackdrop.style.display = 'none';
               }
            });
         }
      },
   },
};
</script>

<style>
:root {
   --primary-color: var(--primary-color) !important;
}
</style>
