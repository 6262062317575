export const formatPrivateKeyFn = (inputNumber) => {
   if (!inputNumber) return null;

   let numericValue = inputNumber.replace(/\D/g, '');

   if (numericValue.length <= 3) {
      inputNumber = numericValue;
   } else if (numericValue.length <= 6) {
      inputNumber = numericValue.slice(0, 3) + '-' + numericValue.slice(3);
   } else {
      inputNumber = numericValue.slice(0, 3) + '-' + numericValue.slice(3, 6) + '-' + numericValue.slice(6);
   }

   return inputNumber;
};

export const formatPrivateKey = (value) => formatPrivateKeyFn(value);
