<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <ObiRow>
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('IP_Address')"
               :disabled="isLoading"
               :placeholder="$t('IP_Address')"
               v-model="ipAddress"
            />
         </ObiCol>
      </ObiRow>
      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('ACTION_SAVE')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import { trFormatDateLong } from '@/libs/date-utils';

export default {
   name: 'AddLinkedIpModal',
   i18n: {
      messages: {
         tr: {
            IP_Address: 'IP Adresi',
            ACTION_SAVE: 'IP Adresini Ata',
         },
         en: {
            IP_Address: 'IP Address',
            ACTION_SAVE: 'Link Ip Address',
         },
      },
   },
   props: {},

   components: {},

   data() {
      return {
         isLoading: false,
         ipAddress: null,
      };
   },
   computed: {
      currentLanguage() {
         return this.$i18n.locale;
      },
   },

   methods: {
      trFormatDateLong,
      show() {
         this.ipAddress = null;
         this.$refs.modal.show();
      },
      hide() {
         this.ipAddress = null;
         this.$refs.modal.hide();
      },
      submitForm() {
         this.$emit('save', this.ipAddress);
         this.$refs.modal.hide();
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';

.obi-online-user {
   padding: 10px;
   border-radius: 8px;
   background-color: #f3f8fe;

   .bottom-white {
      border-bottom: 1px solid #fff;
   }

   .obi-online-user-content-wrapper {
      display: flex;
      gap: 20px;
   }

   .obi-online-user-content {
      display: grid;
   }

   .obi-online-user-value {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-online-user-field {
      font-size: 0.8rem;
   }
}
</style>
