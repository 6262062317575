import LayoutAdmin from '@/views/layouts/Admin';
import ManagementDashboard from '@/views/pages/Dashboard/Management';
import LayoutChildPage from '@/views/layouts/ChildPage';

import PortalEdit from '@/views/pages/Portal/Edit';
import PortalIndex from '@/views/pages/Portal/Index';
import PortalEditGeneral from '@/views/pages/Portal/Edit/General';
import PortalEditRules from '@/views/pages/Portal/Edit/Rules';
import PortalEditSurveys from '@/views/pages/Portal/Edit/Surveys';
import PortalEditSignInMethods from '@/views/pages/Portal/Edit/SignInMethods';
import PortalEditAdvertisements from '@/views/pages/Portal/Edit/Advertisements';
import PortalEditAdvanced from '@/views/pages/Portal/Edit/Advanced';

export default {
   path: '/admin',
   component: LayoutAdmin,
   children: [
      {
         path: '',
         name: 'dashboard-admin',
         component: ManagementDashboard,
      },
      {
         path: 'portals',
         component: LayoutChildPage,
         children: [
            {
               path: '',
               name: 'portals-index',
               component: PortalIndex,
            },
            {
               path: ':portalId/edit',
               component: PortalEdit,
               children: [
                  {
                     path: '/',
                     name: 'portals-edit',
                     component: PortalEditGeneral,
                  },
                  {
                     path: 'sign-in-methods',
                     name: 'portals-edit-sign-in-methods',
                     component: PortalEditSignInMethods,
                  },
                  {
                     path: 'surveys',
                     name: 'portals-edit-surveys',
                     component: PortalEditSurveys,
                  },
                  {
                     path: 'rules',
                     name: 'portals-edit-rules',
                     component: PortalEditRules,
                  },
                  {
                     path: 'advertisements',
                     name: 'portals-edit-advertisements',
                     component: PortalEditAdvertisements,
                  },
                  {
                     path: 'advanced',
                     name: 'portals-edit-advanced',
                     component: PortalEditAdvanced,
                  },
               ],
            },
         ],
      },
   ],
};
